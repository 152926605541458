import * as cmsMembersConst from "../cmsMembersConst";
import axios from "axios";


const GET_CMS_TEAM_MEMBERS = cmsMembersConst.GET_CMS_TEAM_MEMBERS
export const getCMSTM = () => {
    const token = localStorage.getItem("accessToken");
    return axios
      .get(`${process.env.REACT_APP_URL + GET_CMS_TEAM_MEMBERS}`,  {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  };


const CREATE_CMS_USER = cmsMembersConst.CREATE_CMS_USER
export const createCMSUser = (data) => {
    const token = localStorage.getItem("accessToken");
    return axios
      .post(`${process.env.REACT_APP_URL + CREATE_CMS_USER}`, data,  {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  };


  const ASSIGN_CMS_USER_TO_COMPANY = cmsMembersConst.ASSIGN_CMS_USER_TO_COMPANY
export const assignCMSUserToCompany = (UserId,TenantId) => {
    const token = localStorage.getItem("accessToken");
    return axios
      .post(`${process.env.REACT_APP_URL + ASSIGN_CMS_USER_TO_COMPANY}`, 
      {
        UserId: UserId,
        TenantId:TenantId

      },  {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  };
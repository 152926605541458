import React, { useState, useEffect, useMemo } from "react";
import classes from "../../../Assets/Styles/Shapes.module.css";
import { Grid } from "@mui/material";
import WhiteBoard from "./WhiteBoard";
import Form1 from "./Form1";
import Form2 from "./Form2";
import { exportToBlob } from "@excalidraw/excalidraw";
import { useLocation, useNavigate } from "react-router-dom";
import * as shapeServices from "../../../Services/shape-services.proxy";
const getCreateOrEditData = shapeServices.getCreateOrEditData;
const addShape = shapeServices.addShape;
const updateShape = shapeServices.updateShape;
const uploadFileShape = shapeServices.uploadFileShape;
const deleteFileShape = shapeServices.deleteFileShape;

const AddShape = (props) => {
  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const SHAPE_ID = query.get("id");
  const [dataElements, setDataElements] = useState([]);
  const [dataFiles, setDataFiles] = useState([]);
  const [dataScene, setDataScene] = useState({
    type: "excalidraw",
    version: 2,
    source: `${process.env.REACT_APP_URL}`,
    elements: [],
    appState: {
      gridSize: null,
      viewBackgroundColor: "#ffffff",
      isLibraryOpen: true,
    },
    files: {},
  });

  const [step, setStep] = useState(1);
  const [shapElementsTypes, setShapElementsTypes] = useState([]);
  const [measureTypes, setMeasureTypes] = useState([]);
  const [elements, setElements] = useState([]);
  const [twoDIsselected, setTwoDIsselected] = useState(true);
  const [numOfLines, setNumOfLines] = useState(null);
  const [numOfAngles, setNumOfAngles] = useState(null);
  const [shapeCatelogue, setShapeCatelogue] = useState(null);
  const [shapeCatelogues, setShapeCatelogues] = useState([]);
  const [shapeCategory, setShapeCategory] = useState(1);
  const [shapeCategories, setShapeCategories] = useState([]);
  const [shapeType, setShapeType] = useState(null);
  const [shapeTypes, setShapeTypes] = useState([]);
  const [shapeCode, setShapeCode] = useState(null);
  const [shapeStatus, setShapeStatus] = useState(1);
  const [shapeStatuses, setShapeStatuses] = useState([]);
  const [shapeSubCode, setShapeSubCode] = useState(null);
  const [shapeVisibility, setShapeVisibility] = useState(1);
  const [shapeComment, setShapeComment] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [previousfileId, setPreviousFileId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.setHideDrawer(false);
  });
  useEffect(() => {
    getCreateOrEditData(SHAPE_ID ? SHAPE_ID : null).then((x) => {
      setShapElementsTypes(x.ElementTypes);
      setMeasureTypes(x.MeasureTypes);
      setShapeCatelogues(x.Catalogues);
      setShapeCategories(x.Categories);
      setShapeStatuses(x.Statuses);
      setShapeTypes(x.Types);
      if (SHAPE_ID) {
        setShapeCatelogue(x.CatalogueId);
        setShapeCode(x.Code);
        setShapeComment(x.Comment);
        setTwoDIsselected(x.DimensionId === 1 ? true : false);
        setNumOfAngles(x.NumOfAngles);
        setNumOfLines(x.NumOfSides);
        setShapeStatus(x.StatusId);
        setShapeSubCode(x.SubCode ? x.SubCode : null);
        setShapeCategory(x.CategoryId);
        setShapeType(x.TypeId);
        setShapeVisibility(x.VisibilityId);
        setElements(x.Elements);
        setDataScene(JSON.parse(x.DataScene));
        setFileId(x.FileId);
        setPreviousFileId(x.FileId);
      }
      setLoading(false);
    });
  }, []);
  const SyncBoard = () => {
    setDataScene({
      type: "excalidraw",
      version: 2,
      source: `${process.env.REACT_APP_URL}`,
      elements: dataElements,
      appState: {
        gridSize: null,
        viewBackgroundColor: "#ffffff",
      },
      files: dataFiles,
      libraryItems: [],
    });
  };
  const uploadFile = async () => {
    if (previousfileId) {
      deleteFileShape(previousfileId);
    }

    let tempDataScene = structuredClone(dataScene);
    tempDataScene.elements.map((tobeChangedEl) => {
      if (tobeChangedEl.type === "text") {
        let displayedElement = elements.find(
          (x) => x.Name === tobeChangedEl.text
        );

        if (displayedElement.MeasureValue) {
          tobeChangedEl.text =
            tobeChangedEl.text + "=" + displayedElement.MeasureValue;
        } else if (displayedElement.MeasureFunction) {
          tobeChangedEl.text =
            tobeChangedEl.text + "=" + displayedElement.MeasureFunction;
        }
      }
    });
    const blob = await exportToBlob({
      elements: tempDataScene.elements,
      mimeType: "image/png",
      appState: dataScene.appState,
      files: dataScene.files,
    });
    uploadFileShape(blob).then((x) => {
      setFileId(x.FileId);
      if (SHAPE_ID) {
        updateShape({
          Id: SHAPE_ID ? SHAPE_ID : null,
          FileId: x.FileId,
          Name: shapeCode,
          TradeId: 1,
          DimensionId: twoDIsselected ? 1 : 2,
          NumOfSides: numOfLines,
          NumOfAngles: numOfAngles,
          CatelogueId: shapeCatelogue,
          CategoryId: shapeCategory,
          TypeId: shapeType,
          Code: shapeCode,
          SubCode: shapeSubCode,
          StatusId: shapeStatus,
          VisibilityId: shapeVisibility,
          Comment: shapeComment,
          Elements: elements,
          DataScene: JSON.stringify(dataScene),
        }).then((x) => {
          navigate("/Manu/rebarTable");
        });
      } else {
        addShape({
          Id: SHAPE_ID ? SHAPE_ID : null,
          FileId: x.FileId,
          Name: shapeCode,
          TradeId: 1,
          DimensionId: twoDIsselected ? 1 : 2,
          NumOfSides: numOfLines,
          NumOfAngles: numOfAngles,
          CatelogueId: shapeCatelogue,
          CategoryId: shapeCategory,
          TypeId: shapeType,
          Code: shapeCode,
          SubCode: shapeSubCode,
          StatusId: shapeStatus,
          VisibilityId: shapeVisibility,
          Comment: shapeComment,
          Elements: elements,
          DataScene: JSON.stringify(dataScene),
        }).then((x) => {
          navigate("/Manu/rebarTable");
        });
      }
    });
  };
  const saveTheShape = () => {
    // if (SHAPE_ID) {
    //   updateShape(shape).then((x) => {
    //     navigate("/Manu/rebarTable");
    //   });
    // } else {
    //   addShape(shape).then((x) => {
    //     navigate("/Manu/rebarTable");
    //   });
    // }
  };
  return (
    <>
      {SHAPE_ID && loading ? (
        <></>
      ) : (
        <Grid container paddingTop={9}>
          <Grid item container direction={"row"} marginLeft={4}>
            <Grid item className={classes["page-title"]}>
              Shape Creation{" "}
              <span style={{ fontWeight: "normal", fontSize: 15 }}>
                (Draw shape between 20 and 30 box to have a better display)
              </span>
            </Grid>
          </Grid>
          <Grid item container md={12}>
            <WhiteBoard
              dataScene={dataScene}
              setDataScene={setDataScene}
              setDataElements={setDataElements}
              setDataFiles={setDataFiles}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            bgcolor={"rgb(252 250 250)"}
            paddingBottom={20}
          >
            <Grid
              item
              xs={11.6}
              className={classes["Rectangle-1698"]}
              marginTop={3}
              marginLeft={3}
            >
              {step === 1 ? (
                <Form1
                  twoDIsselected={twoDIsselected}
                  setTwoDIsselected={setTwoDIsselected}
                  dataScene={dataScene}
                  elements={elements}
                  setElements={setElements}
                  shapElementsTypes={shapElementsTypes}
                  measureTypes={measureTypes}
                  SyncBoard={SyncBoard}
                  setStep={setStep}
                  setNumOfAngles={setNumOfAngles}
                  setNumOfLines={setNumOfLines}
                />
              ) : (
                <Form2
                  numOfAngles={numOfAngles}
                  numOfLines={numOfLines}
                  setStep={setStep}
                  shapeCategory={shapeCategory}
                  setShapeCategory={setShapeCategory}
                  shapeCategories={shapeCategories}
                  shapeCatelogues={shapeCatelogues}
                  shapeCatelogue={shapeCatelogue}
                  setShapeCatelogue={setShapeCatelogue}
                  shapeType={shapeType}
                  setShapeType={setShapeType}
                  shapeTypes={shapeTypes}
                  shapeCode={shapeCode}
                  setShapeCode={setShapeCode}
                  shapeStatus={shapeStatus}
                  setShapeStatus={setShapeStatus}
                  shapeStatuses={shapeStatuses}
                  shapeSubCode={shapeSubCode}
                  setShapeSubCode={setShapeSubCode}
                  shapeVisibility={shapeVisibility}
                  shapeComment={shapeComment}
                  setShapeComment={setShapeComment}
                  saveTheShape={saveTheShape}
                  uploadFile={uploadFile}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AddShape;

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid, ListItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Badge from "@mui/material/Badge";
import reportsLogo from "../Assets/Images/Drawer/reportLogo.png";
import notiLogo from "../Assets/Images/Drawer/notiLogo.png";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Tooltip } from "@mui/material";

import Logo from "../Assets/Images/Drawer/Logo.png";
import Logo1 from "../Assets/Images/Drawer/logo1.png";
import Userspic from "../Assets/Images/Drawer/USers.png";
import ProjectsPic from "../Assets/Images/Drawer/Projects.png";
import Projects1Pic from "../Assets/Images/Drawer/Projects icon.png";
import LogoutIcon from "../Assets/Images/Drawer/Log Out.png";
import ManuIcon from "../Assets/Images/Drawer/Manufacturing.png";
import SubscPic from "../Assets/Images/Drawer/subscription.png";
import SupportPic from "../Assets/Images/Drawer/support.png";
import classes from "../Assets/Styles/Drawer.module.css";
import { useNavigate } from "react-router-dom";
import * as sideBarServices from "../Services/subscription-services.proxy";
const getSubscriptionsRequNo = sideBarServices.getSubscriptionsRequNo;
export default function MiniDrawer(props) {
  const navigate = useNavigate();
  const BlueTip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
        color:#fff;
        background-color: ${props.open ? "transparent" : "#e1e319"} ;
        font-size: 13px;
        width:fit-content;
     
    `);
  const [usersActive, setUsersActive] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [mCActive, setMCActive] = useState(false);
  const [sCActive, setSCActive] = useState(false);
  const [teamMemberActive, setTeamMemberActive] = useState(false);
  const [requestActive, setRequestActive] = useState(false);
  const [manageActive, setManageActive] = useState(false);
  const [projectsActive, setProjectsActive] = useState(false);
  const [reportsActive, setReportsActive] = useState(false);
  const [notificationsActive, setNotificationsActive] = useState(false);
  const [hintsActive, setHintsActive] = useState(false);
  const [numOfRequests, setnumOfRequests] = useState(0);
  const [manuActive, setManuActive] = useState(false);
  const [rebarActive, setRebarActive] = useState(false);
  const [supportActive, setSupportActive] = useState(false);

  const handleDrawerOpen = () => {
    props.setOpen(true);
  };

  const handleDrawerClose = () => {
    props.setOpen(false);
  };
  useEffect(() => {
    if (window.location.pathname === "/mainContractors") {
      setUsersActive(true);
      setMCActive(true);
    } else if (window.location.pathname === "/subContractors") {
      setUsersActive(true);
      setSCActive(true);
    } else if (window.location.pathname.includes("/teamMembers")) {
      setMCActive(false);
      setUsersActive(true);
      setTeamMemberActive(true);
    } else if (window.location.pathname === "/requestSubscription") {
      setSubscriptionActive(true);
      setRequestActive(true);
    } else if (window.location.pathname === "/manageSubscription") {
      setSubscriptionActive(true);
      setManageActive(true);
    } else if (window.location.pathname === "/projects") {
      setProjectsActive(true);
    } else if (window.location.pathname === "/reports") {
      setReportsActive(true);
    } else if (window.location.pathname === "/notifications") {
      setNotificationsActive(true);
    } else if (window.location.pathname === "/tips") {
      setHintsActive(true);
    } else if (
      window.location.pathname === "/Manu/rebarTable" ||
      window.location.pathname === "/Manu/shapeBoard"
    ) {
      setManuActive(true);
      setRebarActive(true);
    } else if (window.location.pathname === "/support") {
      setSupportActive(true);
    }
  });
  const getTheNumofRequests = () => {
    getSubscriptionsRequNo().then((x) => {
      setnumOfRequests(x.data);
    });
  };
  return (
    <Box sx={{ display: "flex", width: window.innerWidth }}>
      <CssBaseline />

      <Drawer
        variant="permanent"
        open={props.open}
        sx={{ backgroundColor: "#fcfafa", flexShrink: 0 }}
      >
        <DrawerHeader
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <IconButton onClick={handleDrawerOpen}>
            <img
              src={props.open ? Logo1 : Logo}
              width={props.open ? 200 : 50}
              alt={"Logo"}
            />
          </IconButton>
        </DrawerHeader>

        <List style={{ paddingTop: "35px" }}>
          <ListItem
            className={
              usersActive ? classes["DrawerItemActive"] : classes["DrawerItem"]
            }
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => {
              setUsersActive(true);
              setSubscriptionActive(false);
              setProjectsActive(false);
              navigate("/mainContractors");
              handleDrawerClose();
              setMCActive(true);
              setSCActive(false);
              setTeamMemberActive(false);
              setRequestActive(false);
              setManageActive(false);
              setReportsActive(false);
              setNotificationsActive(false);
              setHintsActive(false);
              setManuActive(false);
              setRebarActive(false);
              setSupportActive(false);
            }}
          >
            <BlueTip
              title={props.open ? null : "SaaS Users"}
              placement="right"
              interactive
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={Userspic} alt={"users"} />
              </ListItemIcon>
            </BlueTip>
            <ListItemText
              primary={"SaaS Users"}
              sx={{
                opacity: props.open ? 1 : 0,
                fontFamily: "Muli",
                color: "#707070",
                fontSize: 16,
                fontWeight: "bold",
              }}
            />
          </ListItem>
          <ol style={{ marginLeft: 30 }}>
            <li
              className={mCActive ? classes["activeClass"] : classes["liItem"]}
              onClick={() => {
                navigate("/mainContractors");
                setMCActive(true);
                setUsersActive(true);
                setProjectsActive(false);
                setSubscriptionActive(false);
                setSCActive(false);
                setTeamMemberActive(false);
                setRequestActive(false);
                setManageActive(false);
                setReportsActive(false);
                setNotificationsActive(false);
                setHintsActive(false);
                setManuActive(false);
                setRebarActive(false);
                handleDrawerClose();
                setSupportActive(false);
              }}
            >
              Main Contractors
            </li>
            <li
              className={sCActive ? classes["activeClass"] : classes["liItem"]}
              onClick={() => {
                navigate("/subContractors");
                setMCActive(false);
                setUsersActive(true);
                setProjectsActive(false);
                setSubscriptionActive(false);
                setSCActive(true);
                setTeamMemberActive(false);
                setRequestActive(false);
                setManageActive(false);
                setReportsActive(false);
                setNotificationsActive(false);
                setHintsActive(false);
                setManuActive(false);
                setRebarActive(false);
                handleDrawerClose();
                setSupportActive(false);
              }}
            >
              Sub Contractors
            </li>
          </ol>

          <ListItem
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
            style={{ paddingTop: "20px" }}
            className={
              subscriptionActive
                ? classes["DrawerItemActive"]
                : classes["DrawerItem"]
            }
            onClick={() => {
              setUsersActive(false);
              setSubscriptionActive(true);
              setProjectsActive(false);
              setRequestActive(true);
              setMCActive(false);
              setSCActive(false);
              setTeamMemberActive(false);
              setReportsActive(false);
              setNotificationsActive(false);
              setHintsActive(false);
              setManageActive(false);
              setManuActive(false);
              setRebarActive(false);
              setSupportActive(false);

              navigate("/requestSubscription");
            }}
          >
            <BlueTip
              title={props.open ? null : "Subscriptions"}
              placement="right"
              interactive
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={SubscPic} alt={"users"} />
              </ListItemIcon>
            </BlueTip>
            <ListItemText
              primary={"Subscriptions"}
              sx={{
                opacity: props.open ? 1 : 0,
                fontFamily: "Muli",
                color: "#707070",
                fontSize: 16,
                fontWeight: "bold",
              }}
            />
          </ListItem>

          <ol style={{ marginLeft: 30 }}>
            <li
              className={
                requestActive ? classes["activeClass"] : classes["liItem"]
              }
              onClick={() => {
                navigate("/requestSubscription");
                setMCActive(false);
                setUsersActive(false);
                setProjectsActive(false);
                setSubscriptionActive(true);
                setSCActive(false);
                setTeamMemberActive(false);
                setRequestActive(true);
                setReportsActive(false);
                setNotificationsActive(false);
                setHintsActive(false);
                setManageActive(false);
                setManuActive(false);
                setRebarActive(false);
                setSupportActive(false);

                handleDrawerClose();
              }}
            >
              Subscription Request
              <Badge
                badgeContent={numOfRequests}
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: 10,
                    height: 15,
                    minWidth: 20,
                    fontFamily: "Muli",
                    backgroundColor: "#e1e319",
                    color: "#fff",
                    left: 10,
                    bottom: 2,
                  },
                }}
              ></Badge>
            </li>

            <li
              className={
                manageActive ? classes["activeClass"] : classes["liItem"]
              }
              onClick={() => {
                navigate("/manageSubscription");
                setMCActive(false);
                setUsersActive(false);
                setProjectsActive(false);
                setSubscriptionActive(true);
                setSCActive(false);
                setTeamMemberActive(false);
                setRequestActive(false);
                setManageActive(true);
                setReportsActive(false);
                setNotificationsActive(false);
                setHintsActive(false);
                setManuActive(false);
                setRebarActive(false);
                setSupportActive(false);

                handleDrawerClose();
              }}
            >
              Manage Subscription
            </li>
          </ol>
          <ListItem
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
            style={{ paddingTop: "20px" }}
            className={
              projectsActive
                ? classes["DrawerItemActive"]
                : classes["DrawerItem"]
            }
            onClick={() => {
              setUsersActive(false);
              setSubscriptionActive(false);
              setProjectsActive(true);
              setMCActive(false);
              setReportsActive(false);
              setNotificationsActive(false);
              setHintsActive(false);
              setSCActive(false);
              setTeamMemberActive(false);
              setRequestActive(false);
              setManageActive(false);
              setManuActive(false);
              setRebarActive(false);
              navigate("/projects");
              setSupportActive(false);

              handleDrawerClose();
            }}
          >
            <BlueTip
              title={props.open ? null : "Projects"}
              placement="right"
              interactive
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={Projects1Pic} alt={"users"} />
              </ListItemIcon>
            </BlueTip>
            <ListItemText
              primary={"Projects"}
              sx={{
                opacity: props.open ? 1 : 0,
                fontFamily: "Muli",
                color: "#707070",
                fontSize: 16,
                fontWeight: "bold",
              }}
            />
          </ListItem>
          <ListItem
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
            style={{ paddingTop: "20px" }}
            className={
              reportsActive
                ? classes["DrawerItemActive"]
                : classes["DrawerItem"]
            }
            onClick={() => {
              setUsersActive(false);
              setSubscriptionActive(false);
              setProjectsActive(false);
              setMCActive(false);
              setReportsActive(true);
              setNotificationsActive(false);
              setHintsActive(false);
              setSCActive(false);
              setTeamMemberActive(false);
              setRequestActive(false);
              setManageActive(false);
              setManuActive(false);
              setRebarActive(false);
              setSupportActive(false);

              navigate("/reports");
              handleDrawerClose();
            }}
          >
            <BlueTip
              title={props.open ? null : "Reports"}
              placement="right"
              interactive
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={reportsLogo} alt={"Reports"} />
              </ListItemIcon>
            </BlueTip>
            <ListItemText
              primary={"Reports"}
              sx={{
                opacity: props.open ? 1 : 0,
                fontFamily: "Muli",
                color: "#707070",
                fontSize: 16,
                fontWeight: "bold",
              }}
            />
          </ListItem>
          <ListItem
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
            style={{ paddingTop: "20px" }}
            className={
              notificationsActive
                ? classes["DrawerItemActive"]
                : classes["DrawerItem"]
            }
            onClick={() => {
              setUsersActive(false);
              setSubscriptionActive(false);
              setProjectsActive(false);
              setMCActive(false);
              setReportsActive(false);
              setNotificationsActive(true);
              setHintsActive(false);
              setReportsActive(false);
              setNotificationsActive(true);
              setHintsActive(false);
              setSCActive(false);
              setTeamMemberActive(false);
              setRequestActive(false);
              setManageActive(false);
              setManuActive(false);
              setRebarActive(false);
              setSupportActive(false);

              navigate("/notifications");
              handleDrawerClose();
            }}
          >
            <BlueTip
              title={props.open ? null : "Notifications"}
              placement="right"
              interactive
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={notiLogo} alt={"Notifications"} />
              </ListItemIcon>
            </BlueTip>
            <ListItemText
              primary={"Notifications"}
              sx={{
                opacity: props.open ? 1 : 0,
                fontFamily: "Muli",
                color: "#707070",
                fontSize: 16,
                fontWeight: "bold",
              }}
            />
          </ListItem>
          <ListItem
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
            style={{ paddingTop: "20px" }}
            className={
              hintsActive ? classes["DrawerItemActive"] : classes["DrawerItem"]
            }
            onClick={() => {
              setUsersActive(false);
              setSubscriptionActive(false);
              setProjectsActive(false);
              setMCActive(false);
              setReportsActive(false);
              setNotificationsActive(false);
              setHintsActive(true);
              setSCActive(false);
              setTeamMemberActive(false);
              setRequestActive(false);
              setManageActive(false);
              setManuActive(false);
              setRebarActive(false);
              setSupportActive(false);

              navigate("/tips");
              handleDrawerClose();
            }}
          >
            <BlueTip
              title={props.open ? null : "Tips"}
              placement="right"
              interactive
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={ProjectsPic} alt={"users"} />
              </ListItemIcon>
            </BlueTip>
            <ListItemText
              primary={"Tips"}
              sx={{
                opacity: props.open ? 1 : 0,
                fontFamily: "Muli",
                color: "#707070",
                fontSize: 16,
                fontWeight: "bold",
              }}
            />
          </ListItem>
          <ListItem
            className={
              manuActive ? classes["DrawerItemActive"] : classes["DrawerItem"]
            }
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => {
              setUsersActive(false);
              setSubscriptionActive(false);
              setProjectsActive(false);
              navigate("/Manu/rebarTable");
              handleDrawerClose();
              setMCActive(false);
              setSCActive(false);
              setTeamMemberActive(false);
              setRequestActive(false);
              setManageActive(false);
              setReportsActive(false);
              setNotificationsActive(false);
              setHintsActive(false);
              setManuActive(true);
              setSupportActive(false);
              setRebarActive(true);
            }}
          >
            <BlueTip
              title={props.open ? null : "Manufacturing"}
              placement="right"
              interactive
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={ManuIcon} alt={"ManuIcon"} />
              </ListItemIcon>
            </BlueTip>
            <ListItemText
              primary={"Manufacturing"}
              sx={{
                opacity: props.open ? 1 : 0,
                fontFamily: "Muli",
                color: "#707070",
                fontSize: 16,
                fontWeight: "bold",
              }}
            />
          </ListItem>
          <ol style={{ marginLeft: 30 }}>
            <li
              className={
                rebarActive ? classes["activeClass"] : classes["liItem"]
              }
              onClick={() => {
                navigate("/Manu/rebarTable");
                setMCActive(false);
                setUsersActive(false);
                setProjectsActive(false);
                setSubscriptionActive(false);
                setSCActive(false);
                setTeamMemberActive(false);
                setRequestActive(false);
                setManageActive(false);
                setReportsActive(false);
                setNotificationsActive(false);
                setHintsActive(false);
                setManuActive(true);
                setRebarActive(true);
                setSupportActive(false);
                handleDrawerClose();
              }}
            >
              Rebar Profile
            </li>
          </ol>
          <ListItem
            className={
              supportActive
                ? classes["DrawerItemActive"]
                : classes["DrawerItem"]
            }
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => {
              setUsersActive(false);
              setSubscriptionActive(false);
              setProjectsActive(false);
              navigate("/support");
              handleDrawerClose();
              setMCActive(false);
              setSCActive(false);
              setTeamMemberActive(false);
              setRequestActive(false);
              setManageActive(false);
              setReportsActive(false);
              setNotificationsActive(false);
              setHintsActive(false);
              setManuActive(false);
              setSupportActive(true);
              setRebarActive(false);
            }}
          >
            <BlueTip
              title={props.open ? null : "Support"}
              placement="right"
              interactive
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={SupportPic} alt={"ManuIcon"} />
              </ListItemIcon>
            </BlueTip>
            <ListItemText
              primary={"Support"}
              sx={{
                opacity: props.open ? 1 : 0,
                fontFamily: "Muli",
                color: "#707070",
                fontSize: 16,
                fontWeight: "bold",
              }}
            />
          </ListItem>
          <ListItem
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
            style={{ paddingTop: "20px" }}
            className={classes["DrawerItem"]}
            onClick={() => {
              localStorage.clear();
              props.setToken(null);

              handleDrawerClose();
              navigate("/");
            }}
          >
            <BlueTip
              title={props.open ? null : "Logout"}
              placement="right"
              interactive
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={LogoutIcon} alt={"users"} />
              </ListItemIcon>
            </BlueTip>
            <ListItemText
              primary={"Log Out"}
              sx={{
                opacity: props.open ? 1 : 0,
                fontFamily: "Muli",
                color: "#707070",
                fontSize: 16,
                fontWeight: "bold",
              }}
            />
          </ListItem>
        </List>
      </Drawer>

      <Grid container>
        <Grid item md={12}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              getTheNumofRequests();
              if (props.open) handleDrawerClose();
              else if (!props.open) handleDrawerOpen();
            }}
            edge="start"
            sx={{
              marginLeft: 3,
              marginRight: 5,
              ...props.open,
            }}
          >
            <MenuIcon htmlColor="#101650" fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "rgb(252 250 250)",
  boxShadow: "0 3px 6px 0 #908c8c",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "rgb(252 250 250)",
  boxShadow: "0 3px 6px 0 #908c8c",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

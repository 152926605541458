import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/SaasUser.module.css";
import * as SaaSServicesService from "../../../Services/saas-users-services.proxy";
import MainLogo from "../../../Assets/Images/IconsProfile/mainlogo.png";
import { useNavigate } from "react-router-dom";
const getMainContractorDetails = SaaSServicesService.getMainContractorDetails;
const MainContractorDetails = (props) => {
  const navigate = useNavigate();
  const [mainContractorDetails, setMainContractorDetails] = useState({});
  useEffect(() => {
    if (props.rowData) {
      getMainContractorDetails(props.rowData.row.companyId).then((x) => {
        setMainContractorDetails(x);
      });
    }
  }, []);
  const renderFields = (FieldName, FieldData) => {
    return (
      <Grid container gap={2} sm={3.5}>
        <Grid md={12} item className={classes["FieldHeader"]}>
          {FieldName}
        </Grid>
        <Grid md={12} item className={classes["FieldData"]}>
          {FieldData}
        </Grid>
      </Grid>
    );
  };
  
  return (
    <Grid container paddingBottom={10}>
      <Grid container item direction={"row"}>
        <Grid item className={classes["TableName"]} width={130} marginLeft={4}>
          Main Contractor
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={9} container direction={"row-reverse"} gap={3}>
          <Grid item paddingBottom={1}>
            <button
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#0674b9";
                e.target.style.color = "#fff";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#0674b9";
              }}
              onClick={() => {
                props.setViewDetails(false);
              }}
              className={classes["GoBack"]}
            >
              Go Back
            </button>
          </Grid>
          <Grid item paddingBottom={1}>
            <button
              style={{ width: "fit-content" }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#0674b9";
                e.target.style.color = "#fff";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#0674b9";
              }}
              onClick={() => {
                navigate(`/teamMembers?id=${props.rowData.row.companyId}`);
              }}
              className={classes["GoBack"]}
            >
              View Team Members
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        borderTop={"solid lightGrey 0.5px "}
        paddingLeft={4}
        paddingTop={5}
        gap={3}
      >
        <Grid container gap={2}>
          <Grid md={12} xs={12} item className={classes["FieldHeader"]}>
            Company Name
          </Grid>
          <Grid md={3.5} xs={12} item className={classes["FieldData"]}>
            {mainContractorDetails.CompanyName}
          </Grid>
        </Grid>
        <Grid container gap={3}>
          <Grid container gap={2} md={3.5}>
            <Grid md={12} item className={classes["FieldHeader"]}>
              Company Profile
            </Grid>
            <Grid md={12} item className={classes["FieldData"]}>
              <img
                src={
                  mainContractorDetails.UserDetails?.ProfilePicture
                    ? mainContractorDetails.UserDetails.ProfilePicture.URL
                    : MainLogo
                }
                style={{ width: 27 }}
                alt={"ptofile"}
              ></img>
            </Grid>
          </Grid>
          {renderFields(
            "Registration  Email",
            mainContractorDetails.CompanyEmail
          )}
          {renderFields("Subscription Plan", mainContractorDetails.PlanName)}
          {renderFields(
            "Subscription Status",
            mainContractorDetails.PlanStatus
          )}
          {renderFields("Speciality", mainContractorDetails.Speciality)}
          {renderFields("Country", mainContractorDetails.Country)}
          {renderFields("Company Address", mainContractorDetails.Address)}
          {renderFields(
            "Registration Number",
            mainContractorDetails.RegistrationNumber
          )}
          {renderFields("Employee Range", mainContractorDetails.EmployeesRange)}
          {renderFields(
            "Phone Number",
            mainContractorDetails.CompanyPhoneNumber
          )}
          {renderFields(
            "Contact Name",
            mainContractorDetails.UserDetails?.Name
          )}
          {renderFields(
            "Position",
            mainContractorDetails.UserDetails?.Position
          )}
          {renderFields(
            "Contact Email",
            mainContractorDetails.UserDetails?.PersonalEmail
          )}
          {renderFields(
            "Contact Phone Number",
            mainContractorDetails.UserDetails?.PhoneNumber
          )}
          {renderFields(
            "Joining Date",
            mainContractorDetails.CreationTime?.slice(0, 10)
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainContractorDetails;

import React, { useState, useEffect } from "react";
import classes from "../../../Assets/Styles/SaasUser.module.css";
import { Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Table from "./Table";
import TeamMemberDetails from "./TeamMemberDetails";
const TeamMemberView = (props) => {
  useEffect(() => {
    props.setHideDrawer(false);
  });
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [searchText, setSearchText] = useState(null);
  const [viewDetails, setViewDetails] = useState(false);
  const [rowData, setRowData] = useState(false);

  return (
    <Grid container paddingTop={9} md={12}>
      <Grid item container direction={"row"} marginLeft={4}>
        <Grid item className={classes["page-title"]} md={3} xs={12}>
          Team Members
        </Grid>
        {!viewDetails ? (
          <Grid
            md={9}
            paddingLeft={{ md: 7, xs: 0 }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ marginLeft: 15 }}
                  />
                ),
                style: { fontFamily: "Muli" },
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,

                border: "solid 1px #e9e7e7",
                width: {
                  xs: 1,
                  sm: 483,
                },
              }}
            />
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      <Grid item container paddingTop={5} md={12}>
        {viewDetails ? (
          <TeamMemberDetails
            setViewDetails={setViewDetails}
            rowData={rowData}
          />
        ) : (
          <Table
            setViewDetails={setViewDetails}
            setRowData={setRowData}
            searchText={searchText}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TeamMemberView;

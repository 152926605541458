import React, { useEffect, useState, useMemo } from "react";
import {
  Grid,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import classes from "../../../Assets/Styles/SaasUser.module.css";
import * as SaaSServicesService from "../../../Services/saas-users-services.proxy";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";

const getFactories = SaaSServicesService.getFactories;
const exportReportFactories = SaaSServicesService.exportReportFactories;

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 15,
  },
}))(TableRow);
const FactoriesList = () => {
  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const companyId = query.get("id");
  const companyName = query.get("name");

  const [factories, setFactories] = useState([]);
  const [dnYear, setDnYear] = useState(0);
  const [dnYears, setDnYears] = useState([2020, 2021, 2022, 2023]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  useEffect(() => {
    if (companyId && dnYear) {
      setLoadingData(true);

      getFactories(companyId, dnYear).then((x) => {
        setLoadingData(false);
        setFactories(x);
      });
    }
  }, [dnYear, companyId]);
  return (
    <Grid container paddingTop={9}>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item container direction={"row"} marginLeft={4}>
        <Grid item className={classes["page-title"]} md={3} xs={12}>
          SaaS Users
        </Grid>
      </Grid>
      <Grid container item direction={"row"} paddingTop={1}>
        <Grid
          item
          className={classes["TableNameSub"]}
          width={150}
          marginLeft={4}
        >
          Sub Contractor - {companyName ? companyName : ""}
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={9} container direction={"row-reverse"} gap={1}>
          <Grid item paddingTop={1}>
            <button
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#0674b9";
                e.target.style.color = "#fff";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#0674b9";
              }}
              onClick={() => {
                navigate(-1);
              }}
              className={classes["GoBack"]}
            >
              Go Back
            </button>
          </Grid>

          <Grid item>
            <CustomSelect
              value={dnYear}
              onChange={(option) => {
                setDnYear(option);
              }}
            >
              <StyledOption value={0} disabled>
                Year
              </StyledOption>

              {dnYears.map((s) => (
                <StyledOption key={s} value={s}>
                  {s}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {dnYear ? (
            <Grid
              className={classes["clearFilter"]}
              onClick={() => {
                setDnYear(0);
              }}
            >
              Clear Filters
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        className={classes["Factories-Details"]}
        marginLeft={4}
        paddingTop={3}
      >
        Factory Details
      </Grid>
      <Grid
        item
        overflow={"auto"}
        container
        xs={11}
        md={11}
        marginLeft={4}
        borderRight={"solid 1px #559cca"}
      >
        <TableContainer sx={{ width: "100%" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              backgroundColor: "#fff",
              borderCollapse: "separate",
              borderSpacing: "0px 0px",
              border: 0,
            }}
          >
            <TableHead>
              <StyledTableRow>
                <TableCell sx={styles.tableCellHeadEmpty}></TableCell>
                <TableCell sx={styles.tableCellHeadEmpty}></TableCell>
                <TableCell sx={styles.tableCellHeadEmpty}></TableCell>

                <TableCell sx={styles.tableCellHead2}>
                  Weight of Delivery
                </TableCell>
                <TableCell sx={styles.tableCellHead}>Notes (Tons)</TableCell>
                <TableCell sx={styles.tableCellHead}></TableCell>
                <TableCell sx={styles.tableCellHead}></TableCell>
                <TableCell sx={styles.tableCellHead}></TableCell>
                <TableCell sx={styles.tableCellHead}></TableCell>
                <TableCell sx={styles.tableCellHead}></TableCell>
                <TableCell sx={styles.tableCellHead}></TableCell>
                <TableCell sx={styles.tableCellHead}></TableCell>
                <TableCell sx={styles.tableCellHead}></TableCell>
                <TableCell sx={styles.tableCellHead}></TableCell>
                <TableCell sx={styles.tableCellHead}></TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell sx={styles.tableCellEmptyWithBorder1}></TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder2}></TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder3}></TableCell>

                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  January &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  February &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  March &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  April &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  May &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  June &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  July &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  August &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  September &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  October &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  December &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
                <TableCell sx={styles.tableCellEmptyWithBorder4}>
                  November &nbsp; {dnYear === 0 ? "" : dnYear}
                </TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell sx={styles.tableCellFact}>Factory Code</TableCell>
                <TableCell sx={styles.tableCellFact}>Factory Country</TableCell>
                <TableCell sx={styles.tableCellFact}>
                  Factory Volume (Tons)
                </TableCell>

                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
                <TableCell sx={styles.tableCellFactEmpty}></TableCell>
              </StyledTableRow>
              <StyledTableRow>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
                <TableCell sx={styles.tableCellrowEmpty}></TableCell>
              </StyledTableRow>
              {factories.map((fact) => (
                <StyledTableRow>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.Code}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.Country}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.Volume}
                  </TableCell>

                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["0"]
                      ? fact.MonthlyWeights["0"] / 1000
                      : 0}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["1"]
                      ? fact.MonthlyWeights["1"] / 1000
                      : 0}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["2"]
                      ? fact.MonthlyWeights["2"] / 1000
                      : 0}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["3"]
                      ? fact.MonthlyWeights["3"] / 1000
                      : 0}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["4"]
                      ? fact.MonthlyWeights["4"] / 1000
                      : 0}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["5"]
                      ? fact.MonthlyWeights["5"] / 1000
                      : 0}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["6"]
                      ? fact.MonthlyWeights["6"] / 1000
                      : 0}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["7"]
                      ? fact.MonthlyWeights["7"] / 1000
                      : 0}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["8"]
                      ? fact.MonthlyWeights["8"] / 1000
                      : 0}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["9"]
                      ? fact.MonthlyWeights["9"] / 1000
                      : 0}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["10"]
                      ? fact.MonthlyWeights["10"] / 1000
                      : 0}
                  </TableCell>
                  <TableCell sx={styles.tableCellwithdata}>
                    {fact.MonthlyWeights["11"]
                      ? fact.MonthlyWeights["11"] / 1000
                      : 0}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        item
        container
        xs={11}
        md={11}
        marginLeft={4}
        paddingTop={2}
        paddingBottom={2}
        direction={"row-reverse"}
      >
        <button
          disabled={dnYear ? false : true}
          onClick={() => {
            setLoadingExport(true);
            exportReportFactories(companyId, dnYear).then((x) => {
              setLoadingExport(false);
            });
          }}
          className={classes["ExportButton"]}
        >
          {loadingExport ? "Exporting..." : "Export"}
        </button>
      </Grid>
    </Grid>
  );
};
const styles = {
  tableCellHead2: {
    backgroundColor: "rgba(13, 115, 180, 0.7)",
    fontFamily: "Muli",
    fontSize: 13,
    color: "#fff",
    fontWeight: "bold",
    lineHeight: 1.17,
    textAlign: "left",
    border: "solid 1px #559cca",
    minWidth: "126px",
    padding: 0,
    paddingLeft: "10px",
  },
  tableCellHead: {
    backgroundColor: "rgba(13, 115, 180, 0.7)",
    fontFamily: "Muli",
    fontSize: 13,
    color: "#fff",
    fontWeight: "bold",
    lineHeight: 1.17,
    textAlign: "left",
    border: "solid 1px #559cca",
    minWidth: "150px",
    padding: 0,
  },
  tableCellEmptyWithBorder1: {
    backgroundColor: "#fff",
    border: 0,
    borderTop: "solid 1px #559cca",
    borderLeft: "solid 1px #559cca",
    minWidth: "150px",
  },
  tableCellEmptyWithBorder2: {
    backgroundColor: "#fff",

    border: 0,
    borderTop: "solid 1px #559cca",
    minWidth: "150px",
  },
  tableCellEmptyWithBorder3: {
    backgroundColor: "#fff",
    border: 0,
    borderTop: "solid 1px #559cca",
    borderRight: "solid 1px #559cca",
    minWidth: "150px",
  },
  tableCellEmptyWithBorder4: {
    padding: 1,
    fontFamily: "Muli",
    fontSize: 13,
    fontWeight: "bold",
    color: "#559cca",
    backgroundColor: "#fff",
    border: "solid 2px #559cca",
    textAlign: "center",
  },
  tableCellHeadEmpty: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#101650",
    lineHeight: 1.17,
    textAlign: "left",
    border: 0,
    minWidth: "250px",
  },
  tableCellFact: {
    backgroundColor: "rgba(13, 115, 180, 0.7)",
    fontFamily: "Muli",
    fontSize: 13,
    color: "#fff",
    fontWeight: "bold",
    lineHeight: 1.17,
    textAlign: "center",
    border: "solid 1px #559cca",
    borderLeft: "solid 2px #fff",
    padding: 1,
  },
  tableCellFactEmpty: {
    backgroundColor: "rgba(13, 115, 180, 0.7)",

    border: "solid 1px #559cca",
    padding: 0,
  },
  tableCellrowEmpty: {
    padding: 0,
  },
  tableCellwithdata: {
    padding: 2,
    fontFamily: "Muli",
    fontSize: 13,
    fontWeight: "bold",
    color: "#559cca",
    backgroundColor: "#fff",
    border: "solid 2px #559cca",
    borderLeft: "solid 1px #559cca",
    borderTop: "solid 1px #559cca",

    textAlign: "center",
  },
};
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 12px;
      box-sizing: border-box;
      min-height: 32px;
      width: 9rem;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
      border-radius: 0.75em;
      margin: 0.5em;
      padding: 10px;
      text-align: left;
      line-height: 1.5;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    
      &:hover {
        background: ${theme.palette.mode === "dark" ? "" : grey[100]};
        border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &.${selectUnstyledClasses.focusVisible} {
        outline: 3px solid ${
          theme.palette.mode === "dark" ? blue[600] : blue[100]
        };
      }
    
      &.${selectUnstyledClasses.expanded} {
        &::after {
          content: '▴';
        }
      }
    
      &::after {
        content: '▾';
        float: right;
      }
      `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 0.875rem;
      box-sizing: border-box;
      padding: 5px;
      max-height:250px !important;
      margin: 10px 0;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
      border-radius: 0.75em;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      overflow: auto;
      outline: 0px;
      `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 0.45em;
      cursor: default;
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.highlighted} {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
    
      &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
      `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default FactoriesList;

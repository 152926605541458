import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/ManageModal.module.css";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import * as SubscriptionService from "../../../Services/subscription-services.proxy";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Loading from "../../../Components/Loading";
import moment from "moment/moment";
const getSubscriptionsManagedInputData =
  SubscriptionService.getSubscriptionsManagedInputData;
const AddManagedPlan = SubscriptionService.AddManagedPlan;
const getinfoPlan = SubscriptionService.getinfoPlan;
const renewManagedPlan = SubscriptionService.renewManagedPlan;
const getinfoPlanByEMAIL = SubscriptionService.getinfoPlanByEMAIL;

const style = {
  position: "absolute",
  top: "53%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  overflowY: "scroll",
  outline: "none",
  display: "block",
  border: "#fff",
  borderRadius: "5px",
};
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:180px;
   
  `);
const CustomPlanModal = (props) => {
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [accountBundle, setAccountBundle] = useState("");
  const [accountBundleError, setAccountBundleError] = useState(false);
  const [planType, setPlanType] = useState("");
  const [planTypeError, setPlanTypeError] = useState(false);
  const [billingPlan, setBillingPlan] = useState("");
  const [billingPlanError, setBillingPlanError] = useState(false);
  const [planName, setPlanName] = useState("");
  const [planNameError, setPlanNameError] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState(false);
  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateError] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [endDateError, setEndDateError] = useState(false);
  const [paymentReferenceNum, setPaymentReferenceNum] = useState("");
  const [paymentReferenceNumError, setPaymentReferenceNumError] =
    useState(false);
  const [factoriesNum, setFactoriesNum] = useState("");
  const [factoriesNumError, setFactoriesNumError] = useState(false);
  const [factoryVolume, setFactoryVolume] = useState("");
  const [factoryVolumeError, setFactoryVolumeError] = useState(false);
  const [billingPlans, setBillingPlans] = useState([]);
  const [plans, setPlans] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [managedFieldsDisabled, setManagedFieldsDisabled] = useState(false);
  const [fieldsWoEmailDisabled, setfieldsWoEmailDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [engproplus, setEngproplus] = useState(null);
  const [emailNotRegistered, setEmailNotRegistered] = useState(false);
  const [emailErrorBE, setEmailErrorBE] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [planTypeDisabled, setPlanTypeDisabled] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
    props.setOpenSubscModal(false);
    setEmailErrorBE(null);
    setEmail("");
    setAccountBundle("");
    setPlanType("");
    setEngproplus(null);
    setBillingPlan("");
    setPlanName("");
    setCompanyName("");
    setPrice("");
    setStartDate("");
    setEndDate("");
    // setBillingPlans([]);
    setPaymentReferenceNum("");
    setEmailError(false);
    setEmailInvalid(false);
    setFieldsDisabled(false);
    setAccountBundleError(false);
    setPlanTypeError(false);
    setBillingPlanError(false);
    setPlanNameError(false);
    setCompanyNameError(false);
    setPriceError(false);
    setStartDateError(false);
    setEndDateError(false);
    setPaymentReferenceNumError(false);
    setManagedFieldsDisabled(false);
    props.setRowData("");
    setPlanTypeDisabled(false);
    setEmailNotRegistered(false);
  };
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);

  useEffect(() => {
    let apiTest = true;
    if (props.rowData?.row && open) {
      getinfoPlan(props.rowData.row.Id).then((x) => {
        if (apiTest) {
          setPlans(x.data.Plans);
          setFieldsDisabled(true);
          setManagedFieldsDisabled(true);
          setEmail(x.data.Email);
          setCompanyName(x.data.CompanyName);
          setBillingPlan(x.data.BillingTypeId);
          setPlanType(x.data.PlanId);
          if (x.data.PlanId === null) setEngproplus(1);
          setPrice(x.data.Price);
          setAccountBundle(x.data.SubscriptionTypeId);
          setPlanName(x.data.PlanName);
          setPaymentReferenceNum(x.data.PaymentReferenceNumber);
          setfieldsWoEmailDisabled(false);
        }
      });
    }
    return () => {
      apiTest = false;
    };
  }, [props.rowData, open]);

  useEffect(() => {
    setLoadingData(true);
    getSubscriptionsManagedInputData(props.subscEmailReq).then((x) => { 
      setLoadingData(false);
      if (x.status === 409) {
        setEmailNotRegistered(true);
      } else {
        if (x.data?.Email) {
          setFieldsDisabled(true);
          setBillingPlans(x.data.BillingPlans);
          setPlans(x.data.Plans);
          setSubscriptionTypes(x.data.SubscriptionTypes);
          setCompanyName(x.data.CompanyName);
          setEmail(x.data.Email);
          setPlanType(x.data.PlanId);
          if (x.data.PlanId === null) setEngproplus(1);
          setAccountBundle(x.data.SubscriptionTypeId);
          setfieldsWoEmailDisabled(false);
          setPlanTypeDisabled(true);
        } else {
          setBillingPlans(x.data.BillingPlans);
          setPlans(x.data.Plans);
          setSubscriptionTypes(x.data.SubscriptionTypes);
          // setfieldsWoEmailDisabled(false);
        }
      }
    });
  }, [props.subscEmailReq]);

  useEffect(() => {
    if (props.openModal || props.openSubscModal) {
      handleOpen(true);
    }
  }, [props.openModal, props.openSubscModal]);

  useEffect(() => {
    if (
      !email ||
      !accountBundle ||
      (!planType && !engproplus) ||
      !billingPlan ||
      !companyName ||
      !planName ||
      price === null ||
      !startDate ||
      !endDate ||
      !paymentReferenceNum ||
      emailInvalid ||
      emailError ||
      accountBundleError ||
      planTypeError ||
      billingPlanError ||
      companyNameError ||
      priceError ||
      planNameError ||
      startDateError ||
      endDateError ||
      paymentReferenceNumError ||
      ((planType === 9 || planType === 10 || planType === 11) &&
        (!factoriesNum ||
          factoriesNumError ||
          !factoryVolume ||
          factoryVolumeError))
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [
    email,
    accountBundle,
    planType,
    engproplus,
    billingPlan,
    companyName,
    planName,
    price,
    startDate,
    endDate,
    paymentReferenceNum,
    emailInvalid,
    emailError,
    accountBundleError,
    planTypeError,
    billingPlanError,
    companyNameError,
    priceError,
    planNameError,
    startDateError,
    endDateError,
    paymentReferenceNumError,
    factoriesNum,
    factoriesNumError,
    factoryVolume,
    factoryVolumeError,
  ]);

  //addType
  const addThePlan = () => {
    setSaving(true);
    AddManagedPlan(
      props.subscEmailReq,
      planName,
      email,
      companyName,
      planType,
      billingPlan,
      price,
      startDate,
      endDate,
      paymentReferenceNum,
      factoriesNum,
      factoryVolume
    ).then((x) => {
      props.setModify(!props.modify);
      if (x === 403) {
        setEmailError(true);
        setEmailInvalid(true);
      } else {
        setTimeout(() => {
          handleClose();
        }, 1500);
      }
      setSaving(false);
    });
  };

  //addType
  const renewThePlan = () => {
    setSaving(true);
    renewManagedPlan(
      props.rowData.row.Id,
      startDate,
      endDate,
      paymentReferenceNum,
      factoriesNum,
      factoryVolume
    ).then((x) => {
      if (x === 403) {
        setEmailError(true);
        setEmailInvalid(true);
      } else {
        props.setModify(!props.modify);
        setTimeout(() => {
          handleClose();
        }, 1500);
      }
      setSaving(false);
    });
  };
  useEffect(() => {
    if (startDate !== null) {
      console.log(startDate);
      if (billingPlan === 1) {
        setEndDate(moment(startDate).add(30, "days"));

        // let d = moment(startDate).add(7, "months");
        // d = d.format("llll");
      } else if (billingPlan === 2) {
        setEndDate(moment(startDate).add(365, "days"));
      }
    }

    // var date = new Date(startDate);
    // if (billingPlan === 1) {
    //   console.log(date);
    // date.setMonth(date.getMonth() + 2);
    // setEndDate(
    //   date.getMonth() + "-" + date.getDate() + "-" + date.getFullYear()
    // );
    // } else if (billingPlan === 2) {
    //   if (date.getMonth() === 11) {
    //     date.setFullYear(date.getFullYear() + 1);
    //     date.setMonth(date.getMonth());
    //   } else date.setMonth(date.getMonth() + 13);
    //   console.log(date);
    //   setEndDate(
    //     date.getMonth() + "-" + date.getDate() + "-" + date.getFullYear()
    //   );
    // }
  }, [startDate, billingPlan]);
  const getInfoByEmail = () => {
    getinfoPlanByEMAIL(email).then((x) => {
      if (x.status) {
        setEmailErrorBE(x.error);
      }

      setBillingPlans(x.data.BillingPlans);
      setPlans(x.data.Plans);
      setSubscriptionTypes(x.data.SubscriptionTypes);
      setCompanyName(x.data.CompanyName);
      setEmail(x.data.Email);
      setPlanType(x.data.PlanId);
      if (x.data.PlanId === null) setEngproplus(1);
      setAccountBundle(x.data.SubscriptionTypeId);
      setfieldsWoEmailDisabled(false);
    });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={style}
          height={{ sm: "58%", md: "90%" }}
          width={{ sm: "70%", md: "35%" }}
        >
          {loadingData ? (
            <Grid container justifyContent={"center"}>
              <Loading />
            </Grid>
          ) : (
            <Grid
              container
              spacing={0.5}
              paddingTop={5}
              justifyContent={"center"}
              gap={1}
              alignItems={"center"}
              paddingBottom={3}
            >
              <Grid
                item
                xs={3}
                className={classes["FieldLabel"]}
                paddingTop={1}
              >
                *Email
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  disabled={fieldsDisabled}
                  label="Email"
                  size="small"
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  InputProps={{
                    endAdornment: props.openSubscModal || props.rowData ? (
                      <></>
                    ) : (
                      <BlueTip
                        open={tooltipIsOpen}
                        title={"Please click the button before you continue "}
                        placement="right"
                      >
                        <button
                          className={classes["Confirm-Email"]}
                          onMouseOver={openTip}
                          onMouseLeave={closeTip}
                          onClick={() => {
                            openTip();
                            getInfoByEmail();
                          }}
                        >
                          Confirm
                        </button>
                      </BlueTip>
                    ),
                  }}
                  inputProps={{
                    maxLength: 64,
                  }}
                  value={email}
                  error={emailError}
                  helperText={
                    emailError ? (
                      emailInvalid ? (
                        "Enter a valid  email"
                      ) : (
                        "Enter an email"
                      )
                    ) : emailErrorBE ? (
                      <span style={{ color: "#D32F2F" }}>{emailErrorBE}</span>
                    ) : (
                      <></>
                    )
                  }
                  onBlur={(e) => {
                    if (!email) setEmailError(true);
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailErrorBE(null);
                    if (e.target.value === "") {
                      setEmailError(true);
                      setEmailInvalid(false);
                    } else if (
                      (e.target.value !== "") &
                      !e.target.value.match(
                        /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                      )
                    ) {
                      setEmailError(true);
                      setEmailInvalid(true);
                    } else {
                      setEmailError(false);
                      setEmailInvalid(false);
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes["FieldLabel"]}
                paddingTop={1}
              >
                *Account Bundle
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  // select
                  // disabled={fieldsDisabled || fieldsWoEmailDisabled}
                  disabled={true}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label="Account Bundle"
                  size="small"
                  value={
                    accountBundle === 1
                      ? "Main Contractor"
                      : accountBundle === 2 || accountBundle === 3
                      ? "Sub Contractor"
                      : ""
                  }
                  // error={accountBundleError}
                  // helperText={accountBundleError ? "Choose a Bundle" : <></>}
                  // onBlur={(e) => {
                  //   if (!accountBundle) setAccountBundleError(true);
                  // }}
                  // onChange={(e) => {
                  //   setAccountBundle(e.target.value);
                  //   if (e.target.value === "") {
                  //     setAccountBundleError(true);
                  //   } else {
                  //     setAccountBundleError(false);
                  //   }
                  // }}
                >
                  {/* {subscriptionTypes.map((option) => (
                    <MenuItem value={option.Id} key={option.Id}>
                      {option.Name}
                    </MenuItem>
                  ))} */}
                </TextField>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes["FieldLabel"]}
                paddingTop={1}
              >
                *Company Name
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  // disabled={fieldsDisabled || fieldsWoEmailDisabled}
                  disabled={true}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label="Company Name"
                  size="small"
                  inputProps={{
                    maxLength: 150,
                  }}
                  value={companyName}
                  // error={companyNameError}
                  // helperText={companyNameError ? "Enter company name" : <></>}
                  // onBlur={(e) => {
                  //   if (!companyName) setCompanyNameError(true);
                  // }}
                  // onChange={(e) => {
                  //   setCompanyName(e.target.value);
                  //   if (e.target.value === "") {
                  //     setCompanyNameError(true);
                  //   } else {
                  //     setCompanyNameError(false);
                  //   }
                  // }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes["FieldLabel"]}
                paddingTop={1}
              >
                *Plan Type
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  select
                  disabled={
                    fieldsWoEmailDisabled ||
                    managedFieldsDisabled ||
                    planTypeDisabled
                  }
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label="Plan Type"
                  size="small"
                  value={planType === null ? 1 : planType}
                  error={planTypeError}
                  helperText={planTypeError ? "Choose a Plan" : <></>}
                  onBlur={(e) => {
                    if (!planType && engproplus !== 1) setPlanTypeError(true);
                  }}
                  onChange={(e) => {
                    setPlanType(e.target.value);
                    if (e.target.value === null) setEngproplus(1);
                    if (e.target.value === "" && engproplus !== 1) {
                      setPlanTypeError(true);
                    } else {
                      setPlanTypeError(false);
                    }
                  }}
                >
                  {plans.map((option) => (
                    <MenuItem
                      value={option.Id === null ? 1 : option.Id}
                      key={option.Id}
                    >
                      {option.Id === null ? "Engineering Pro +" : option.Name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes["FieldLabel"]}
                paddingTop={1}
              >
                *Billing Plan
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label="Billing Plan"
                  size="small"
                  disabled={managedFieldsDisabled || fieldsWoEmailDisabled}
                  value={billingPlan}
                  error={billingPlanError}
                  helperText={
                    billingPlanError ? "Choose a Billing plan" : <></>
                  }
                  onBlur={(e) => {
                    if (!billingPlan) setBillingPlanError(true);
                  }}
                  onChange={(e) => {
                    setBillingPlan(e.target.value);
                    if (e.target.value === "") {
                      setBillingPlanError(true);
                    } else {
                      setBillingPlanError(false);
                    }
                  }}
                >
                  {billingPlans.map((option) => (
                    <MenuItem value={option.Id} key={option.Id}>
                      {option.Name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                xs={3}
                className={classes["FieldLabel"]}
                paddingTop={1}
              >
                *Plan Name
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label="Plan Name"
                  size="small"
                  disabled={managedFieldsDisabled || fieldsWoEmailDisabled}
                  inputProps={{
                    maxLength: 150,
                  }}
                  value={planName}
                  error={planNameError}
                  helperText={planNameError ? "Enter plan name" : <></>}
                  onBlur={(e) => {
                    if (!planName) setPlanNameError(true);
                  }}
                  onChange={(e) => {
                    setPlanName(e.target.value);
                    if (e.target.value === "") {
                      setPlanNameError(true);
                    } else {
                      setPlanNameError(false);
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes["FieldLabel"]}
                paddingTop={1}
              >
                *Price
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label="Price"
                  size="small"
                  inputProps={{
                    maxLength: 50,
                  }}
                  value={price}
                  error={priceError}
                  disabled={fieldsWoEmailDisabled || managedFieldsDisabled}
                  helperText={priceError ? "Enter a price" : <></>}
                  onBlur={(e) => {
                    if (!price) setPriceError(true);
                  }}
                  onChange={(e) => {
                    setPrice(e.target.value);
                    if (e.target.value === "") {
                      setPriceError(true);
                    } else {
                      setPriceError(false);
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className={classes["FieldLabel"]}
                paddingTop={1}
              >
                *Start Date
              </Grid>
              <Grid item xs={7}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    openTo="day"
                    disablePast
                    views={["year", "month", "day"]}
                    label="DD-MM-YYYY"
                    disableMaskedInput
                    inputFormat="dd/MM/yyyy"
                    value={startDate}
                    disabled={fieldsWoEmailDisabled}
                    onChange={(newValue) => {
                      if (newValue === "") {
                        setStartDateError(true);
                      } else {
                        setStartDateError(false);
                      }
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: {
                            fontFamily: "Muli",
                            fontSize: "13px",
                          },
                        }}
                        error={startDateError}
                        onBlur={() => {
                          if (!startDate) setStartDateError(true);
                        }}
                        helperText={startDateError ? "Enter a date" : <></>}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes["FieldLabel"]}
                paddingTop={1}
              >
                *End Date
              </Grid>
              <Grid item xs={7}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    openTo="day"
                    disabled
                    views={["day", "month", "year"]}
                    label="DD-MM-YYYY"
                    format="DD-MM-YYYY"
                    inputFormat="dd/MM/yyyy"
                    value={endDate}
                    onChange={(newValue) => {}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: {
                            fontFamily: "Muli",
                            fontSize: "13px",
                          },
                        }}
                        error={endDateError}
                        onBlur={() => {
                          if (!endDate) setEndDateError(true);
                        }}
                        helperText={endDateError ? "Enter a date" : <></>}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes["FieldLabel"]}
                paddingTop={1}
              >
                *Payment Reference Number
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                  label="Payment Reference Number"
                  size="small"
                  value={paymentReferenceNum}
                  error={paymentReferenceNumError}
                  disabled={fieldsWoEmailDisabled}
                  helperText={
                    paymentReferenceNumError ? (
                      "Enter a payment reference number"
                    ) : (
                      <></>
                    )
                  }
                  onBlur={(e) => {
                    if (!paymentReferenceNum) setPaymentReferenceNumError(true);
                  }}
                  onChange={(e) => {
                    setPaymentReferenceNum(e.target.value);

                    if (e.target.value === "") {
                      setPaymentReferenceNumError(true);
                    } else {
                      setPaymentReferenceNumError(false);
                    }
                  }}
                />
              </Grid>

              {planType === 9 || planType === 10 || planType === 11 ? (
                <>
                  <Grid
                    item
                    xs={3}
                    className={classes["FieldLabel"]}
                    paddingTop={1}
                  >
                    *Number of Factories
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        style: {
                          fontFamily: "Muli",
                          fontSize: "13px",
                        },
                      }}
                      inputProps={{
                        maxLength: 50,
                      }}
                      label="Number of Factories"
                      size="small"
                      value={factoriesNum}
                      error={factoriesNumError}
                      disabled={fieldsWoEmailDisabled}
                      helperText={
                        factoriesNumError ? "Enter a factories number" : <></>
                      }
                      onBlur={(e) => {
                        if (!factoriesNum) setFactoriesNumError(true);
                      }}
                      onChange={(e) => {
                        setFactoriesNum(e.target.value);

                        if (e.target.value === "") {
                          setFactoriesNumError(true);
                        } else {
                          setFactoriesNumError(false);
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className={classes["FieldLabel"]}
                    paddingTop={1}
                  >
                    *Factory Volume
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        style: {
                          fontFamily: "Muli",
                          fontSize: "13px",
                        },
                      }}
                      inputProps={{
                        maxLength: 50,
                      }}
                      label="Factory Volume"
                      size="small"
                      value={factoryVolume}
                      error={factoryVolumeError}
                      disabled={fieldsWoEmailDisabled}
                      helperText={
                        factoryVolumeError ? "Enter a factory volume" : <></>
                      }
                      onBlur={(e) => {
                        if (!factoryVolume) setFactoryVolumeError(true);
                      }}
                      onChange={(e) => {
                        setFactoryVolume(e.target.value);

                        if (e.target.value === "") {
                          setFactoryVolumeError(true);
                        } else {
                          setFactoryVolumeError(false);
                        }
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Grid item sm={10.2} md={10.2} paddingTop={2}>
                <button
                  disabled={isDisabled || fieldsWoEmailDisabled || saving}
                  onClick={() => {
                    if (props.rowData) {
                      renewThePlan();
                    } else {
                      addThePlan();
                    }
                  }}
                  className={classes["Save-Plan"]}
                >
                  {saving ? "Saving..." : "Save"}
                </button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Modal>
    </div>
  );
};

export default CustomPlanModal;

import { Grid } from "@mui/material";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SaasUsers from "../Pages/SaasUsers";
import MainContractorView from "../Components/SaaSUsers/MainContractor/MainContractorView";
import SubContractorView from "../Components/SaaSUsers/SubContractor/SubContractorView";
import ProjectsView from "../Components/Projects/ProjectsView";
import SideBar from "../Routes/SideBar";
import Login from "../Pages/Login";
import Requests from "../Components/Subscription/Requests/Requests";
import Manage from "../Components/Subscription/Manage/Manage";
import TeamMemberView from "../Components/SaaSUsers/Team Members/TeamMemberView";
import Reports from "../Components/Reports/Reports";
import Notifications from "../Components/Notifications/NotificationsView";
import Tips from "../Components/Tips/Tips";
import ShapesTable from "../Components/Shapes/ShapesTable";
import AddShape from "../Components/Shapes/ShapesBoard/AddShape";
import AssignSupport from "../Components/Support/AssignSupport";
import FactoriesList from "../Components/SaaSUsers/SubContractor/FactoriesList";
export default function Navigate() {
  const [hideDrawer, setHideDrawer] = useState(false);
  const [openSubscModal, setOpenSubscModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [subscEmailReq, setSubscEmailReq] = useState("");
  const [token, setToken] = useState(localStorage.getItem("accessToken"));
  return (
    <Grid container>
      <Router>
        <Grid
          item
          md={hideDrawer ? 0 : open ? 2 : 0.5}
          sm={hideDrawer ? 0 : open ? 4 : 0.5}
          xs={hideDrawer ? 0 : open ? 10 : 1}
          style={{ transition: "ease-in-out" }}
        >
          {hideDrawer ? (
            <></>
          ) : (
            <SideBar open={open} setOpen={setOpen} setToken={setToken} />
          )}
        </Grid>
        <Grid
          item
          md={hideDrawer ? 12 : open ? 10 : 11.5}
          sm={hideDrawer ? 12 : open ? 8 : 11.5}
          xs={hideDrawer ? 12 : open ? 2 : 11}
          style={{ transition: "ease-in-out" }}
          onMouseOver={(e) => {
            if (open) {
              e.target.style.cursor = "pointer";
            } else {
              e.target.style.cursor = "unset";
            }
          }}
          onClick={() => {
            if (open) setOpen(false);
          }}
        >
          <Routes>
            <Route
              path="/"
              element={
                token ? (
                  <MainContractorView setHideDrawer={setHideDrawer} setToken={setToken}/>
                ) : (
                  <Login setHideDrawer={setHideDrawer} setToken={setToken} />
                )
              }
            />

            <Route
              path="/saasUsers"
              element={
                token ? <SaasUsers /> : <Login setHideDrawer={setHideDrawer} />
              }
            />
            <Route
              path="/mainContractors"
              element={
                token ? (
                  <MainContractorView setHideDrawer={setHideDrawer} setToken={setToken}/>
                ) : (
                  <Login setHideDrawer={setHideDrawer} />
                )
              }
            />
            <Route
              path="/teamMembers"
              element={
                token ? (
                  <TeamMemberView setHideDrawer={setHideDrawer} />
                ) : (
                  <Login setHideDrawer={setHideDrawer} />
                )
              }
            />
             <Route
              path="/SubContractors/Factories"
              element={
                token ? (
                  <FactoriesList setHideDrawer={setHideDrawer} />
                ) : (
                  <Login setHideDrawer={setHideDrawer} />
                )
              }
            />
            <Route path="/SubContractors" element={<SubContractorView />} />
            <Route
              path="/requestSubscription"
              element={
                token ? (
                  <Requests
                    setOpenSubscModal={setOpenSubscModal}
                    setSubscEmailReq={setSubscEmailReq}
                  />
                ) : (
                  <Login setHideDrawer={setHideDrawer} />
                )
              }
            />
            <Route
              path="/manageSubscription"
              element={
                token ? (
                  <Manage
                    openSubscModal={openSubscModal}
                    subscEmailReq={subscEmailReq}
                    setOpenSubscModal={setOpenSubscModal}
                  />
                ) : (
                  <Login setHideDrawer={setHideDrawer} />
                )
              }
            />
            <Route path="/projects" element={<ProjectsView />} />
            <Route
              path="/reports"
              element={
                token ? (
                  <Reports setHideDrawer={setHideDrawer} />
                ) : (
                  <Login setHideDrawer={setHideDrawer} />
                )
              }
            />
            <Route
              path="/notifications"
              element={
                token ? (
                  <Notifications />
                ) : (
                  <Login setHideDrawer={setHideDrawer} />
                )
              }
            />
            <Route
              path="/tips"
              element={
                token ? (
                  <Tips setHideDrawer={setHideDrawer} />
                ) : (
                  <Login setHideDrawer={setHideDrawer} />
                )
              }
            />
            <Route
              path="/Manu/rebarTable"
              element={
                token ? (
                  <ShapesTable setHideDrawer={setHideDrawer} />
                ) : (
                  <Login setHideDrawer={setHideDrawer} />
                )
              }
            />
            <Route
              path="/support"
              element={
                token ? (
                  <AssignSupport setHideDrawer={setHideDrawer} />
                ) : (
                  <Login setHideDrawer={setHideDrawer} />
                )
              }
            />
            <Route
              path="/Manu/shapeBoard"
              element={
                token ? (
                  <AddShape setHideDrawer={setHideDrawer} />
                ) : (
                  <Login setHideDrawer={setHideDrawer} />
                )
              }
            />
          </Routes>
        </Grid>
      </Router>
    </Grid>
  );
}

import React, { useState } from "react";
import classes from "../../../Assets/Styles/SaasUser.module.css";
import { Grid, Box, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Table from "./Table";
import SubContractorDetails from "./SubContractorDetails";
const SubContractorView = () => {
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [searchText, setSearchText] = useState(null);
  const [viewDetails, setViewDetails] = useState(false);
  const [rowData, setRowData] = useState(false);

  return (
    <Grid  container  paddingTop={9} >
      <Grid item container direction={"row"} marginLeft={4}>
        <Grid item className={classes["page-title"]} md={3} xs={12}>
          SaaS Users
        </Grid>
    { !viewDetails?     <Grid
        item
          md={9}
          paddingLeft={{ md: 7, xs: 0 }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <TextField
            variant="standard"
            fullWidth
            onChange={inputHandler}
            placeholder="Search…"
            InputProps={{
              startAdornment: (
                <SearchIcon
                  fontSize="small"
                  htmlColor={"#707070"}
                  style={{ marginLeft: 15 }}
                />
              ),
              style: { fontFamily: "Muli" },
            }}
            sx={{
              fontFamily: "Muli",
              backgroundColor: "white",
              borderRadius: 1,

              border: "solid 1px #e9e7e7",
              width: {
                xs: 1,
                sm: 483,
              },
            }}
          />
        </Grid>:
        <></>}
      </Grid>
      <Grid item container paddingTop={5} md={12}>
        {viewDetails ? (
          <SubContractorDetails setViewDetails={setViewDetails} rowData={rowData}  />
        ) : (
          <Table setViewDetails={setViewDetails} setRowData={setRowData} searchText={searchText} />
        )}
      </Grid>
    </Grid>
  );
};

export default SubContractorView;

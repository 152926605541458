import { React, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../src/Assets/Styles/projects.module.css";
import * as ProjectServices from "../../Services/projects-services.proxy";
import { Title } from "@mui/icons-material";

const getProjectDetails = ProjectServices.getProjectDetails;

const ProjectsDetails = (props) => {
  const [projectDetails, setProjectDetails] = useState({});

  useEffect(() => {
    if (props.rowData) {
      getProjectDetails(props.rowData.row.Id).then((x) => {
        setProjectDetails(x);
      });
    }
  }, []);

  const renderFields = (FieldName, FieldData, showButton) => {
    return (
      <Grid container gap={2} sm={3.5}>
        <Grid md={12} item className={classes["FieldHeader"]}>
          {FieldName}
        </Grid>
        <Grid
          item
          container
          direction="row"
          className={classes["FieldData"]}
          alignItems="center"
        >
          <Grid item md={9}>
            {FieldData}
          </Grid>
          {showButton ? (
            <Grid item>
              <button
                className={classes["ViewButton"]}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#0674b9";
                  e.target.style.color = "#fff";
                  e.target.style.cursor = "pointer";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#fff";
                  e.target.style.color = "#0674b9";
                }}
                onClick={(e) => {
                  if (FieldName == "Client") {
                    props.setViewClientDetails(true);
                    props.setClientId(projectDetails.ClientId);
                  } else {
                    props.setViewProjectCreatorDetails(true);
                    props.setProjectCreatorId(projectDetails.CompanyId);
                    props.setCreatorAccountTypeId(
                      projectDetails.CreatorAccountTypeId
                    );
                  }
                }}
              >
                View
              </button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container paddingLeft={{ sm: 2, md: 0 }}>
      <Grid container item direction={"row"}>
        <Grid item className={classes["TableName"]} width={150} marginLeft={4}>
          Contractor Projects
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={9} container direction={"row-reverse"}>
          <Grid item paddingBottom={1}>
            <button
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#0674b9";
                e.target.style.color = "#fff";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#0674b9";
              }}
              onClick={() => {
                props.setViewDetails(false);
              }}
              className={classes["GoBack"]}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        borderTop={"solid lightGrey 0.5px "}
        paddingLeft={4}
        paddingTop={5}
        gap={3}
      >
        <Grid container gap={2}>
          <Grid md={12} xs={12} item className={classes["FieldHeader"]}>
            Project Name
          </Grid>
          <Grid md={3.5} xs={12} item className={classes["FieldData"]}>
            {projectDetails.Name}
          </Grid>
        </Grid>
        <Grid container gap={3}>
          {renderFields("Client", projectDetails?.ClientName, true)}
          {renderFields("Visibility ", projectDetails?.Visibility, false)}
          {renderFields(
            "Audience ",
            projectDetails?.IsPublished === false
              ? "Not Published"
              : "Published",
            false
          )}
          {renderFields("Type", projectDetails?.Type, false)}
          {renderFields("Country", projectDetails?.Country, false)}
          {renderFields("Address", projectDetails?.Address, false)}
          {renderFields("Project Creator", projectDetails?.Creator, true)}
          {renderFields("Creator Plan", projectDetails?.CreatorPlan, false)}

        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectsDetails;

import * as constFile from "../cmsConst";
import axios from "axios";
var fileDownload = require("js-file-download");

const REPORT_INPUT_DATA = constFile.REPORT_INPUT_DATA;
export const getReportInputData = (ReportId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + REPORT_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },

      params: { ReportId: ReportId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const REPORT_RUN = constFile.REPORT_RUN;
export const runReport = (
  ReportId,
  TableId,
  Name,
  Type,
  Description,
  Fields
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + REPORT_RUN}`,
      {
        ReportId: ReportId,
        TableId: TableId,
        Name: Name,
        Type: Type,
        Description: Description,
        Fields: Fields,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const REPORT_SAVE = constFile.REPORT_SAVE;
export const saveReport = (
  ReportId,
  TableId,
  Name,
  Type,
  Description,
  Fields
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + REPORT_SAVE}`,
      {
        ReportId: ReportId,
        TableId: TableId,
        Name: Name,
        Type: Type,
        Description: Description,
        Fields: Fields,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
//get reports list
const GET_REPORT_LIST = constFile.GET_REPORT_LIST;
export const getReportsList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_REPORT_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const GET_REPORT_DATA_BY_ID = constFile.GET_REPORT_DATA_BY_ID;
export const getReportDataById = (ReportId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_REPORT_DATA_BY_ID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },

      params: { ReportId: ReportId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const EXPORT_REPORT = constFile.EXPORT_REPORT;
export const exportReport = (ReportId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + EXPORT_REPORT}`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { ReportId: ReportId },
    })
    .then((response) => {
      fileDownload(response.data, 'report.xlsx');
      return response;
    })
    .catch((err) => { });
};
const UPDATE_REPORT = constFile.UPDATE_REPORT;
export const updateReport = (
  ReportId,
  TableId,
  Name,
  Type,
  Description,
  Fields
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + UPDATE_REPORT}`,
      {
        ReportId: ReportId,
        TableId: TableId,
        Name: Name,
        Type: Type,
        Description: Description,
        Fields: Fields,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const REPORT_DELETE = constFile.REPORT_DELETE;
export const deleteReport = (reportId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + REPORT_DELETE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { reportId: reportId },
    })
    .then((response) => {

      return response;
    })
    .catch((err) => { });
};

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";

import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/reports.module.css";
import { TextField, IconButton } from "@mui/material";

const style = {
  position: "absolute",
  top: "53%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  width: "40%",
  height: "70%",
  display: "block",
  border: "#fff",
  borderRadius: "5px",
};

const ReportModal = (props) => {
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
  };
  useEffect(() => {
    if (!props.name || !props.type || titleError || typeError) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            paddingTop={1}
            paddingLeft={2}
            justifyContent={"center"}
            gap={1}
            alignItems={"center"}
          >
            <Grid item className={classes["ModalTitle"]}>
              Save Report
            </Grid>
            <Grid item xs={12} className={classes["Seperator"]}></Grid>
            <Grid container paddingTop={5}>
              <Grid item xs={12} className={classes["FieldLabel"]}>
                *Title
              </Grid>
              <Grid item xs={11.7}>
                <TextField
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label={props.name ? "" : "Title"}
                  size="small"
                  inputProps={{
                    maxLength: 150,
                  }}
                  value={props.name}
                  error={titleError}
                  helperText={titleError ? "Enter a Title" : <></>}
                  onBlur={(e) => {
                    if (!props.name) setTitleError(true);
                  }}
                  onChange={(e) => {
                    props.setName(e.target.value);
                    if (e.target.value === "") {
                      setTitleError(true);
                    } else {
                      setTitleError(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes["FieldLabel"]}>
                *Type
              </Grid>
              <Grid item xs={11.7}>
                <TextField
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label={props.type ? "" : "Type"}
                  size="small"
                  inputProps={{
                    maxLength: 150,
                  }}
                  value={props.type}
                  error={typeError}
                  helperText={typeError ? "Enter a Type" : <></>}
                  onBlur={(e) => {
                    if (!props.type) setTypeError(true);
                  }}
                  onChange={(e) => {
                    props.setType(e.target.value);
                    if (e.target.value === "") {
                      setTypeError(true);
                    } else {
                      setTypeError(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes["FieldLabel"]}>
                Description
              </Grid>
              <Grid item xs={11.7}>
                <TextField
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label={props.description ? "" : "Description"}
                  size="large"
                  multiline
                  inputProps={{
                    maxLength: 150,
                  }}
                  value={props.description}
                  onChange={(e) => {
                    props.setDescription(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes["FieldLabel"]}>
                Created Date
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  fullWidth
                  disabled
                  variant="outlined"
                  label={props.creationTime ? "" : "Created Date"}
                  size="small"
                  value={props.creationTime}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row-reverse"}
              md={12}
              paddingTop={2}
              paddingRight={3}
            >
              <button
                onClick={() => {
                  setLoadingButton(true);
                  if (props.reportIdTable) {
                    props.updateTheReport();
                  } else {
                    props.saveTheReport();
                  }
                  setTimeout(() => {
                    handleClose();
                    setLoadingButton(false);
                  }, 1500);
                }}
                disabled={isDisabled}
                className={classes["SaveButon"]}
              >
                {loadingButton ? "Saving..." : "Save"}
              </button>
              <button
                onClick={() => {
                  handleClose();
                }}
                className={classes["CancelButton"]}
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ReportModal;

import "./App.css";
import WebFont from "webfontloader";
import React, { useEffect, useState } from "react";
import SideBar from "./Routes/SideBar";
import Navigate from "./Routes/Navigate";
import { Grid } from "@mui/material";
import { BrowserRouter } from 'react-router-dom';
function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Muli"],
      },
    });
  }, []);
  return (
 
    <Grid container>
      <Navigate />
     
    </Grid>
  
  );
}

export default App;

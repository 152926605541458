import React, { useEffect, useState } from "react";
import LoginBG from "../Assets/Images/Login/logInBg.png";
import { CircularProgress, Grid, TextField } from "@mui/material";
import classes from "../Assets/Styles/Login.module.css";
import Logo from "../Assets/Images/Drawer/logo1.png";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as loginServices from "../Services/login-services.proxy";
import { useNavigate } from "react-router-dom";
const logIn = loginServices.logIn;
const Login = (props) => {
  useEffect(() => {
    props.setHideDrawer(true);
  });
  const navigate = useNavigate();
  const [inputsError, setInputsError] = useState(false);
  const [inputsInvalid, setInputsInvalid] = useState(false);
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === "" || password === "") setInputsError(true);
    else {
      setLoading(true);
      logIn(email, password)
        .then((res) => {
          setTimeout(() => {
            localStorage.setItem("accessToken", res.data);
            props.setToken(res.data);
            navigate("/mainContractors");
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          setInputsError(true);
          setInputsInvalid(true);
        });
    }
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${LoginBG})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid
          item
          container
          direction={"column"}
          width="380px"
          height="450px"
          alignItems={"center"}
          marginTop={20}
          className={classes["FormBg"]}
        >
          <Grid item marginTop={5}>
            <img src={Logo} alt="logo" height={50} />
          </Grid>
          <Grid item marginTop={1}>
            <span className={classes["LoginText"]}>CMS Login </span>
          </Grid>
          <Grid item marginTop={2}>
            <span className="login-errors">
              {inputsError
                ? inputsInvalid
                  ? "Incorrect email address and / or password."
                  : "Empty fields."
                : ""}
            </span>
          </Grid>
          <Grid
            item
            container
            direction={"column"}
            marginTop={1}
            justifyContent="center"
            alignItems={"center"}
          >
            <Grid item marginRight={38}>
              <span className={classes["FiedLabels"]}>Email</span>
            </Grid>
            <Grid item>
              <TextField
                value={email}
                error={inputsError}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      borderColor: "#f2f2f2",
                    },
                  },
                }}
                id="outlined-name1"
                size="small"
                className={classes["FieldsInput"]}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid
              item
              container
              direction={"column"}
              marginTop={1}
              justifyContent="center"
              alignItems={"center"}
            >
              <Grid item marginRight={35}>
                <span className={classes["FiedLabels"]}>Password</span>
              </Grid>
              <Grid item>
                <TextField
                  type={showPassword ? "text" : "password"}
                  error={inputsError}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        borderColor: "#f2f2f2",
                      },
                    },
                  }}
                  id="outlined-name"
                  size="small"
                  className={classes["FieldsInput"]}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                        ) : (
                          <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                        )}
                      </IconButton>
                    ),
                  }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
              </Grid>
              <Grid item marginTop={2}>
                <button
                  disabled={loading}
                  className={classes["loginButton"]}
                  onClick={handleSubmit}


                >
                  {loading ? <>
                    <CircularProgress size={25} />
                  </> :
                    "Log In"}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/reports.module.css";
import yellowReport from "../../Assets/Images/Reports/yellowReport.png";
import blueReport from "../../Assets/Images/Reports/blueReport.png";
import greenReport from "../../Assets/Images/Reports/GreenReport.png";

const ReportsView = (props) => {
  const ReportsTypes = (title, fileColor, id) => {
    return (
      <Grid direction={"column"} className={classes["report-Type"]}>
        <Grid item >
          {title}
        </Grid>
        <Grid
          onClick={() => {
            props.setReportType(title);
            props.setViewAddReport(true);
            props.setReportId(id);
          }}
          item
          paddingTop={
            title === "Projects" ||
            title === "Clients" ||
            title === "Consultants" ||
            title === "Factories" ||
            title === "Contracts"
              ? 5.1
              : 2.5
          }
        >
          <img src={fileColor} alt={"report"} />
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid
      item
      width="100%"
      height={"100%"}
      bgcolor={"rgb(252 249 249)"}
      paddingBottom={"30%"}
    >
      <Grid container paddingTop={5} paddingLeft={5} direction={"row"} rowGap={5} columnGap={17}>
        {ReportsTypes("Main Contractors", yellowReport, 1)}
        {ReportsTypes("Sub Contractors", blueReport, 2)}
        {ReportsTypes("Team Members", greenReport, 3)}
        {ReportsTypes("Projects", yellowReport, 4)}
        {ReportsTypes("Clients", blueReport, 5)}
        {ReportsTypes("Consultants", greenReport, 6)}
        {ReportsTypes("Contracts", yellowReport, 7)}
        {ReportsTypes("Factories", blueReport, 8)}
        
      </Grid>
      {props.AvailableData ? (
        <>
          <Grid className={classes["EmptyTable"]} xs={12} marginTop={10}>
            Nothing here yet
          </Grid>
          <Grid className={classes["EmptyTableHint"]} xs={12} marginTop={1}>
            When you create a report, you'll find it here.
          </Grid>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default ReportsView;

import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/reports.module.css";
import removeLogo from "../../Assets/Images/Reports/remove.png";
import addLogo from "../../Assets/Images/Reports/plus.png";
import iLogo from "../../Assets/Images/Reports/i.png";
import xLogo from "../../Assets/Images/Reports/x.png";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import ReportModal from "./ReportModal";
import GeneratedData from "./GeneratedData";
import * as reportServices from "../../Services/reports-services.proxy";
const getReportInputData = reportServices.getReportInputData;
const runReport = reportServices.runReport;
const saveReport = reportServices.saveReport;
const getReportDataById = reportServices.getReportDataById;
const exportReport = reportServices.exportReport;
const updateReport = reportServices.updateReport;
const CreateReport = (props) => {
  const ref = useRef();
  const [reportsInputData, setReportsInputData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedColumnsId, setSelectedColumnsId] = useState([]);
  const [returnedData, setReturnedData] = useState([]);

  const [reportId, setReportId] = useState(null);
  const [tableId, setTableId] = useState(props.reportId);
  const [tableName, setTableName] = useState(
    props.reportType ? props.reportType : ""
  );

  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [creationTime, setCreationTime] = useState(null);

  const [description, setDescription] = useState(null);
  const [saveIsdisabled, setsaveIsdisabled] = useState(true);
  const [runIsDisabled, setRunIsDisabled] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [succesSaved, setsuccesSaved] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (props.reportIdTable) {
      getReportInputData(props.reportIdTable).then((x) => {
        setReportsInputData(
          x.AvailableReports.find((table) => table.TableId === x.Report.TableId)
        );
        if (x.Report.TableId === 1) {
          setTableName("Main Contractor");
        } else if (x.Report.TableId === 2) {
          setTableName("Sub Contractor");
        } else if (x.Report.TableId === 3) {
          setTableName("Team Member");
        } else if (x.Report.TableId === 4) {
          setTableName("Project");
        } else if (x.Report.TableId === 5) {
          setTableName("Client");
        } else if (x.Report.TableId === 6) {
          setTableName("Consultants");
        }
        setSelectedColumns(x.Report.Fields);
        let a = x.Report.Fields.map((x) => {
          return {
            Id: x.Id,
          };
        });
        setSelectedColumnsId(a);
        setName(x.Report.Name);
        setDescription(x.Report.Description);
        setTableId(x.Report.TableId);
        setCreationTime(x.Report.CreationTime?.slice(0, 10));
        setType(x.Report.Type);
        setEditMode(true);
      });
    } else {
      getReportInputData(null).then((x) => {
        setReportsInputData(
          x.AvailableReports.find((table) => table.TableId === props.reportId)
        );
      });
    }
  }, []);
  useEffect(() => {
    if (props.reportIdTable) {
      getReportDataById(props.reportIdTable).then((x) => {
        setReturnedData(x);
      });
    }
  }, []);
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const runTheReport = () => {
    runReport(
      reportId,
      tableId,
      name,
      type,
      description,
      selectedColumnsId
    ).then((x) => {
      setReturnedData(x);
    });
  };
  const saveTheReport = () => {
    var today = new Date();

    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    setCreationTime(date);
    saveReport(
      reportId,
      tableId,
      name,
      type,
      description,
      selectedColumnsId
    ).then((x) => {
      setsuccesSaved(true);
      setEditMode(true);
      setTimeout(() => {
        setsuccesSaved(false);
        props.setAvailableData(true);
        props.setViewAddReport(false);
        props.setShowTableOnly(false);
      }, 3000);
    });
  };
  const exportTheReport = () => {
    exportReport(props.reportIdTable);
  };
  const addColumn = (field) => {
    let temp = field;
    let arr = selectedColumns;
    arr.push(temp);
    setSelectedColumns(arr);
    selectedColumns.sort((a, b) => a.Id - b.Id);
    temp = null;
    arr = [];
    let tempId = { Id: field.Id };
    let arrId = selectedColumnsId;
    arrId.push(tempId);
    setSelectedColumnsId(arrId);
    tempId = null;
    arrId = [];
    forceUpdate();
  };
  const removeColumn = (field) => {
    var filtered = selectedColumns.filter(function (value, index, arr) {
      return value.Id !== field.Id;
    });
    setSelectedColumns(filtered);
    selectedColumns.sort((a, b) => a.Id - b.Id);
    var filteredId = selectedColumnsId.filter(function (value, index, arr) {
      return value.Id !== field.Id;
    });
    setSelectedColumnsId(filteredId);
    forceUpdate();
  };
  const updateTheReport = () => {
    updateReport(
      props.reportIdTable,
      tableId,
      name,
      type,
      description,
      selectedColumnsId
    ).then((x) => {
      setsuccesSaved(true);
      setEditMode(true);
      props.setReportIdTable(null);

      setTimeout(() => {
        setsuccesSaved(false);
        props.setAvailableData(true);
        props.setViewAddReport(false);
        props.setShowTableOnly(false);
      }, 3000);
    });
  };
  useEffect(() => {
    if (selectedColumns.length === 0) {
      setRunIsDisabled(true);
    } else {
      setRunIsDisabled(false);
    }
  });
  return (
    <Grid container>
      <Grid container direction={"row"}>
        {props.showTableOnly ? (
          <Grid item marginLeft={3} md={1.5} className={classes["ReportName"]}>
            {tableName + " " + "Report"}
          </Grid>
        ) : (
          <></>
        )}
        <Grid
          container
          item
          md={props.showTableOnly ? 10 : 12}
          direction={"row-reverse"}
          paddingRight={5}
          paddingBottom={2}
          gap={2}
        >
          {props.reportIdTable && props.showTableOnly ? (
            <>
              <button
                style={{ width: "79px" }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  exportTheReport();
                }}
                className={classes["RunButton"]}
              >
                Export
              </button>
              <ReactToPrint content={() => ref.current}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <button
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={handlePrint}
                      className={classes["RunButton"]}
                    >
                      Print
                    </button>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            </>
          ) : props.reportIdTable ? (
            <button
              style={{ width: "fit-content" }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                setOpenModal(true);
              }}
              className={classes["RunButton"]}
            >
              Edit Report Details
            </button>
          ) : (
            <></>
          )}
          <button
            disabled={saveIsdisabled || succesSaved}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              if (props.reportIdTable) updateTheReport();
              else {
                setOpenModal(true);
              }
            }}
            className={classes["RunButton"]}
          >
            Save
          </button>

          <button
            disabled={runIsDisabled || succesSaved}
            onClick={() => {
              if (props.showTableOnly) {
                props.setShowTableOnly(false);
              } else {
                runTheReport();
                setsaveIsdisabled(false);
                props.setShowTableOnly(true);
              }
            }}
            className={classes["RunButton"]}
          >
            {props.showTableOnly ? "Edit" : "Run"}
          </button>
        </Grid>
      </Grid>
      <Grid
        container
        width="100%"
        bgcolor={"rgb(252 249 249)"}
        paddingBottom={"30%"}
        direction={"row"}
      >
        {succesSaved ? (
          <Grid
            item
            md={4}
            alignSelf={"center"}
            justifyContent={"center"}
            className={classes["savedSuccess"]}
            container
            direction={"row"}
            marginTop={3}
            marginLeft={"35%"}
          >
            <img
              src={iLogo}
              alt={"i"}
              style={{ paddingRight: 10, width: 25, height: 20 }}
            />
            Report "{name}" was saved
            <img src={xLogo} alt={"x"} style={{ paddingLeft: 10 }} />
          </Grid>
        ) : (
          <>
            {props.showTableOnly ? (
              <></>
            ) : (
              <Grid
                item
                paddingTop={1.2}
                paddingLeft={2}
                direction={"column"}
                gap={1}
                md={2}
              >
                <Grid item className={classes["report-Type"]}>
                  Table
                </Grid>
                <Grid
                  item
                  xs={4}
                  className={classes["SelectedFields"]}
                  container
                  alignItems={"center"}
                  direction={"row"}
                >
                  <Grid item xs={10.5} className={classes["FieldName"]}>
                    {tableName}
                  </Grid>
                  <Grid item xs={1.5} alignItems={"flex-end"}>
                    <img src={removeLogo} alt={"remove"} />
                  </Grid>
                </Grid>
                <Grid container paddingTop={7} gap={1} direction={"column"}>
                  <Grid item xs={12} className={classes["report-Type"]}>
                    Columns
                  </Grid>
                  {reportsInputData?.Fields?.map((field) => (
                    <Grid
                      key={field.Id}
                      item
                      className={
                        selectedColumns.find((x) => x.Id === field.Id)
                          ? classes["SelectedFields"]
                          : classes["NotSelectedFields"]
                      }
                      container
                      alignItems={"center"}
                      direction={"row"}
                    >
                      <Grid item xs={10.5} className={classes["FieldName"]}>
                        {field.Name}
                      </Grid>
                      {selectedColumns.find((x) => x.Id === field.Id) ? (
                        <Grid
                          item
                          xs={1.5}
                          alignItems={"flex-end"}
                          onClick={() => {
                            removeColumn(field);
                          }}
                        >
                          <img src={removeLogo} alt={"remove"} />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={1.5}
                          alignItems={"flex-end"}
                          onClick={() => {
                            addColumn(field);
                          }}
                        >
                          <img src={addLogo} alt={"remove"} />
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}

            <GeneratedData
              tableId={tableId}
              selectedColumns={selectedColumns}
              returnedData={returnedData}
              showTableOnly={props.showTableOnly}
              name={name}
              type={type}
              description={description}
              creationTime={creationTime}
              editMode={editMode}
              Myref={ref}
            />
          </>
        )}

        <ReportModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          name={name}
          setName={setName}
          type={type}
          setType={setType}
          description={description}
          setDescription={setDescription}
          creationTime={creationTime}
          saveTheReport={saveTheReport}
          reportIdTable={props.reportIdTable}
          updateTheReport={updateTheReport}
        />
      </Grid>
    </Grid>
  );
};

export default CreateReport;

import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/SaasUser.module.css";
import * as SaaSServicesService from "../../../Services/saas-users-services.proxy";
import MainLogo from "../../../Assets/Images/IconsProfile/sublogo.png";
import { useNavigate } from "react-router-dom";

const getSubContractorDetails = SaaSServicesService.getSubContractorDetails;
const SubContractorDetails = (props) => {
  const navigate = useNavigate();
  const [subContractorDetails, setSubContractorDetails] = useState({});
  useEffect(() => {
    if (props.rowData) {
      getSubContractorDetails(props.rowData.row.CompanyId).then((x) => {
        setSubContractorDetails(x);
      });
    }
  }, [props.rowData]);

  const renderFields = (FieldName, FieldData) => {
    return (
      <Grid container gap={2} sm={3.5}>
        <Grid md={12} item className={classes["FieldHeader"]}>
          {FieldName}
        </Grid>
        <Grid md={12} item className={classes["FieldData"]}>
          {FieldData}
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container paddinBottom={10}>
      <Grid container item direction={"row"}>
        <Grid
          item
          className={classes["TableNameSub"]}
          width={130}
          marginLeft={4}
        >
          Sub Contractor
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={9} container direction={"row-reverse"} gap={3}>
          <Grid item paddingBottom={1}>
            <button
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#0674b9";
                e.target.style.color = "#fff";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#0674b9";
              }}
              onClick={() => {
                props.setViewDetails(false);
              }}
              className={classes["GoBack"]}
            >
              Go Back
            </button>
          </Grid>
          <Grid item paddingBottom={1}>
            <button
              style={{ width: "fit-content" }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#0674b9";
                e.target.style.color = "#fff";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#0674b9";
              }}
              onClick={() => {
                navigate(
                  `/SubContractors/Factories?id=${props.rowData.row.CompanyId}&name=${subContractorDetails.CompanyName}`
                );
              }}
              className={classes["GoBack"]}
            >
              Factories Detail
            </button>
          </Grid>
          <Grid item paddingBottom={1}>
            <button
              style={{ width: "fit-content" }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#0674b9";
                e.target.style.color = "#fff";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#0674b9";
              }}
              onClick={() => {
                navigate(`/teamMembers?id=${props.rowData.row.CompanyId}`);
              }}
              className={classes["GoBack"]}
            >
              View Team Members
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        borderTop={"solid lightGrey 0.5px "}
        paddingLeft={4}
        paddingTop={5}
        gap={3}
      >
        <Grid container gap={2}>
          <Grid md={12} xs={12} item className={classes["FieldHeader"]}>
            Company Name
          </Grid>
          <Grid md={3.5} xs={12} item className={classes["FieldData"]}>
            {subContractorDetails.CompanyName}
          </Grid>
        </Grid>
        <Grid container gap={3} paddingBottom={10}>
          <Grid container gap={2} md={3.5}>
            <Grid md={12} item className={classes["FieldHeader"]}>
              Company Profile
            </Grid>
            <Grid md={12} item className={classes["FieldData"]}>
              <img
                src={
                  subContractorDetails.UserDetails?.ProfilePicture
                    ? subContractorDetails.UserDetails.ProfilePicture.URL
                    : MainLogo
                }
                style={{ width: "27px" }}
                alt={"ptofile"}
              ></img>
            </Grid>
          </Grid>
          {renderFields(
            "Registration  Email",
            subContractorDetails.CompanyEmail
          )}
          {renderFields("Subscription Plan", subContractorDetails.PlanName)}
          {renderFields("Subscription Status", subContractorDetails.PlanStatus)}
          {renderFields("Industry Type", subContractorDetails.IndustryType)}
          {renderFields("Discipline", subContractorDetails.DisciplineCovered)}
          {renderFields("Trade", subContractorDetails.Trade)}
          {renderFields("Country", subContractorDetails.Country)}
          {renderFields("Company Address", subContractorDetails.Address)}
          {renderFields(
            "Registration Number",
            subContractorDetails.RegistrationNumber
          )}
          {renderFields("Employee Range", subContractorDetails.EmployeesRange)}
          {renderFields(
            "Phone Number",
            subContractorDetails.CompanyPhoneNumber
          )}
          {renderFields("Contact Name", subContractorDetails.UserDetails?.Name)}
          {renderFields("Position", subContractorDetails.UserDetails?.Position)}
          {renderFields(
            "Contact Email",
            subContractorDetails.UserDetails?.PersonalEmail
          )}
          {renderFields(
            "Contact Phone Number",
            subContractorDetails.UserDetails?.PhoneNumber
          )}
          {renderFields(
            "Joining Date",
            subContractorDetails.CreationTime?.slice(0, 10)
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubContractorDetails;

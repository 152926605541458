import { React, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../src/Assets/Styles/projects.module.css";
import * as ProjectServices from "../../Services/projects-services.proxy";
import { border } from "@mui/system";
import MainIcon from "../../Assets/Images/IconsProfile/mainlogo.png";
import SubIcon from "../../Assets/Images/IconsProfile/sublogo.png";

const getProjectCreatorDetailsMC = ProjectServices.getMCDetails;
const getProjectCreatorDetailsSC = ProjectServices.getSCDetails;

const ProjectCreatorDetails = (props) => {
  const [projectCreatorDetails, setProjectCreatorDetails] = useState({});
  useEffect(() => {
    if (props.projectCreatorId) {
      if (props.creatorAccountTypeId == 1) {
        getProjectCreatorDetailsMC(props.projectCreatorId).then((x) => {
          setProjectCreatorDetails(x);
        });
      } else {
        getProjectCreatorDetailsSC(props.projectCreatorId).then((x) => {
          setProjectCreatorDetails(x);
        });
      }
    }
  }, []);

  const renderFields = (FieldName, FieldData) => {
    return (
      <Grid container gap={2} sm={3.5}>
        <Grid md={12} item className={classes["FieldHeader"]}>
          {FieldName}
        </Grid>
        <Grid item container direction="row" className={classes["FieldData"]}>
          <Grid item md={9}>
            {FieldData}
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      
        <Grid container paddingBottom={10} paddingLeft={{ sm: 2, md: 0 }}>
          <Grid container item direction={"row"}>
            <Grid
              item
              className={classes["TableNameSCMC"]}
              marginLeft={4}
              sx={{
                borderBottom:
                  props.creatorAccountTypeId === 1
                    ? "solid 3px #e1e319"
                    : "solid 3px #0674b9",
              }}
            >
              {props.creatorAccountTypeId === 1
                ? "Main Contractor"
                : "Sub Contractor"}
            </Grid>
            <Grid item md={0.5}></Grid>
            <Grid item md={9.8} container direction={"row-reverse"}>
              <Grid item paddingBottom={1}>
                <button
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#0674b9";
                    e.target.style.color = "#fff";
                    e.target.style.cursor = "pointer";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#fff";
                    e.target.style.color = "#0674b9";
                  }}
                  onClick={() => {
                    props.setViewProjectCreatorDetails(false);
                  }}
                  className={classes["GoBack"]}
                >
                  Go Back
                </button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            borderTop={"solid lightGrey 0.5px "}
            paddingLeft={4}
            paddingTop={5}
            gap={3}
          >
            <Grid container gap={2}>
              <Grid md={12} xs={12} item className={classes["FieldHeader"]}>
                Company Name
              </Grid>
              <Grid md={3.5} xs={12} item className={classes["FieldData"]}>
                {projectCreatorDetails.CompanyName}
              </Grid>
            </Grid>
            <Grid container gap={3}>
              {renderFields(
                "Company Profile",
                <img
                  src={
                    projectCreatorDetails.UserDetails?.ProfilePicture
                      ? projectCreatorDetails.UserDetails?.ProfilePicture.URL
                      : props.projectCreatorId === 1
                      ? MainIcon
                      : SubIcon
                  }
                  style={{ width: 27 }}
                  alt={"ptofile"}
                ></img>
              )}
              {renderFields(
                "Registration Email",
                projectCreatorDetails?.CompanyEmail
              )}
              {renderFields("Subscription Plan ", projectCreatorDetails?.PlanName)}
              {renderFields(
                "Subscription Status ",
                projectCreatorDetails?.PlanStatus
              )}

              {props.creatorAccountTypeId === 1
                ? ""
                : renderFields(
                    "Industry Type",
                    projectCreatorDetails?.IndustryType
                  )}
              {props.creatorAccountTypeId == 2
                ? renderFields(
                    "Discipline",
                    projectCreatorDetails?.DisciplineCovered
                  )
                : ""}
              {props.creatorAccountTypeId === 2
                ? renderFields("Trade", projectCreatorDetails?.Trade)
                : ""}
              {props.creatorAccountTypeId === 1
                ? renderFields("Speciality", projectCreatorDetails?.Speciality)
                : ""}
              {renderFields("Country", projectCreatorDetails?.Country)}
              {renderFields("Company Address ", projectCreatorDetails?.Address)}

              {renderFields(
                "Registration Number",
                projectCreatorDetails?.RegistrationNumber
              )}
              {renderFields(
                "Employee Range ",
                projectCreatorDetails?.EmployeesRange
              )}
              {renderFields(
                "Phone Number",
                projectCreatorDetails?.CompanyPhoneNumber
              )}
         
              {renderFields(
                "Contact Name ",
                projectCreatorDetails?.UserDetails?.Name
              )}
              {renderFields(
                "Position",
                projectCreatorDetails?.UserDetails?.Position
              )}
              {renderFields(
                "Contact Email",
                projectCreatorDetails?.UserDetails?.PersonalEmail
              )}
              {renderFields(
                "Contact Phone Number",
                projectCreatorDetails?.UserDetails?.PhoneNumber
              )}
              {renderFields(
                "Joining Date",
                projectCreatorDetails?.CreationTime?.slice(0,10)
              )}
            </Grid>
          </Grid>
        </Grid>
     
    </>
  );
};

export default ProjectCreatorDetails;

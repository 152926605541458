import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import classes from "../../../Assets/Styles/SaasUser.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import Loading from "../../../Components/Loading";
import { styled } from "@mui/system";
import { Tooltip } from "@mui/material";
import * as SubscriptionService from "../../../Services/subscription-services.proxy";

const getSubscriptionsRequests = SubscriptionService.getSubscriptionsRequests;
const getSubscriptionsRequestFilterInputData =
  SubscriptionService.getSubscriptionsRequestFilterInputData;
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
        color:#fff;
        background-color:  #e1e319 ;
        font-size: 13px;
        width:fit-content;
     
    `);
const style = {
  plan: {
    fontfamily: "Muli",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 1.31,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#101650",
    border: 0,
    textTransform: "none",
  },
  activePlan: {
    fontfamily: "Muli",
    fontSize: 16,
    fontWeight: "bolder",
    lineHeight: 1.31,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#101650",
    textTransform: "none",
  },
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 12px;
    box-sizing: border-box;
    min-height: 32px;
    width: 9rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    margin: 0.5em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 13px;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    width: 150px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

const Table = (props) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(null);
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "ACTION",
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BlueTip
            title={
              params.row.IsManageForbidden ? params.row.ErrorMessage : "Manage"
            }
            placement="right"
          >
            <span>
              <Button
                className={classes["manageButton"]}
                disabled={params.row.IsManageForbidden}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#5fba47";
                  e.target.style.color = "#fff";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#fff";
                  e.target.style.color = "#5fba47";
                }}
                onClick={() => {
                  props.setRowData(params);
                  props.setSubscEmailReq(params.row.SubscriptionRequestId);
                  props.setOpenSubscModal(true);
                  navigate("/manageSubscription");
                }}
                style={{
                  borderRadius: 5,
                  border: "solid 1px #5fba47",
                  fontFamily: "Muli",
                  fontSize: 13,
                  color: params.row.IsManageForbidden ? "#fff" : "#5fba47",
                  textTransform: "none",
                  backgroundColor: params.row.IsManageForbidden
                    ? "#bcbcbc"
                    : "fff",
                }}
              >
                Manage
              </Button>
            </span>
          </BlueTip>
        );
      },
    },
    {
      field: "Status",
      headerName: "Subscription Status",
      width: 150,
      sortable: false,
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 150,
    },

    {
      field: "CompanyEmail",
      headerName: "Email",
      width: 230,
    },

    {
      field: "Country",
      headerName: "Country",
      width: 150,
    },
    {
      field: "EmployeeRange",
      headerName: "Employees",
      width: 150,
      sortable: false,
    },
    {
      field: "CompanyNumber",
      headerName: "Phone Number",
      width: 150,
      sortable: false,
    },
    {
      field: "PersonalName",
      headerName: "Contact Name ",
      width: 150,
      sortable: false,
    },
    {
      field: "Position",
      headerName: "Position",
      width: 150,
      sortable: false,
    },

    {
      field: "PersonalEmail",
      headerName: "Contact Email",
      width: 200,
      sortable: false,
    },
    {
      field: "PersonalNumber",
      headerName: "Contact Phone Number",
      width: 200,
      sortable: false,
    },
    {
      field: "NumOfFactories",
      headerName: "Number of Factories",
      width: 150,
      sortable: false,
      hide: active === 9 || active === 10 || active === 11 ? false : true,
    },
    {
      field: "FactoryVolume",
      headerName: "Factory Volume",
      width: 150,
      sortable: false,
      hide: active === 9 || active === 10 || active === 11 ? false : true,
    },
    {
      field: "Date",
      headerName: "Request Date",
      width: 150,
      sortable: false,
      renderCell: (params) => params.row.CreationTime.slice(0, 10),
    },
  ];

  const [products, setProducts] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [planType, setPlanType] = useState(null);
  const [planList, setPlanList] = useState([]);
  const [status, setStatus] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setdataLength] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSubscriptionsRequestFilterInputData().then((x) => {
      setStatuses(x.data.Statuses);
      setProducts(x.data.Products);
    });
  }, []);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: props.searchText,
    StatusId: status === 0 ? null : status,
    ProductId: planType,
  };

  useEffect(() => {
    setLoading(true);
    getSubscriptionsRequests(listData).then((x) => {
      setPlanList(x.Requests);
      setdataLength(x.Total);
      setLoading(false);
    });
  }, [pageSize, pageNumber, status, props.searchText, planType]);

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 20,
      bottom: params.isLastVisible ? 0 : 20,
    };
  }, []);

  return (
    <Grid container>
      <Grid container item direction={"row"}>
        {products.map((product, index) => (
          <Grid
            key={index}
            item
            xs={product.Id === null ? 1.5 : 1.7}
            sx={{
              borderBottom: active === product.Id ? "solid 2px #e1e319" : 0,
            }}
            marginLeft={3}
            paddingLeft="1%"
            onClick={() => {
              setActive(product.Id);
              setPlanType(product.Id);
            }}
          >
            <Button sx={active === product.Id ? style.activePlan : style.plan}>
              {product.Name}
            </Button>
            {active === product.Id ? <Grid item sx={{
              borderRadius: '50px', backgroundColor: 'lightgrey', color: '101650',
              width: '20px', paddingLeft: '3.5%', marginLeft: '2%', fontSize: '13px'
            }}>{loading ? "" : dataLength}</Grid> : ""}
          </Grid>
        ))}
        <Grid item xs={3} md={4} container direction={"row-reverse"}>
          <Grid item>
            <CustomSelect
              value={status}
              onChange={(option) => {
                setStatus(option);
              }}
            >
              <StyledOption value={0} disabled>
                Subscription Status
              </StyledOption>

              {statuses.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {status ? (
            <Grid
              className={classes["clearFilter"]}
              onClick={() => {
                setStatus(0);
              }}
            >
              Clear Filters
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {loading ? (
        <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={12}
          >
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
          {planList.length > 0 ? (
            <DataGrid
              style={{ paddingTop: 30, paddingLeft: 10 }}
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              pagination
              paginationMode="server"
              rows={planList}
              columns={columns}
              headerHeight={40}
              rowHeight={100}
              getRowSpacing={getRowSpacing}
              showColumnRightBorder
              sx={{
                [`& .${gridClasses.row}`]: {
                  border: 0,

                  boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
                  color: "#0674b9",
                  overflow: "auto",

                  bgcolor: (theme) =>
                    theme.palette.mode === "light" ? "#fff" : grey[500],
                },

                [`& .${gridClasses.columnHeader}`]: {
                  bgcolor: "transparent",
                  color: "rgb(17 24 84)",
                  border: 0,
                },
                [`& .${gridClasses.columnHeaders}`]: {
                  border: 0,
                },
                [`& .${gridClasses.columnHeaderTitle}`]: {
                  fontFamily: "Muli",
                  fontWeight: "bold",
                },

                [`& .${gridClasses.footerContainer}`]: {
                  bgcolor: "transparent",
                  minHeight: "30px",
                  border: 0,
                },
                fontFamily: "Muli",
                fontSize: "13px",
                border: 0,
              }}
              disableColumnFilter
              getRowId={(row) => row.SubscriptionRequestId}
              // rowCount={dataLength}

              onPageChange={(newPage) => {
                if (pageSize > dataLength) {
                  setPageNumber(1);
                } else setPageNumber(newPage + 1);
              }}
              onPageSizeChange={(newPage) => {
                setPageSize(newPage);
              }}
              pageSize={pageSize}
              components={{
                NoRowsOverlay: () => (
                  <Stack className={classes["EmptyMessage"]}>
                    No Requests To Display
                  </Stack>
                ),
              }}
            />
          ) : (
            <Stack className={classes["EmptyMessage"]}>
              No Subscriptions Requests To Display
            </Stack>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Table;

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../src/Assets/Styles/projects.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { DataGrid, gridClasses } from "@mui/x-data-grid";

import { Stack } from "@mui/material";

import { styled } from "@mui/system";

import * as ProjectServices from "../../Services/projects-services.proxy";
import Loading from "../../Components/Loading";
const getProjectsList = ProjectServices.getProjectsList;
const getProjectFilterInputs = ProjectServices.getProjectFilterInputs;
const toggleProject = ProjectServices.toggleProject;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 12px;
    box-sizing: border-box;
    min-height: 32px;
    width: 9rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    margin: 0.5em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${
        theme.palette.mode === "dark" ? blue[600] : blue[100]
      };
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    max-height:250px !important;
    padding: 5px;
    margin: 10px 0;
    min-width: 11rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;
    
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${
        theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${
        theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

const Table = (props) => {
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setdataLength] = useState(0);

  const [country, setCountry] = useState(0);
  const [countries, setCountries] = useState([]);

  const [visibility, setVisibilty] = useState(0);
  const [visibilities, setVisibilities] = useState([]);

  const [audience, setAudience] = useState(0);
  const [audienceList, setAudienceList] = useState([]);
  const [modify, setModify] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: props.searchText,
    VisibilityId: visibility === 0 ? null : visibility,
    IsPublished: audience === 0 ? null : audience,
    CountryId: country === 0 ? null : country,
  };

  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "ACTION",
      sortable: false,
      headerAlign: "center",
      width: 110,
      renderCell: (params) => {
        return (
          <Grid container direction="column" spacing={1}>
            {params.row?.VisibilityId === 1 ? (
              <Grid item>
                <button
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#5fba47";
                    e.target.style.color = "#fff";
                    e.target.style.cursor = "pointer";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#fff";
                    e.target.style.color = "#5fba47";
                  }}
                  className={classes["PublishButton"]}
                  onClick={() => {
                    props.setRowData(params);
                    setLoadingButton(true);
                    toggleProject(params.id).then((x) => {
                      setLoadingButton(false);
                      setModify(!modify);
                    });
                  }}
                >
                  {loadingButton && params.id === props.rowData.id
                    ? "Loading..."
                    : params.row?.IsPublished === false
                    ? "Publish"
                    : "Unpublish"}
                </button>
              </Grid>
            ) : (
              <></>
            )}

            <Grid item>
              <button
                onClick={() => {
                  props.setRowData(params);
                  props.setViewDetails(true);
                }}
                className={classes["ViewButton"]}
              >
                View
              </button>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "Name",
      headerName: "Project Name",
      width: 150,
    },
    {
      field: "ClientName",
      headerName: "Client",
      width: 150,
      resizeable: true,
    },
    {
      field: "Visibility",
      headerName: "Visibility",
      width: 150,
    },
    {
      field: "Audience",
      headerName: "Audience",
      width: 150,
      renderCell: (params) => (
        <span>{params.row?.IsPublished ? "Published" : "Not Published"}</span>
      ),
    },
    {
      field: "Type",
      headerName: "Type",
      width: 150,
    },
    {
      field: "Country",
      headerName: "Country",
      width: 150,
    },
    {
      field: "Address",
      headerName: "Address",
      width: 200,
    },

    {
      field: "Creator",
      headerName: "Project Creator",
      width: 150,
      sortable: false,
    },
    {
      field: "CreationTime",
      headerName: "Creation Date",
      width: 150,
      sortable: false,
      renderCell: (params) => params.row.CreationTime.slice(0, 10),
    },
  ];
  const [projectTable, setProjectTable] = useState([]);
  useEffect(() => {
    getProjectsList(listData).then((x) => {
      setProjectTable(x.Projects);

      setdataLength(x.Total);
      setLoading(false);
    });
  }, [
    pageSize,
    pageNumber,
    visibility,
    audience,
    country,
    props.searchText,
    modify,
  ]);

  useEffect(() => {
    getProjectFilterInputs().then((x) => {
      setCountries(x.Countries);
      setVisibilities(x.Visibilities);
    });
  }, []);

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 20,
      bottom: params.isLastVisible ? 0 : 20,
    };
  }, []);

  return (
    <Grid container>
      <Grid container item direction={"row"}>
        <Grid item className={classes["TableName"]} width={150} marginLeft={4}>
          Contractor Projects
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item container direction={"row-reverse"} md={9}>
          <Grid item>
            <CustomSelect
              value={country}
              onChange={(option) => {
                setCountry(option);
              }}
            >
              <StyledOption value={0} disabled>
                Country
              </StyledOption>

              {countries.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          <Grid item>
            <CustomSelect
              value={audience}
              onChange={(option) => {
                setAudience(option);
              }}
            >
              <StyledOption value={0} disabled>
                Audience
              </StyledOption>

              <StyledOption key={1} value={true}>
                Published
              </StyledOption>
              <StyledOption key={1} value={false}>
                Not Published
              </StyledOption>
            </CustomSelect>
          </Grid>
          <Grid item>
            <CustomSelect
              value={visibility}
              onChange={(option) => {
                setVisibilty(option);
              }}
            >
              <StyledOption value={0} disabled>
                Visibility
              </StyledOption>

              {visibilities.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {visibility || audience !== 0 || country ? (
            <Grid
              className={classes["clearFilter"]}
              onClick={() => {
                setVisibilty(0);
                setCountry(0);
                setAudience(0);
              }}
            >
              Clear Filters
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {loading ? (
        <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={12}
          >
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
          <>
            {projectTable.length > 0 ? (
              <DataGrid
                style={{ paddingTop: 30, paddingLeft: 10 }}
                initialState={{
                  pagination: {
                    pageSize: 25,
                  },
                }}
                pagination
                paginationMode="server"
                rows={projectTable}
                columns={columns}
                headerHeight={40}
                rowHeight={100}
                getRowSpacing={getRowSpacing}
                showColumnRightBorder
                sx={{
                  [`& .${gridClasses.row}`]: {
                    border: 0,

                    boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
                    color: "#0674b9",
                    overflow: "auto",

                    bgcolor: (theme) =>
                      theme.palette.mode === "light" ? "#fff" : grey[500],
                  },

                  [`& .${gridClasses.columnHeader}`]: {
                    bgcolor: "transparent",
                    color: "rgb(17 24 84)",
                    border: 0,
                  },
                  [`& .${gridClasses.columnHeaders}`]: {
                    border: 0,
                  },
                  [`& .${gridClasses.columnHeaderTitle}`]: {
                    fontFamily: "Muli",
                    fontWeight: "bold",
                  },

                  [`& .${gridClasses.footerContainer}`]: {
                    bgcolor: "transparent",
                    minHeight: "30px",
                    border: 0,
                  },
                  fontFamily: "Muli",
                  fontSize: "13px",
                  border: 0,
                }}
                disableColumnFilter
                getRowId={(row) => row.Id}
                rowCount={dataLength}
                onPageChange={(newPage) => {
                  if (pageSize > dataLength) {
                    setPageNumber(1);
                  } else setPageNumber(newPage + 1);
                }}
                onPageSizeChange={(newPage) => {
                  setPageSize(newPage);
                }}
                pageSize={pageSize}
                //   loading={props.loading}
                components={{
                  NoRowsOverlay: () => (
                    <Stack className={classes["EmptyMessage"]}>
                      No Projects To Display
                    </Stack>
                  ),
                }}
              />
            ) : (
              <Stack className={classes["EmptyMessage"]}>
                No Projects To Display
              </Stack>
            )}
          </>
        </Grid>
      )}
    </Grid>
  );
};

export default Table;

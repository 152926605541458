import React, { useState } from "react";
import classes from "../../../Assets/Styles/SaasUser.module.css";
import { Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Table from "./Table";

const Requests = (props) => {

  const [searchText, setSearchText] = useState(null);
  const [rowData, setRowData] = useState(false);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };

  return (
    <Grid container paddingTop={9}>
      <Grid item container direction={"row"} marginLeft={4}>
        <Grid item container md={3} xs={12}>
          <Grid item xs={12} className={classes["page-title"]} >
            Subscriptions
          </Grid>
          <Grid item
            xs={12}
            style={{
              fontFamily: 'Muli',
              fontsSize: 16,
              lineHeight: 1.31,
              letterSpacing: 'normal',
              textAlign: 'left',
              color: '#101650',
            }}>Subscription Request</Grid>
        </Grid>
        <Grid
          item
          md={9}
          paddingLeft={{ md: 7, xs: 0 }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <TextField
            variant="standard"
            fullWidth
            onChange={inputHandler}
            placeholder="Search…"
            InputProps={{
              startAdornment: (
                <SearchIcon
                  fontSize="small"
                  htmlColor={"#707070"}
                  style={{ marginLeft: 15 }}
                />
              ),
              style: { fontFamily: "Muli" },
            }}
            sx={{
              fontFamily: "Muli",
              backgroundColor: "white",
              borderRadius: 1,

              border: "solid 1px #e9e7e7",
              width: {
                xs: 1,
                sm: 483,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item container paddingTop={5} md={12}>
        <Table
          setRowData={setRowData}
          setOpenSubscModal={props.setOpenSubscModal}
          setSubscEmailReq={props.setSubscEmailReq}
          searchText={searchText}
        />
      </Grid>
    </Grid>
  );
};

export default Requests;
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/SaasUser.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import { styled } from "@mui/system";
import * as SaaSServicesService from "../../../Services/saas-users-services.proxy";
import MainLogo from "../../../Assets/Images/IconsProfile/mainlogo.png";
import Loading from "../../../Components/Loading";
import { useNavigate } from "react-router-dom";
const getMainContractorsList = SaaSServicesService.getMainContractorsList;
const getMainContractorFiltersInputs =
  SaaSServicesService.getMainContractorFiltersInputs;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 12px;
    box-sizing: border-box;
    min-height: 32px;
    width: 9rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    margin: 0.5em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }

    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
    }

    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }

    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    max-height:250px !important;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;

    &:last-of-type {
      border-bottom: none;
    }

    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }

    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }

    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }

    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }

    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

const Table = (props) => {
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setdataLength] = useState();
  const [specialities, setSpecialities] = useState([]);
  const [speciality, setSpeciality] = useState(0);
  const [employeesRanges, setEmployeesRanges] = useState([]);
  const [employeesRange, setEmployeesRange] = useState(0);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (dataLength > 0) {
  //     setLoading(false);
  //   }
  //   else if(dataLength === 0){
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }
  // }, [dataLength]);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: props.searchText,
    SpecialityId: speciality === 0 ? null : speciality,
    EmployeeRangeId: employeesRange === 0 ? null : employeesRange,
    CountryId: country === 0 ? null : country,
  };
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "ACTION",
      sortable: false,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <button
            onMouseOver={(e) => {
              e.target.style.backgroundColor = "#5fba47";
              e.target.style.color = "#fff";
              e.target.style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#fff";
              e.target.style.color = "#5fba47";
            }}
            onClick={() => {
              props.setRowData(params);
              props.setViewDetails(true);
            }}
            className={classes["ViewButton"]}
          >
            View
          </button>
        );
      },
    },
    {
      field: "CompanyName",
      headerName: "Company Name",

      width: 150,
    },
    {
      field: "Company Profile",
      headerName: "Profile",
      width: 100,
      resizeable: true,
      renderCell: (params) => (
        <img
          src={
            params.row.UserDetails?.ProfilePicture
              ? params.row.UserDetails.ProfilePicture.URL
              : MainLogo
          }
          style={{ width: "27px" }}
          alt={"null"}
        />
      ),
    },
    {
      field: "CompanyEmail",
      headerName: "Registration Email",
      width: 230,
    },
    {
      field: "PlanName",
      headerName: "Subscription Plan",
      width: 250,
    },
    {
      field: "PlanStatus",
      headerName: "Subscription Status",
      width: 150,
    },
    {
      field: "Speciality",
      headerName: "Speciality",
      width: 150,
    },
    {
      field: "Country",
      headerName: "Country",
      width: 120,
    },

    {
      field: "Address",
      headerName: "Company Address",
      width: 200,
      sortable: false,
    },
    {
      field: "RegistrationNumber",
      headerName: "Registration Number",
      width: 150,
      sortable: false,
    },
    {
      field: "EmployeesRange",
      headerName: "Employees Range",
      width: 150,
      sortable: false,
    },
    {
      field: "CompanyPhoneNumber",
      headerName: "Phone Number",
      width: 150,
      sortable: false,
    },
    {
      field: "Contact Name ",
      headerName: "Contact Name ",
      width: 150,
      sortable: false,
      renderCell: (params) => <span>{params.row?.UserDetails?.Name}</span>,
    },
    {
      field: "Position",
      headerName: "Position",
      width: 150,
      sortable: false,
      renderCell: (params) => <span>{params.row?.UserDetails?.Position}</span>,
    },
    {
      field: "PersonalEmail",
      headerName: "Contact Email",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <span>{params.row?.UserDetails?.PersonalEmail}</span>
      ),
    },
    {
      field: "PhoneNumber",
      headerName: "Contact Phone Number",
      width: 170,
      sortable: false,
      renderCell: (params) => (
        <span>{params.row?.UserDetails?.PhoneNumber}</span>
      ),
    },
  ];
  const [mcTable, setMcTable] = useState([]);
  useEffect(() => {
    getMainContractorsList(listData).then((x) => {
      if (x.statusCode === 401 || x.statusCode === 500 || x.error || !x) {
        localStorage.clear();
        props.setToken(null);
        navigate('/')
      } else {
        setMcTable(x.Companies);
        setdataLength(x.Total);
        setLoading(false);
      }
    });
  }, [
    pageSize,
    pageNumber,
    employeesRange,
    speciality,
    country,
    props.searchText,
  ]);
  useEffect(() => {
    getMainContractorFiltersInputs().then((x) => {
      if (x.statusCode === 401 || x.statusCode === 500 || x.error || !x) {
        localStorage.clear();
        props.setToken(null);
        navigate('/')
      } else {
        setSpecialities(x.Specialities);
        setEmployeesRanges(x.EmployeesRanges);
        setCountries(x.Countries);
      }
    });
  }, []);
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 20,
      bottom: params.isLastVisible ? 0 : 20,
    };
  }, []);
  return (
    <Grid container>
      <Grid container item direction={"row"}>
        <Grid item className={classes["TableName"]} width={130} marginLeft={4}>
          Main Contractor
        </Grid>
        <Grid item md={1} sm={1}></Grid>
        <Grid item md={9} sm={8} xs={12} container direction={"row-reverse"}>
          <Grid item>
            <CustomSelect
              value={country}
              onChange={(option) => {
                setCountry(option);
              }}
            >
              <StyledOption value={0} disabled>
                Country
              </StyledOption>

              {countries.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          <Grid item>
            <CustomSelect
              value={employeesRange}
              onChange={(option) => {
                setEmployeesRange(option);
              }}
            >
              <StyledOption value={0} disabled>
                Employees Range
              </StyledOption>

              {employeesRanges.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          <Grid item>
            <CustomSelect
              value={speciality}
              onChange={(option) => {
                setSpeciality(option);
              }}
            >
              <StyledOption value={0} disabled>
                Specialty
              </StyledOption>

              {specialities.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {employeesRange || speciality || country ? (
            <Grid
              className={classes["clearFilter"]}
              onClick={() => {
                setSpeciality(0);
                setCountry(0);
                setEmployeesRange(0);
              }}
            >
              Clear Filters
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {loading ? (
        <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={12}
          >
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
          {mcTable.length > 0 ? (
            <>
              <DataGrid
                style={{ paddingTop: 30, paddingLeft: 10 }}
                initialState={{
                  pagination: {
                    pageSize: 25,
                  },
                }}
                pagination
                paginationMode="server"
                rows={mcTable}
                columns={columns}
                headerHeight={40}
                rowHeight={100}
                getRowSpacing={getRowSpacing}
                showColumnRightBorder
                sx={{
                  [`& .${gridClasses.row}`]: {
                    border: 0,

                    boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
                    color: "#0674b9",
                    overflow: "auto",

                    bgcolor: (theme) =>
                      theme.palette.mode === "light" ? "#fff" : grey[500],
                  },

                  [`& .${gridClasses.columnHeader}`]: {
                    bgcolor: "transparent",
                    color: "rgb(17 24 84)",
                    border: 0,
                  },
                  [`& .${gridClasses.columnHeaders}`]: {
                    border: 0,
                  },
                  [`& .${gridClasses.columnHeaderTitle}`]: {
                    fontFamily: "Muli",
                    fontWeight: "bold",
                  },

                  [`& .${gridClasses.footerContainer}`]: {
                    bgcolor: "transparent",
                    minHeight: "30px",
                    border: 0,
                  },
                  fontFamily: "Muli",
                  fontSize: "13px",
                  border: 0,
                }}
                disableColumnFilter
                getRowId={(row) => row.companyId}
                rowCount={dataLength}
                onPageChange={(newPage) => {
                  if (pageSize > dataLength) {
                    setPageNumber(1);
                  } else setPageNumber(newPage + 1);
                }}
                onPageSizeChange={(newPage) => {
                  setPageSize(newPage);
                }}
                pageSize={pageSize}
              />
            </>
          ) : (
            <Stack className={classes["EmptyMessage"]}>
              No Main Contractors to display
            </Stack>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Table;

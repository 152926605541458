import React, { useState, useEffect } from "react";
import classes from "../../../Assets/Styles/Shapes.module.css";
import { Grid, TextField } from "@mui/material";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const ariaLabel = { "aria-label": "description" };
const Form2 = (props) => {
  const [commentError, setCommentError] = useState(false);
  const [shapeCodeError, setShapeCodeError] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (
      !props.shapeCategory ||
      !props.shapeCatelogue ||
      !props.shapeType ||
      !props.shapeCode ||
      !props.shapeStatus ||
      (props.shapeCategory === 2 && !props.shapeSubCode) ||
      commentError ||
      shapeCodeError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  return (
    <Grid container paddingLeft={4} paddingTop={2.5} paddingBottom={5}>
      <Grid container item xs={12} direction={"row"} gap={2}>
        <Grid container sm={1.8} gap={1}>
          <Grid item className={classes["FieldsLabels"]}>
            *No. of Lines
          </Grid>
          <Grid item>
            <Input
              disabled
              value={props.numOfLines}
              fullWidth
              inputProps={ariaLabel}
            />
          </Grid>
        </Grid>
        <Grid container sm={1.8} gap={1}>
          <Grid item className={classes["FieldsLabels"]}>
            *No. of Angles
          </Grid>
          <Grid item>
            <Input
              disabled
              value={props.numOfAngles}
              fullWidth
              inputProps={ariaLabel}
            />
          </Grid>
        </Grid>
        <Grid container sm={2.1} gap={1}>
          <Grid item className={classes["FieldsLabels"]}>
            *Shape Type
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              size="small"
              variant="standard"
              value={props.shapeType}
              select
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              onChange={(e) => {
                props.setShapeType(e.target.value);
                // if (e.target.value) {
                //   props.setShapeSubCode(null);
                // }
                // if (e.target.value === 2) {
                //   props.setShapeCode(99);
                // } else {
                //   props.setShapeCode("");
                // }
              }}
            >
              {props.shapeTypes.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container sm={2.1} gap={1}>
          <Grid item className={classes["FieldsLabels"]}>
            *Shape Category
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              size="small"
              variant="standard"
              value={props.shapeCategory}
              select
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              onChange={(e) => {
                props.setShapeCategory(e.target.value);
                if (e.target.value) {
                  props.setShapeSubCode(null);
                }
                if (e.target.value === 2) {
                  props.setShapeCode(99);
                } else {
                  props.setShapeCode("");
                }
              }}
            >
              {props.shapeCategories.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container sm={2.2} gap={1}>
          <Grid item className={classes["FieldsLabels"]}>
            *Shape Catalogue
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              size="small"
              variant="standard"
              value={props.shapeCatelogue}
              select
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              onChange={(e) => {
                props.setShapeCatelogue(e.target.value);
              }}
            >
              {props.shapeCatelogues.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container sm={3.6} gap={1}>
          <Grid item className={classes["FieldsLabels"]}>
            *Shape Code
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              size="small"
              error={shapeCodeError}
              helperText={
                shapeCodeError ? "Shape Code must be between 1-99" : ""
              }
              variant="standard"
              type={"number"}
              InputProps={{
                inputProps: { min: 1, max: 99 },
              }}
              value={props.shapeCode}
              onChange={(e) => {
                props.setShapeCode(e.target.value);
                if (e.target.value > 99) setShapeCodeError(true);
                else {
                  setShapeCodeError(false);
                }
                if (+e.target.value === 99) {
                  props.setShapeCategory(2);
                } else {
                  props.setShapeCategory(1);
                }
              }}
            ></TextField>
          </Grid>
        </Grid>
        <Grid
          container
          sm={3.75}
          gap={1}
          sx={{ opacity: props.shapeCategory === 1 ? 0.4 : 1 }}
        >
          <Grid item className={classes["FieldsLabels"]}>
            *Shape Sub-Code
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              size="small"
              disabled={props.shapeCategory === 1 ? true : false}
              variant="standard"
              value={props.shapeSubCode}
              onChange={(e) => {
                props.setShapeSubCode(e.target.value);
              }}
            ></TextField>
          </Grid>
        </Grid>
        <Grid container sm={2.8} gap={1} marginLeft={2}>
          <Grid item className={classes["FieldsLabels"]}>
            *Status
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              size="small"
              variant="standard"
              value={props.shapeStatus}
              select
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              onChange={(e) => {
                props.setShapeStatus(e.target.value);
              }}
            >
              {props.shapeStatuses.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid sm={3.6} gap={1}>
          <Grid item className={classes["FieldsLabels"]}>
            Visibility
          </Grid>
          <Grid item xs={12} paddingTop={1}>
            <TextField
              style={{ width: "100%" }}
              size="small"
              disabled={true}
              variant="standard"
              value={
                props.shapeVisibility === 1 ? "Not Published" : "Published"
              }
            ></TextField>
          </Grid>
        </Grid>
        <Grid container sm={6.7} gap={1} marginLeft={2}>
          <Grid item className={classes["FieldsLabels"]}>
            Comment
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={commentError}
              helperText={
                commentError ? "Comment must be less than 255 chars" : null
              }
              label={props.shapeComment ? "" : "Got any notes?"}
              style={{ width: "100%", border: 0, backgroundColor: "#e9e7e7" }}
              size="small"
              InputLabelProps={{
                shrink: false,
              }}
              rows={3}
              multiline
              variant="outlined"
              value={props.shapeComment}
              onChange={(e) => {
                if (e.target.value.length > 255) {
                  setCommentError(true);
                } else {
                  setCommentError(false);
                }
                props.setShapeComment(e.target.value);
              }}
            ></TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={11.7} direction={"row"}>
        <Grid item xs={6}>
          <button
            className={classes["SaveButton"]}
            onClick={() => {
              props.setStep(1);
            }}
          >
            Back
          </button>
        </Grid>
        <Grid item xs={6} container direction={"row-reverse"}>
          <button
            disabled={isDisabled}
            className={classes["SaveButton"]}
            onClick={() => {
              props.uploadFile();
            }}
          >
            Save
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Form2;

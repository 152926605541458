import * as constFile from "../cmsConst";
import axios from "axios";

const GET_SUBSCRIPTIOS_REQUESTS = constFile.GET_SUBSCRIPTIOS_REQUESTS;
export const getSubscriptionsRequests = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_SUBSCRIPTIOS_REQUESTS}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};
const GET_SUBSCRIPTIOS_REQUEST_FILTER_INPUT_DATA =
  constFile.GET_SUBSCRIPTIOS_REQUEST_FILTER_INPUT_DATA;
export const getSubscriptionsRequestFilterInputData = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${
        process.env.REACT_APP_URL + GET_SUBSCRIPTIOS_REQUEST_FILTER_INPUT_DATA
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};
const GET_SUBSCRIPTIOS_MANAGED_FILTER_INPUT_DATA =
  constFile.GET_SUBSCRIPTIOS_MANAGED_FILTER_INPUT_DATA;
export const getSubscriptionsManagedFilterInputData = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${
        process.env.REACT_APP_URL + GET_SUBSCRIPTIOS_MANAGED_FILTER_INPUT_DATA
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};
//GET_SUBSCRIPTIONS_MANAGED
const GET_SUBSCRIPTIONS_MANAGED = constFile.GET_SUBSCRIPTIONS_MANAGED;
export const getSubscritionsManaged = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_SUBSCRIPTIONS_MANAGED}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const GET_SUBSCRIPTIONS_MANAGED_INPUT_DATA =
  constFile.GET_SUBSCRIPTIONS_MANAGED_INPUT_DATA;
export const getSubscriptionsManagedInputData = (subscriptionRequestId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_SUBSCRIPTIONS_MANAGED_INPUT_DATA}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          subscriptionRequestId: subscriptionRequestId,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const ADD_PLAN = constFile.ADD_PLAN;

export const AddManagedPlan = (
  SubscriptionRequestId,
  PlanName,
  Email,
  CompanyName,
  PlanId,
  BillingPlanId,
  Price,
  StartDate,
  EndDate,
  PaymentReferenceNumber,
  NumOfFactories,
  FactoryVolume
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + ADD_PLAN}`,
      {
        SubscriptionRequestId: SubscriptionRequestId,
        PlanName: PlanName,
        Email: Email,
        CompanyName: CompanyName,
        PlanId: PlanId,
        BillingPlanId: BillingPlanId,
        Price: Price,
        StartDate: StartDate,
        EndDate: EndDate,
        PaymentReferenceNumber: PaymentReferenceNumber,
        NumOfFactories: NumOfFactories,
        FactoryVolume: FactoryVolume,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data.status;
    });
};
const ACTIVATE_PLAN = constFile.ACTIVATE_PLAN;
export const activatePlan = (managedSubscriptionId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + ACTIVATE_PLAN}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        managedSubscriptionId: managedSubscriptionId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};
const DEACTIVATE_PLAN = constFile.DEACTIVATE_PLAN;
export const deactivatePlan = (managedSubscriptionId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + DEACTIVATE_PLAN}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        managedSubscriptionId: managedSubscriptionId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};
const MANAGE_SUBSCRIPTION_BY_ID = constFile.MANAGE_SUBSCRIPTION_BY_ID;
export const getinfoPlan = (managedSubscriptionId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + MANAGE_SUBSCRIPTION_BY_ID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        managedSubscriptionId: managedSubscriptionId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};
const RENEW_PLAN = constFile.RENEW_PLAN;

export const renewManagedPlan = (
  ManagedSubscriptionId,

  StartDate,
  EndDate,
  PaymentReferenceNumber,
  NumOfFactories,
  FactoryVolume
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + RENEW_PLAN}`,
      {
        ManagedSubscriptionId: ManagedSubscriptionId,

        StartDate: StartDate,
        EndDate: EndDate,
        PaymentReferenceNumber: PaymentReferenceNumber,
        NumOfFactories: NumOfFactories,
        FactoryVolume: FactoryVolume,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data.status;
    });
};
const MANAGE_SUBSCRIPTION_BY_EMAIL = constFile.MANAGE_SUBSCRIPTION_BY_EMAIL;
export const getinfoPlanByEMAIL = (email) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + MANAGE_SUBSCRIPTION_BY_EMAIL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        email: email,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_SUBSCRIPTIONS_REQUESTS_NUMBER =
  constFile.GET_SUBSCRIPTIONS_REQUESTS_NUMBER;
export const getSubscriptionsRequNo = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_SUBSCRIPTIONS_REQUESTS_NUMBER}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};

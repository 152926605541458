import React, { useEffect, useState } from "react";
import { Grid, Box, TextField, Select, MenuItem } from "@mui/material";
import classes from "../../Assets/Styles/Support.module.css";
import AddTM from "./AddTM";
import * as cmsTMServices from "../../Services/cms-members-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";

const getCMSTM = cmsTMServices.getCMSTM;
const assignCMSUserToCompany = cmsTMServices.assignCMSUserToCompany;

const AssignSupport = (props) => {
  useEffect(() => {
    props.setHideDrawer(false);
  });
  const [tempTeamMembers, setTempTeamMembers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [countries, setCountries] = useState([]);

  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [openModal, setOpenModal] = useState(false);
  const [modify, setModify] = useState(false);
  const [assigning, setAssigning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chosingId, setChosingId] = useState(null);

  useEffect(() => {
    setLoading(true);
    getCMSTM().then((x) => {
      setTeamMembers(structuredClone(x.TeamMembers));
      setTempTeamMembers(x.TeamMembers);
      setCountries(x.Countries);
      setCompanies(x.companies);
      setLoading(false);
    });
  }, [modify]);
  return (
    <Grid container paddingTop={9}>
      <Grid item className={classes["page-title"]} xs={12} paddingLeft={4}>
        Support
      </Grid>
      <Grid item container xs={11.5} direction={"row-reverse"}>
        <Grid item>
          <button
            style={{ width: "fit-content", padding: 10 }}
            onClick={() => {
              setOpenModal(true);
            }}
            className={classes["RunButton"]}
          >
            Add A Support
          </button>
        </Grid>
      </Grid>
      {loading ? (
        <Grid item container justifyContent="center" paddingTop="10%">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          item
          container
          bgcolor={"rgb(252 249 249)"}
          marginTop={1}
          paddingBottom={5}
          paddingTop={1}
          paddingLeft={4}
        >
          <Grid item className={classes["page-sub-title"]} xs={12}>
            Assign support to company
          </Grid>

          <Grid item container xs={12} direction={"row"}>
            <Grid item xs={4} className={classes["teamAndCom"]}>
              Team Members:
            </Grid>
            <Grid item xs={6} className={classes["teamAndCom"]}>
              Companies:
            </Grid>
          </Grid>
          <Grid container gap={2} paddingTop={1}>
            {teamMembers?.map((tm) => (
              <Grid item container xs={12} direction={"row"} key={tm.Id}>
                <Grid item xs={4}>
                  <Grid
                    item
                    className={classes["Rectangle-3005"]}
                    xs={10}
                    sm={5}
                  >
                    {tm.Name}
                  </Grid>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Grid item xs={10} sm={12}>
                    <Select
                      fullWidth
                      MenuProps={{ disableScrollLock: true }}
                      value={tm?.TenantId}
                      sx={{
                        height: "40px",
                        fontFamily: "Muli",
                        fontSize: 15,
                        color: "#101650",
                      }}
                      InputProps={{
                        style: {},
                      }}
                      onChange={(e) => {
                        tm.TenantId = e.target.value;
                        forceUpdate();
                      }}
                    >
                      <MenuItem value={0} key={0} disabled>
                        Companies
                      </MenuItem>
                      {companies.map((r) => (
                        <MenuItem
                          value={r.Id}
                          key={r.Id}
                          style={{ display: "block" }}
                        >
                          {r.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                {tm?.TenantId !==
                tempTeamMembers.filter((x) => x.Id === tm.Id)[0]?.TenantId ? (
                  <Grid item xs={2} paddingLeft={1}>
                    <button
                      style={{ width: "fit-content", padding: 10 }}
                      onClick={() => {
                        setChosingId(tm.Id);
                        setAssigning(true);
                        assignCMSUserToCompany(tm.Id, tm.TenantId).then(() => {
                          setModify(!modify);
                          setAssigning(false);
                        });
                      }}
                      className={classes["RunButton"]}
                    >
                      {assigning && chosingId === tm.Id
                        ? "Assigning..."
                        : "Assign"}
                    </button>
                  </Grid>
                ) : (
                  <></>
                )}
                {tm?.TenantId &&
                tm?.TenantId ===
                  tempTeamMembers.filter((x) => x.Id === tm.Id)[0]?.TenantId ? (
                  <Grid item xs={2} paddingLeft={1}>
                    <button
                      style={{ width: "fit-content", padding: 10 }}
                      onClick={() => {
                        setChosingId(tm.Id);
                        setAssigning(true);
                        assignCMSUserToCompany(tm.Id, null).then(() => {
                          setModify(!modify);
                          setAssigning(false);
                        });
                      }}
                      className={classes["RunButton"]}
                    >
                      {assigning && chosingId === tm.Id
                        ? "UnAssigning..."
                        : "Unassign"}
                    </button>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      <AddTM
        openModal={openModal}
        setOpenModal={setOpenModal}
        countries={countries}
        setCountries={setCountries}
        modify={modify}
        setModify={setModify}
      />
    </Grid>
  );
};

export default AssignSupport;

import * as constFile from "../cmsConst";
import axios from "axios";
const FormData = require("form-data");
const GET_SHAPE_LIST = constFile.GET_SHAPE_LIST;
export const getShapeList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_SHAPE_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};

const GET_SHAPE_LIST_FILTER_DATA = constFile.GET_SHAPE_LIST_FILTER_DATA;
export const getShapeListFilterData = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_SHAPE_LIST_FILTER_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};

const ADD_OR_DELETE_SHAPE = constFile.ADD_OR_DELETE_SHAPE;
export const addShape = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + ADD_OR_DELETE_SHAPE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};
export const deleteShape = (ShapeId, Password) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + ADD_OR_DELETE_SHAPE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { ShapeId: ShapeId, Password: Password },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const CHANGE_SHAPE_VISIBILITY = constFile.CHANGE_SHAPE_VISIBILITY;
export const changeShapeVisibility = (ShapeId, VisibiltyId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_SHAPE_VISIBILITY}`,
      { ShapeId: ShapeId, VisibiltyId: VisibiltyId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};

const GET_CREATE_OR_EDIT_DATA_SHAPE = constFile.GET_CREATE_OR_EDIT_DATA_SHAPE;
export const getCreateOrEditData = (shapeId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_CREATE_OR_EDIT_DATA_SHAPE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        shapeId: shapeId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};
const UPDATE_SHAPE = constFile.UPDATE_SHAPE;
export const updateShape = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_SHAPE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};
const UPLOAD_FILE_SHAPE = constFile.UPLOAD_FILE_SHAPE;
export const uploadFileShape = (file, ProjectId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  data.append("ProjectId", ProjectId);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_FILE_SHAPE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const DELETE_FILE_SHAPE = constFile.DELETE_FILE_SHAPE;
export const deleteFileShape = (fileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_FILE_SHAPE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

import { React, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../src/Assets/Styles/projects.module.css";
import * as ProjectServices from "../../Services/projects-services.proxy";
import clientIcon from "../../Assets/Images/IconsProfile/clientlogo.png";

const getClientDetails = ProjectServices.getClientDetails;

const ClientDetails = (props) => {
  const [clientDetails, setClientDetails] = useState({});
  useEffect(() => {
    if (props.rowData) {
      getClientDetails(props.clientId).then((x) => {
        setClientDetails(x);
      });
    }
  }, []);

  const renderFields = (FieldName, FieldData) => {
    return (
      <Grid container gap={2} sm={3.5}>
        <Grid md={12} item className={classes["FieldHeader"]}>
          {FieldName}
        </Grid>
        <Grid item container direction="row" className={classes["FieldData"]}>
          <Grid item md={9}>
            {FieldData}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container paddingBottom={10} paddingLeft={{ sm: 2, md: 0 }}>
      <Grid container item direction={"row"}>
        <Grid item className={classes["TableName"]} width={50} marginLeft={4}>
          Client
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={9.8} container direction={"row-reverse"}>
          <Grid item paddingBottom={1}>
            <button
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#0674b9";
                e.target.style.color = "#fff";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#0674b9";
              }}
              onClick={() => {
                props.setViewClientDetails(false);
              }}
              className={classes["GoBack"]}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        borderTop={"solid lightGrey 0.5px "}
        paddingLeft={4}
        paddingTop={5}
        gap={3}
      >
        <Grid container gap={2}>
          <Grid md={12} xs={12} item className={classes["FieldHeader"]}>
            Client Name
          </Grid>
          <Grid md={3.5} xs={12} item className={classes["FieldData"]}>
            {clientDetails?.Name}
          </Grid>
        </Grid>
        <Grid container gap={3}>
          {renderFields(
            "Client Profile",
            <img
              src={
                clientDetails.UserDetails?.ProfilePicture
                  ? clientDetails.UserDetails?.ProfilePicture.URL
                  : clientIcon
              }
              style={{ width: 27 }}
              alt={"profile"}
            ></img>
          )}

          {renderFields("Email ", clientDetails?.Email)}
          {renderFields("Phone Number", clientDetails?.PhoneNumber)}
          {renderFields("Website ", clientDetails?.Website)}
          {renderFields("Country", clientDetails?.Country)}
          {renderFields("Address", clientDetails?.Address)}
          {renderFields("Description", clientDetails?.Description)}

          {/* {renderFields("File", clientDetails.Documents?.map(d=>d?.DisplayName))} */}

          {renderFields("Contact Name", clientDetails?.PersonalContactName)}
          {renderFields("Contact Email", clientDetails?.PersonalEmail)}
          {renderFields("Position", clientDetails?.Position)}
          {renderFields(
            "Contact Phone Number",
            clientDetails?.PersonalPhoneNumber
          )}
          {renderFields("Creator", clientDetails?.Creator)}
          {renderFields(
            "Created Date",
            clientDetails?.CreationTime?.slice(0, 10)
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientDetails;

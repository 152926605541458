import * as constFile from "../cmsConst";
import axios from "axios";
const LOGIN = constFile.LOGIN;

export const logIn = (email, password) => {
  return axios
    .post(`${process.env.REACT_APP_URL + LOGIN}`, {
      Email: email,
      Password: password,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => { });
};

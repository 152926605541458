import * as constFile from "../cmsConst";
import axios from "axios";
//get tips list
const GET_TIPS_LIST = constFile.GET_TIPS_LIST;
export const getTipsList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_TIPS_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const ADD_TIP = constFile.ADD_TIP;
export const addTip = (Title, Content) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + ADD_TIP}`,
      {
        Id: null,
        Title: Title,
        Content: Content,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => { });
};
const GET_TIP_BY_ID = constFile.GET_TIP_BY_ID;
export const getTipById = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_TIP_BY_ID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },

      params: { Id: Id },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};

const UPDATE_TIP = constFile.UPDATE_TIP;

export const updateTip = (Id, Title, Content) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + UPDATE_TIP}`,
      {
        Id: Id,
        Title: Title,
        Content: Content,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => { });
};
const DELETE_TIP = constFile.DELETE_TIP;
export const deleteTip = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_TIP}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { Id: Id },
    })
    .then((response) => {

      return response;
    })
    .catch((err) => { });
};
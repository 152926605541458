import * as constFile from "../cmsConst";
import axios from "axios";
var fileDownload = require("js-file-download");
const GET_MAIN_CONTRACTORS_LIST = constFile.GET_MAIN_CONTRACTORS_LIST;
export const getMainContractorsList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_MAIN_CONTRACTORS_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data });
};
const GET_MAIN_CONTRACTOR_DETAILS = constFile.GET_MAIN_CONTRACTOR_DETAILS;
export const getMainContractorDetails = (companyId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_MAIN_CONTRACTOR_DETAILS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },

      params: { companyId: companyId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const GET_MAIN_CONTRACTOR_FILTERS_INPUTS =
  constFile.GET_MAIN_CONTRACTOR_FILTERS_INPUTS;
export const getMainContractorFiltersInputs = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_MAIN_CONTRACTOR_FILTERS_INPUTS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data });
};
const GET_SUB_CONTRACTORS_LIST = constFile.GET_SUB_CONTRACTORS_LIST;
export const getSubContractorsList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_SUB_CONTRACTORS_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const GET_SUB_CONTRACTOR_DETAILS = constFile.GET_SUB_CONTRACTOR_DETAILS;
export const getSubContractorDetails = (companyId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_SUB_CONTRACTOR_DETAILS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },

      params: { companyId: companyId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const GET_SUB_CONTRACTOR_FILTERS_INPUTS =
  constFile.GET_SUB_CONTRACTOR_FILTERS_INPUTS;
export const getSubContractorFiltersInputs = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_SUB_CONTRACTOR_FILTERS_INPUTS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const GET_FACTORIES = constFile.GET_FACTORIES;
export const getFactories = (CompanyId, Year) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_FACTORIES}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        companyId: CompanyId,
        year: Year,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const EXPORT_REPORT_FACTORIES = constFile.EXPORT_REPORT_FACTORIES;
export const exportReportFactories = (companyId, year) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + EXPORT_REPORT_FACTORIES}`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { companyId: companyId, year: year },
    })
    .then((response) => {
      fileDownload(response.data, `${year} Delivery notes report.xlsx`);
      return response;
    })
    .catch((err) => { });
};

import { React, useState } from "react";
import { Grid, Box, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import classes from "./Notifications.module.css";
import Table from "./Table";
import AddModal from "./AddModal";

const NotificationsView = (props) => {
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [searchText, setSearchText] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modify, setModify] = useState(false);
  const [value, setValue] = useState("1");
  const [rowData, setRowData] = useState(null);

  return (
    <Grid container paddingTop={9}>
      <Grid item container direction={"row"} marginLeft={4}>
        <Grid item md={3} xs={12} className={classes.title}>
          Notifications
        </Grid>

        <Grid
          item
          md={9}
          paddingLeft={{ md: 7, xs: 0 }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <TextField
            fullWidth
            size="small"
            onChange={inputHandler}
            placeholder="Search…"
            InputProps={{
              startAdornment: (
                <SearchIcon
                  fontSize="small"
                  htmlColor={"#707070"}
                  style={{ marginLeft: 15 }}
                />
              ),
              style: { fontFamily: "Muli" },
            }}
            sx={{
              fontFamily: "Muli",
              backgroundColor: "white",
              borderRadius: 1,

              border: "solid 1px #e9e7e7",
              width: {
                xs: 1,
                sm: 483,
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          setOpenModal={setOpenModal}
          searchText={searchText}
          modify={modify}
          setModify={setModify}
          value={value}
          setValue={setValue}
          setRowData={setRowData}
        />
      </Grid>

      <AddModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        modify={modify}
        setModify={setModify}
        value={value}
        setValue={setValue}
        rowData={rowData}
        setRowData={setRowData}
      />
    </Grid>
  );
};

export default NotificationsView;

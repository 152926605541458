import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import classes from "./Notifications.module.css";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Stack } from "@mui/material";
import Bin from "../../Assets/Images/Reports/Bin.png";
import Eye from "../../Assets/Images/Reports/Eye.png";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import Loading from "../../Components/Loading";

import * as notiServices from "../../Services/notification-services.proxy";
const getNotificationList = notiServices.getNotificationList;
const deleteNoti = notiServices.deleteNoti;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Table = (props) => {
  const [chosenId, setChosenId] = useState(null);
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "ACTION",
      sortable: false,
      width: 120,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Grid
            container
            direction={"column"}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              item
              className={classes["IconEye"]}
              onClick={() => {
                props.setRowData(params);
                props.setOpenModal(true);
              }}
            >
              <img src={Eye} alt={"pen"}></img>
            </Grid>
            {loadingButton && params.row.Id === chosenId ? (
              <Grid item className={classes["IconEye"]}>
                <CircularProgress></CircularProgress>
              </Grid>
            ) : (
              <Grid
                item
                className={classes["IconEye"]}
                onClick={() => {
                  setLoadingButton(true);
                  setChosenId(params.row.Id);
                  deleteNoti(params.row.Id).then(() => {
                    setLoadingButton(false);
                    props.setModify(!props.modify);
                  });
                }}
              >
                <img src={Bin} alt={"pen"}></img>
              </Grid>
            )}
          </Grid>
        );
      },
    },
    {
      field: "Title",
      headerName: "Title",

      width: 120,
    },

    {
      field: "Category",
      headerName: "Content Category",
      width: 200,
    },

    {
      field: "Content",
      headerName: "Content",
      width: 350,
    },
    {
      field: "Recpient",
      headerName: "Recipient",
      width: 150,
      sortable: false,
    },
    {
      field: "RecpientGroups",
      headerName: "Specific Group",
      width: 250,
      sortable: false,
    },
    {
      field: "TriggeredAt",
      headerName: "Date & Time",
      width: 250,
      sortable: false,
      renderCell: (params) => new Date(params.row.TriggeredAt).toLocaleString(),
    },
  ];

  const [notiTable, setNotiTable] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setdataLength] = useState(0);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (dataLength > 0) {
      setLoading(false);
    } else if (dataLength === 0) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [dataLength]);
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 20,
      bottom: params.isLastVisible ? 0 : 20,
    };
  }, []);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: props.searchText,
    Scheduled: props.value === "1" ? true : false,
  };

  useEffect(() => {
    let apiTest = true;

    getNotificationList(listData).then((x) => {
      if (apiTest) {
        setNotiTable(x.Notifications);
        setdataLength(x.Total);
        setLoading(false);
      }
    });

    return () => {
      apiTest = false;
    };
  }, [pageSize, pageNumber, props.searchText, props.value, props.modify]);

  const handleChange = (event, newValue) => {
    props.setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", paddingLeft: "1%", paddingTop: "3%" }}>
      <TabContext value={props.value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab label="Pending" value="1" className={classes.tabName} />
            <Tab label="Sent" value="2" className={classes.tabName} />
            <Grid item container lg={10} direction={"row-reverse"}>
              <Grid
                item
                className={classes.NewButton}
                onClick={() => props.setOpenModal(true)}
              >
                New Notification
              </Grid>
            </Grid>
          </TabList>
        </Box>

        {loading ? (
          <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              paddingTop={12}
            >
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
            {notiTable.length > 0 ? (
              <DataGrid
                initialState={{
                  pagination: {
                    pageSize: 25,
                  },
                }}
                pagination
                paginationMode="server"
                rows={notiTable}
                columns={columns}
                headerHeight={40}
                rowHeight={150}
                getRowSpacing={getRowSpacing}
                showColumnRightBorder
                sx={{
                  [`& .${gridClasses.row}`]: {
                    border: 0,

                    boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
                    color: "#0674b9",
                    overflow: "auto",

                    bgcolor: (theme) =>
                      theme.palette.mode === "light" ? "#fff" : grey[500],
                  },
                  [`& .${gridClasses.cell}`]: {
                    overflow: "visible",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                  },
                  [`& .${gridClasses.columnHeader}`]: {
                    bgcolor: "transparent",
                    color: "rgb(17 24 84)",
                    border: 0,
                  },
                  [`& .${gridClasses.columnHeaders}`]: {
                    border: 0,
                  },
                  [`& .${gridClasses.columnHeaderTitle}`]: {
                    fontFamily: "Muli",
                    fontWeight: "bold",
                  },

                  [`& .${gridClasses.footerContainer}`]: {
                    bgcolor: "transparent",
                    minHeight: "30px",
                    border: 0,
                  },
                  fontFamily: "Muli",
                  fontSize: "13px",
                  border: 0,
                }}
                disableColumnFilter
                getRowId={(row) => row.Id}
                rowCount={dataLength}
                onPageChange={(newPage) => {
                  if (pageSize > dataLength) {
                    setPageNumber(1);
                  } else setPageNumber(newPage + 1);
                }}
                onPageSizeChange={(newPage) => {
                  setPageSize(newPage);
                }}
                pageSize={pageSize}
                //   loading={props.loading}
                // components={{
                //   NoRowsOverlay: () => (
                //     <Stack className={classes["EmptyMessage"]}>
                //       No Notifications to Display
                //     </Stack>
                //   ),
                // }}
              />
            ) : (
              <Stack className={classes["EmptyMessage"]}>
                No Notifications to Display
              </Stack>
            )}
          </Grid>
        )}
      </TabContext>
    </Box>
  );
};
export default Table;

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/SaasUser.module.css";
import * as tmServices from "../../../Services/team-member-services.proxy";

import TeamMemberLogo from "../../../Assets/Images/IconsProfile/TeamMemberProfile.png";

const getTMById = tmServices.getTMById;
const TeamMemberDetails = (props) => {
  const [teamMemberDetails, setTeamMemberDetails] = useState({});

  useEffect(() => {
    if (props.rowData) {
      getTMById(props.rowData.row.Id).then((x) => {
        setTeamMemberDetails(x);
      });
    }
  }, []);
  const renderFields = (FieldName, FieldData) => {
    return (
      <Grid container gap={2} sm={3.5}>
        <Grid md={12} item className={classes["FieldHeader"]}>
          {FieldName}
        </Grid>
        <Grid md={12} item className={classes["FieldData"]}>
          {FieldData}
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container>
      <Grid container item direction={"row"}>
        <Grid item className={classes["TableName"]} width={130} marginLeft={4}>
          Team Member
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={9} container direction={"row-reverse"} paddingBottom={1.5}>
          <Grid item>
            <button
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#0674b9";
                e.target.style.color = "#fff";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#0674b9";
              }}
              onClick={() => {
                props.setViewDetails(false);
              }}
              className={classes["GoBack"]}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        borderTop={"solid lightGrey 0.5px "}
        paddingLeft={4}
        paddingTop={5}
        gap={3}
      >
        <Grid container gap={2} md={3.5}>
          <Grid md={12} xs={12} item className={classes["FieldHeader"]}>
            Name
          </Grid>
          <Grid md={12} xs={12} item className={classes["FieldData"]}>
            {teamMemberDetails.Name}
          </Grid>
        </Grid>
        <Grid container gap={2} md={3.5} paddingLeft={1.7}>
          <Grid md={12} item className={classes["FieldHeader"]}>
            Profile
          </Grid>
          <Grid md={12} item>
            <img
              src={
                teamMemberDetails.ProfilePicture
                  ? teamMemberDetails.ProfilePicture.URL
                  : TeamMemberLogo
              }
              style={{ width: 25 }}
              alt={"ptofile"}
            ></img>
          </Grid>
        </Grid>

        <Grid container gap={5}>
          {renderFields("Email", teamMemberDetails.Email)}
          {renderFields("Country", teamMemberDetails.Country)}
          {renderFields("Address", teamMemberDetails.Address)}
          {renderFields("Phone Number", teamMemberDetails.PhoneNumber)}

          {renderFields("Company Name", teamMemberDetails.Company)}

          {renderFields("Role", teamMemberDetails.UserGroupPermissions)}

          {renderFields("Joining Date", teamMemberDetails.CreationTime?.slice(0,10))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamMemberDetails;

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/reports.module.css";
const GeneratedData = (props) => {
  const renderTableCell = (field) => {
    return field ? (
      <TableCell align="left" sx={Styles.tableRow}>
        {field}
      </TableCell>
    ) : field !== undefined ? (
      <TableCell align="left" sx={Styles.tableRow}>
        N/A
      </TableCell>
    ) : (
      <></>
    );
  };
  return (
    <Grid
      item
      className={classes["BoxData"]}
      ref={props.Myref}
      overflow={"hidden"}
      md={props.showTableOnly ? 11 : 10}
    >
      {props.editMode ? (
        <Grid
          item
          container
          direction={"row"}
          bgcolor={"rgb(252 249 249)"}
          paddingTop={2}
          paddingLeft={3}
          gap={10}
          xs={12}
        >
          <Grid item>
            <Grid item className={classes["ReportFields"]}>
              Name:
            </Grid>
            <Grid item className={classes["ReportFields"]}>
              {props.name}
            </Grid>
          </Grid>
          <Grid item>
            <Grid item className={classes["ReportFields"]}>
              Type:
            </Grid>
            <Grid item className={classes["ReportFields"]}>
              {props.type}
            </Grid>
          </Grid>
          <Grid item container xs={4}>
            <Grid item className={classes["ReportFields"]}>
              Description:
            </Grid>
            <Grid item className={classes["ReportFields"]}>
              {props.description}
            </Grid>
          </Grid>
          <Grid item>
            <Grid item className={classes["ReportFields"]}>
              Created Date:
            </Grid>
            <Grid item className={classes["ReportFields"]}>
              {props.creationTime}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <TableContainer>
        <Table
          aria-label="simple table"
          style={{ paddingLeft: props.showTableOnly ? 2 : 0 }}
        >
          <TableHead
            sx={{
              backgroundColor: "rgb(252 249 249)",
              "& td": { border: 0 },
            }}
          >
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  minWidth: 25,
                  fontFamily: "Muli",
                  fontSize: 13,
                  fontWeight: "bold",
                  color: "#101650",
                }}
              ></TableCell>
              {props.selectedColumns.map((column) => (
                <TableCell
                  align="left"
                  key={column.Id}
                  sx={{
                    minWidth: 250,
                    fontFamily: "Muli",
                    fontSize: 13,
                    fontWeight: "bold",
                    color: "#101650",
                  }}
                >
                  {column.Name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.returnedData.map((row, index) =>
              props.tableId === 8 ? (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={Styles.tableHeader}>
                    {index + 1}
                  </TableCell>
                  {renderTableCell(row.Code)}
                  {renderTableCell(row.Name)}
                  {renderTableCell(row.Country)}
                  {renderTableCell(row.Address)}
                  {renderTableCell(row.PhoneNumber)}
                  {renderTableCell(row.Email)}
                  {renderTableCell(row.Website)}
                  {renderTableCell(row.Trade)}
                  {renderTableCell(row.Type)}
                  {renderTableCell(row.Volume)}
                  {renderTableCell(row.DailyCapacity)}
                  {renderTableCell(row.RadiusCoverage)}
                  {renderTableCell(row.MinDaysToDeliver)}
                  {renderTableCell(row.NumOfEmloyees)}
                  {renderTableCell(row.NumOfBays)}
                  {renderTableCell(row.NumOfStorages)}
                  {renderTableCell(row.NumOfMachines)}
                  {renderTableCell(row.NumOfHandlings)}
                  {renderTableCell(row.CompanyName)}

                </TableRow>
              ) : props.tableId === 7 ? (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={Styles.tableHeader}>
                    {index + 1}
                  </TableCell>
                  {renderTableCell(row.CreatorName)}
                  {renderTableCell(row.InvitedContractor)}
                  {renderTableCell(row.PhaseName)}
                  {renderTableCell(row.SubprojectName)}
                  {renderTableCell(row.PhoneNumber)}
                  {renderTableCell(row.ContractName)}
                  {renderTableCell(row.ContractType)}
                  {renderTableCell(row.ContractSubType)}
                  {renderTableCell(row.StartDate)}
                  {renderTableCell(row.EndDate)}
                  {renderTableCell(row.ContractAbberviation)}
                  {renderTableCell(row.ContractDescription)}
                  {renderTableCell(row.ContractCode)}
                  {renderTableCell(row.ContractFile)}
                  {renderTableCell(row.ContractStatus)}
                  {renderTableCell(row.BillingType)}
                  {renderTableCell(row.BillingRetentionRatio)}
                  {renderTableCell(row.DefaultSheetSize)}
                  {renderTableCell(row.ContractDiscipline)}
                  {renderTableCell(row.ContractTrade)}
                  {renderTableCell(row.SoCreator)}
                  {renderTableCell(row.FactoryNum)}
                  {renderTableCell(row.MeasureSystem)}
                  {renderTableCell(row.UOM)}
                  {renderTableCell(row.BarStandard)}
                  {renderTableCell(row.BarGrade)}
                  {renderTableCell(row.BarCoating)}
                </TableRow>
              ) : (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={Styles.tableHeader}>
                    {index + 1}
                  </TableCell>

                  {props.tableId === 3 ||
                  props.tableId === 4 ||
                  props.tableId === 5 ||
                  props.tableId === 6
                    ? renderTableCell(row.Name)
                    : renderTableCell(row.CompanyName)}
                  {renderTableCell(row.CompanyEmail)}
                  {renderTableCell(row.Email)}

                  {renderTableCell(row.RegistrationNumber)}
                  {renderTableCell(row.Speciality)}
                  {renderTableCell(row.IndustryType)}
                  {renderTableCell(row.DisciplineCovered)}
                  {renderTableCell(row.Trade)}
                  {renderTableCell(row.EmployeesRange)}
                  {renderTableCell(row.Role)}
                  {renderTableCell(row.Type)}
                  {renderTableCell(row.Visibility)}
                  {renderTableCell(row.Country)}
                  {renderTableCell(row.Address)}
                  {props.tableId === 5 || props.tableId === 6
                    ? renderTableCell(row.PhoneNumber)
                    : renderTableCell(row.PersonalPhoneNumber)}
                  {renderTableCell(row.Code)}
                  {renderTableCell(row.ClientName)}
                  {renderTableCell(row.Creator)}
                  {renderTableCell(row.CompanyPhoneNumber)}
                  {props.tableId === 3 ? (
                    renderTableCell(row.PhoneNumber)
                  ) : (
                    <></>
                  )}
                  {props.tableId === 4 ? (
                    <></>
                  ) : props.tableId === 3 ? (
                    renderTableCell(row.CompanyName)
                  ) : props.tableId === 5 || props.tableId === 6 ? (
                    renderTableCell(row.PersonalName)
                  ) : (
                    renderTableCell(row.Name)
                  )}

                  {renderTableCell(row.Position)}
                  {renderTableCell(row.PersonalEmail)}
                  {props.tableId === 3 ||
                  props.tableId === 5 ||
                  props.tableId === 6 ? (
                    <></>
                  ) : (
                    renderTableCell(row.PhoneNumber)
                  )}
                  {props.tableId === 5 || props.tableId === 6 ? (
                    renderTableCell(row.PersonalPhoneNumber)
                  ) : (
                    <></>
                  )}
                  {renderTableCell(row.CreationTime?.slice(0, 10))}
                  {props.tableId === 4? (
                    renderTableCell(row.CreatorPlan)
                  ) : (
                    <></>
                  )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
const Styles = {
  tableHeader: {
    maxWidth: 25,
    fontFamily: "Muli",
    fontSize: 13,
    fontWeight: "bold",
    color: "#101650",
  },
  tableRow: {
    fontFamily: "Muli",
    fontSize: 13,
    fontWeight: "bold",
    color: "#0674b9",
  },
};
export default GeneratedData;

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";

import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/TipsModal.module.css";
import { TextField, IconButton } from "@mui/material";
import * as tipsServices from "../../Services/tips-services.proxy";
const addTip = tipsServices.addTip;
const getTipById = tipsServices.getTipById;
const updateTip = tipsServices.updateTip;

const style = {
  position: "absolute",
  top: "53%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  width: "70%",
  height: "50%",
  overflowY: "hidden",
  display: "block",
  border: "#fff",
  borderRadius: "5px",
};

const TipsModal = (props) => {
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [titleErrorMsg, setTitleErrorMsg] = useState("");

  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState(false);
  const [contentErrorMsg, setContentErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
    props.setTipId("");
    setTitle("");
    setContent("");
    setTitleError(false);
    setContentError(false);
  };
  useEffect(() => {
    if (!title || !content || titleError || contentError) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);
  const saveTheTip = () => {
    setLoading(true);
    addTip(title, content).then((x) => {
      props.setModify(!props.modify);
      setTimeout(() => {
        setLoading(false);
        handleClose();
      }, 1500);
    });
  };
  useEffect(() => {
    if (props.tipId) {
      getTipById(props.tipId).then((x) => {
        setTitle(x.Title);
        setContent(x.Content);
      });
    }
  }, [props.tipId]);
  
  const updateTheTip = () => {
    setLoading(true);
    updateTip(props.tipId, title, content).then((x) => {
      props.setModify(!props.modify);

      setTimeout(() => {
        setLoading(false);
        handleClose();
      }, 1500);
    });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            paddingTop={4}
            justifyContent={"center"}
            gap={1}
            alignItems={"center"}
          >
            <Grid item xs={11} className={classes["FieldLabel"]}>
              Title
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: false,
                  style: {
                    fontFamily: "Muli",
                    fontSize: "13px",
                  },
                }}
                label={title ? "" : "Title"}
                size="small"
                inputProps={{
                  maxLength: 150,
                }}
                value={title}
                error={titleError}
                helperText={titleError ? titleErrorMsg : <></>}
                onBlur={(e) => {
                  if (!title) {
                    setTitleError(true);
                    setTitleErrorMsg("Enter a Title");
                  }
                }}
                onChange={(e) => {
                  setTitle(e.target.value);
                  if (e.target.value === "") {
                    setTitleError(true);
                    setTitleErrorMsg("Enter a Title");
                  } else if (e.target.value.length > 35) {
                    setTitleError(true);
                    setTitleErrorMsg("Title must be less than 35 char");
                  } else {
                    setTitleError(false);
                  }
                  setTitle(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={11} className={classes["FieldLabel"]}>
              Content
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                InputLabelProps={{
                  shrink: false,
                  style: {
                    fontFamily: "Muli",
                    fontSize: "13px",
                  },
                }}
                label={content ? "" : "Content"}
                size="small"
                value={content}
                error={contentError}
                helperText={contentError ? contentErrorMsg : ""}
                onBlur={(e) => {
                  if (!content) {
                    setContentError(true);
                    setContentErrorMsg("Enter a Content");
                  }
                }}
                onChange={(e) => {
                  setContent(e.target.value);
                  if (!e.target.value) {
                    setContentError(true);
                    setContentErrorMsg("Enter a Content");
                  } else if (e.target.value.length > 255) {
                    setContentError(true);
                    setContentErrorMsg("Content must be less than 255 char");
                  } else {
                    setContentError(false);
                  }
                }}
              />
            </Grid>

            <Grid
              item
              container
              direction={"row-reverse"}
              md={12}
              paddingTop={4}
              paddingRight={5}
              paddingBottom={2}
            >
              <button
                onClick={() => {
                  if (!loading) {
                    if (props.tipId) updateTheTip();
                    else saveTheTip();
                  }
                }}
                disabled={isDisabled}
                className={classes["SaveButon"]}
              >
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                onClick={() => {
                  handleClose();
                }}
                className={classes["CancelButton"]}
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default TipsModal;

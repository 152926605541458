import React, { useState, useEffect } from "react";
import classes from "../../Assets/Styles/Shapes.module.css";
import { Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import { styled } from "@mui/system";
import Bin from "../../Assets/Images/Reports/Bin.png";
import Eye from "../../Assets/Images/Reports/Eye.png";
import { useNavigate } from "react-router-dom";
import Pen from "../../Assets/Images/Reports/Pen.png";
import Loading from "../../Components/Loading";
import DeleteModal from "./DeleteModal";
import * as shapesServices from "../../Services/shape-services.proxy";
const getShapeList = shapesServices.getShapeList;
const getShapeListFilterData = shapesServices.getShapeListFilterData;

const changeShapeVisibility = shapesServices.changeShapeVisibility;

const ShapesTable = (props) => {
  useEffect(() => {
    props.setHideDrawer(false);
  });
  const [searchText, setSearchText] = useState(null);
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [visibilties, setVisibilties] = useState([]);
  const [visisibility, setVisisibility] = useState(0);
  const [status, setStatus] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [shapeType, setShapeType] = useState(0);
  const [shapeTypes, setShapeTypes] = useState([]);
  const [shapeCatalogue, setShapeCatalogue] = useState(0);
  const [shapeCatalogues, setShapeCatalogues] = useState([]);
  const [numOfAngle, setNumOfAngle] = useState(0);
  const [numOfAngles, setNumOfAngles] = useState([]);
  const [numOfSide, setNumOfSide] = useState(0);
  const [numOfSides, setNumOfSides] = useState([]);
  const [dimension, setDimension] = useState(0);
  const [dimensions, setDimensions] = useState([]);
  const [rowData, setRowData] = useState();
  const [open, setOpen] = useState(false);

  const [modify, setModify] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const navigate = useNavigate();

  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "ACTION",
      sortable: false,
      headerAlign: "center",
      width: 250,

      renderCell: (params) => {
        return (
          <Grid
            container
            direction={"column"}
            gap={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              className={classes["IconPointer"]}
              onClick={(e) => {
                navigate(`/Manu/shapeBoard?id=${params.row.Id}`);
              }}
            >
              <img src={Pen} alt={"pen"}></img>
            </Grid>

            <Grid item className={classes["IconPointer"]}>
              <img
                src={Bin}
                alt={"pen"}
                onClick={(e) => {
                  setRowData(params.row);
                  setOpen(true);
                }}
              ></img>
            </Grid>
            <Grid item>
              <button
                className={
                  params.row?.VisibilityId === 1
                    ? classes["PublishButton"]
                    : classes["UnpublishButton"]
                }
                onClick={() => {
                  setRowData(params);
                  setLoadingButton(true);
                  changeShapeVisibility(
                    params.row.Id,
                    params.row.VisibilityId === 1 ? 2 : 1
                  ).then(() => {
                    setLoadingButton(false);
                    setModify(!modify);
                  });
                }}
              >
                {loadingButton && params.id === rowData.id
                  ? "Loading..."
                  : params.row?.VisibilityId === 1
                  ? "Publish"
                  : "Unpublish"}
              </button>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "ShapeFormed",
      headerName: "Shape Formed",
      width: 350,
      headerAlign: "center",
      resizeable: true,
      renderCell: (params) => (
        <img src={params.row.File?.URL} width={200} alt={"null"} />
      ),
    },

    {
      field: "Catalogue",
      headerName: "Shape Catalogue",
      width: 150,
    },
    {
      field: "Code",
      headerName: "Shape Code",
      width: 150,
    },
    {
      field: "subCode",
      headerName: "Shape Sub Code",
      width: 150,
    },
    {
      field: "Type",
      headerName: "Shape Type",
      width: 150,
    },
    {
      field: "Category",
      headerName: "Shape Category",
      width: 150,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "Catalogue",
      headerName: "Shape Catalogue",
      width: 150,
    },
    {
      field: "Dimension",
      headerName: "Dimension",
      width: 150,
    },
    {
      field: "NumofSides",
      headerName: "No.of Sides",
      width: 150,
    },
    {
      field: "NumOfAngles",
      headerName: "No.of Angles",
      width: 150,
    },
    {
      field: "VisibilityId",
      headerName: "Visibility",
      width: 150,
      renderCell: (params) => (
        <span>
          {params.row.VisibilityId === 1 ? "Not Published" : "Published"}
        </span>
      ),
    },
  ];
  const [shapesTable, setShapesTable] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setdataLength] = useState(0);

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 20,
      bottom: params.isLastVisible ? 0 : 20,
    };
  }, []);
  useEffect(() => {
    let apiTest = true;
    setLoading(true);
    getShapeList(listData).then((x) => {
      if (apiTest) {
        setShapesTable(x.Shapes);
        setdataLength(x.Total);
        setLoading(false);
      }
    });

    return () => {
      apiTest = false;
    };
  }, [
    pageSize,
    pageNumber,
    searchText,
    visisibility,
    status,
    numOfAngle,
    numOfSide,
    dimension,
    shapeCatalogue,
    shapeType,
    modify,
  ]);
  useEffect(() => {
    getShapeListFilterData().then((x) => {
      setVisibilties(x.Visibility);
      setStatuses(x.Statuses);
      setShapeTypes(x.Types);
      setShapeCatalogues(x.Catalogues);
      setDimensions(x.Dimension);
    });
  }, []);

  const [loading, setLoading] = useState(true);
  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    VisibilityId: visisibility === 0 ? null : visisibility,
    StatusId: status === 0 ? null : status,
    CatelogueId: shapeCatalogue === 0 ? null : shapeCatalogue,
    DimensionId: dimension === 0 ? null : dimension,
    TypeId: shapeType === 0 ? null : shapeType,
  };

  return (
    <Grid container paddingTop={9}>
      <Grid item container direction={"row"} marginLeft={4}>
        <Grid item className={classes["page-title"]} md={3} xs={12}>
          Universal Shape Library
        </Grid>
        <Grid
          item
          md={8}
          paddingLeft={{ md: 7, xs: 0 }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <TextField
            size="small"
            fullWidth
            onChange={inputHandler}
            placeholder="Search…"
            InputProps={{
              startAdornment: (
                <SearchIcon
                  fontSize="small"
                  htmlColor={"#707070"}
                  style={{ marginLeft: 15 }}
                />
              ),
              style: { fontFamily: "Muli" },
            }}
            sx={{
              fontFamily: "Muli",
              backgroundColor: "white",
              borderRadius: 1,

              width: {
                xs: 1,
                sm: 483,
              },
            }}
          />
        </Grid>
        <Grid item md={1}>
          <button
            style={{ width: "fit-content", padding: 10 }}
            onClick={() => {
              navigate("/Manu/shapeBoard");
            }}
            className={classes["AddShape"]}
          >
            New Shape
          </button>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction={"row-reverse"}
        paddingRight={5}
        paddingTop={3}
      >
        <Grid item>
          <CustomSelect
            value={dimension}
            onChange={(option) => {
              setDimension(option);
            }}
          >
            <StyledOption value={0} disabled>
              Dimension
            </StyledOption>

            {dimensions.map((s) => (
              <StyledOption key={s.Id} value={s.Id}>
                {s.Name}
              </StyledOption>
            ))}
          </CustomSelect>
        </Grid>

        <Grid item>
          <CustomSelect
            value={shapeCatalogue}
            onChange={(option) => {
              setShapeCatalogue(option);
            }}
          >
            <StyledOption value={0} disabled>
              Shape Catalogue
            </StyledOption>

            {shapeCatalogues.map((s) => (
              <StyledOption key={s.Id} value={s.Id}>
                {s.Name}
              </StyledOption>
            ))}
          </CustomSelect>
        </Grid>
        <Grid item>
          <CustomSelect
            value={shapeType}
            onChange={(option) => {
              setShapeType(option);
            }}
          >
            <StyledOption value={0} disabled>
              Shape Type
            </StyledOption>

            {shapeTypes.map((s) => (
              <StyledOption key={s.Id} value={s.Id}>
                {s.Name}
              </StyledOption>
            ))}
          </CustomSelect>
        </Grid>
        <Grid item>
          <CustomSelect
            value={status}
            onChange={(option) => {
              setStatus(option);
            }}
          >
            <StyledOption value={0} disabled>
              Status
            </StyledOption>

            {statuses.map((s) => (
              <StyledOption key={s.Id} value={s.Id}>
                {s.Name}
              </StyledOption>
            ))}
          </CustomSelect>
        </Grid>
        <Grid item>
          <CustomSelect
            value={visisibility}
            onChange={(option) => {
              setVisisibility(option);
            }}
          >
            <StyledOption value={0} disabled>
              Visibility
            </StyledOption>
            <StyledOption value={1}>Not Published</StyledOption>
            <StyledOption value={2}>Published</StyledOption>
          </CustomSelect>
        </Grid>
        {dimension ||
        numOfAngle ||
        numOfSide ||
        shapeCatalogue ||
        shapeType ||
        status ||
        visisibility ? (
          <Grid
            className={classes["clearFilter"]}
            onClick={() => {
              setDimension(0);
              setNumOfAngle(0);
              setNumOfSide(0);
              setShapeCatalogue(0);
              setShapeType(0);
              setStatus(0);
              setVisisibility(0);
            }}
          >
            Clear Filters
          </Grid>
        ) : (
          <></>
        )}
      </Grid>

      <Grid
        item
        width="100%"
        height={700}
        bgcolor={"rgb(252 249 249)"}
        paddingLeft={1}
      >
        <DataGrid
          style={{ paddingTop: 30, paddingLeft: 10 }}
          initialState={{
            pagination: {
              pageSize: 25,
            },
          }}
          pagination
          paginationMode="server"
          rows={shapesTable}
          columns={columns}
          headerHeight={40}
          // rowHeight={"auto"}
          getRowHeight={({ id, densityFactor }) => {
            return 200;
          }}
          getRowSpacing={getRowSpacing}
          showColumnRightBorder
          sx={{
            [`& .${gridClasses.row}`]: {
              border: 0,
              boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
              color: "#0674b9",
              maxHeight: "none !important",
              overflow: "visible",

              bgcolor: (theme) =>
                theme.palette.mode === "light" ? "#fff" : grey[500],
            },
            [`& .${gridClasses.cell}`]: {
              overflow: "visible",
              whiteSpace: "pre-wrap",
            },
            [`& .${gridClasses.columnHeader}`]: {
              bgcolor: "transparent",
              color: "rgb(17 24 84)",
              border: 0,
            },
            [`& .${gridClasses.columnHeaders}`]: {
              border: 0,
            },
            [`& .${gridClasses.columnHeaderTitle}`]: {
              fontFamily: "Muli",
              fontWeight: "bold",
            },

            [`& .${gridClasses.footerContainer}`]: {
              bgcolor: "transparent",
              minHeight: "30px",
              border: 0,
            },
            fontFamily: "Muli",
            fontSize: "13px",
            border: 0,
          }}
          disableColumnFilter
          getRowId={(row) => row.Id}
          rowCount={dataLength}
          onPageChange={(newPage) => {
            if (pageSize > dataLength) {
              setPageNumber(1);
            } else setPageNumber(newPage + 1);
          }}
          onPageSizeChange={(newPage) => {
            setPageSize(newPage);
          }}
          pageSize={pageSize}
          loading={loading}
          components={{
            NoRowsOverlay: () => (
              <Stack className={classes["EmptyMessage"]}>
                No Shapes To Display
              </Stack>
            ),
            LoadingOverlay: () => (
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Loading />
              </Grid>
            ),
          }}
        />
      </Grid>
      <DeleteModal
        rowData={rowData}
        open={open}
        setOpen={setOpen}
        modify={modify}
        setModify={setModify}
      />
    </Grid>
  );
};
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};
const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 12px;
      box-sizing: border-box;
      min-height: 32px;
      width: 9rem;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
      border-radius: 0.75em;
      margin: 0.5em;
      padding: 10px;
      text-align: left;
      line-height: 1.5;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    
      &:hover {
        background: ${theme.palette.mode === "dark" ? "" : grey[100]};
        border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &.${selectUnstyledClasses.focusVisible} {
        outline: 3px solid ${
          theme.palette.mode === "dark" ? blue[600] : blue[100]
        };
      }
    
      &.${selectUnstyledClasses.expanded} {
        &::after {
          content: '▴';
        }
      }
    
      &::after {
        content: '▾';
        float: right;
      }
      `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 0.875rem;
      box-sizing: border-box;
      padding: 5px;
      margin: 10px 0;
      max-height:250px !important;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
      border-radius: 0.75em;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      overflow: auto;
      outline: 0px;
      `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 0.45em;
      cursor: default;
      
      &:last-of-type {
        border-bottom: none;
      }
    
      &.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.highlighted} {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
    
      &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
      `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
export default ShapesTable;

import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import classes from "../../../Assets/Styles/SaasUser.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import * as SubscriptionService from "../../../Services/subscription-services.proxy";
import { styled } from "@mui/system";
import Loading from "../../../Components/Loading";

const getSubscriptionsManagedFilterInputData =
  SubscriptionService.getSubscriptionsManagedFilterInputData;
const getSubscritionsManaged = SubscriptionService.getSubscritionsManaged;
const activatePlan = SubscriptionService.activatePlan;
const deactivatePlan = SubscriptionService.deactivatePlan;

const style = {
  plan: {
    fontfamily: "Muli",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 1.31,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#101650",
    border: 0,
    textTransform: "none",
  },
  activePlan: {
    fontfamily: "Muli",
    fontSize: 16,
    fontWeight: "bolder",
    lineHeight: 1.31,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#101650",
    textTransform: "none",
  },
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 13px;
    box-sizing: border-box;
    min-height: 32px;
    width: 9rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    margin: 0.5em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${
        theme.palette.mode === "dark" ? blue[600] : blue[100]
      };
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    width: 140px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${
        theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${
        theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

const Table = (props) => {
  const navigate = useNavigate();

  const [planType, setPlanType] = useState(null);
  const [planStatusChanging, setPlanStatusChanging] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getSubscriptionsManagedFilterInputData().then((x) => {
      setStatuses(x.data.Statuses);
      setProducts(x.data.Products);
    });
  }, []);

  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "ACTION",
      sortable: false,
      width: 150,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <button
            onClick={() => {
              setPlanStatusChanging(true);
              if (params.row.Status === "Unsubscribed") {
                VerifyPayment(params.row.Id);
                props.setRowData(params);
              } else if (
                params.row.Status === "Subscribed" ||
                params.row.Status === "Scheduled"
              ) {
                props.setRowData(params);
                deactivateCustomPlan(params.row.Id);
              } else if (params.row.Status === "Deactivated") {
                props.setOpenModal(true);
                props.setRowData(params);
                setPlanStatusChanging(false);
              }
              props.setModify(!props.modify);
            }}
            className={classes["VerifyButton"]}
          >
            {planStatusChanging && params.row.Id === props.rowData.row.Id ? (
              "Loading..."
            ) : params.row.Status === "Unsubscribed" ? (
              "Verify Payment"
            ) : params.row.Status === "Subscribed" ||
              params.row.Status === "Scheduled" ? (
              "Deactivate"
            ) : params.row.Status === "Deactivated" ? (
              "Renew"
            ) : (
              <></>
            )}
          </button>
        );
      },
    },
    {
      field: "PlanName",
      headerName: "Plan Name",
      width: 150,
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 150,
    },

    {
      field: "Email",
      headerName: "Email",
      width: 230,
    },

    {
      field: "SubscriptionType",
      headerName: "Account Bundle",
      width: 150,
    },
    {
      field: "SubscriptionBundle",
      headerName: "Subscription Bundle",
      width: 150,
    },
    {
      field: "PlanType",
      headerName: "Plan Type",
      width: 200,
      sortable: false,
    },
    {
      field: "BillingPlan",
      headerName: "Billing Plan",
      width: 100,
      sortable: false,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      sortable: false,
    },
    {
      field: "Price",
      headerName: "Price",
      width: 130,
      sortable: false,
    },
    {
      field: "PaymentReferenceNumber",
      headerName: "Payment #",
      width: 150,
      sortable: false,
    },
    {
      field: "NumOfFactories",
      headerName: "Number of Factories",
      width: 150,
      sortable: false,
      hide:
        props.active == 9 || props.active == 10 || props.active == 11
          ? false
          : true,
    },
    {
      field: "FactoryVolume",
      headerName: "Factory Volume",
      width: 150,
      sortable: false,
      hide:
        props.active == 9 || props.active == 10 || props.active == 11
          ? false
          : true,
    },
    {
      field: "StartDate",
      headerName: "Start Date",
      width: 280,
      sortable: false,
    },
    {
      field: "EndDate",
      headerName: "End Date",
      width: 280,
      sortable: false,
    },
  ];
  const [msTable, setMsTable] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setdataLength] = useState(0);
  const [status, setStatus] = useState(0);
  const [modify, setModify] = useState(true);
  const [loading, setLoading] = useState(false);

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 20,
      bottom: params.isLastVisible ? 0 : 20,
    };
  }, []);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: props.searchText,
    StatusId: status === 0 ? null : status,
    ProductId: planType,
  };

  const VerifyPayment = (managedSubscriptionId) => {
    activatePlan(managedSubscriptionId).then((x) => {
      props.setModify(!props.modify);
      setModify(!modify)
      setPlanStatusChanging(false);
    });
  };

  const deactivateCustomPlan = (managedSubscriptionId) => {
    deactivatePlan(managedSubscriptionId).then((x) => {
      setModify(!modify);
      props.setModify(!props.modify);
      setPlanStatusChanging(false);
    });
  };
  

  useEffect(() => {
    let apiTest = true;
    setLoading(true);
    getSubscritionsManaged(listData).then((x) => {
      if (apiTest) {
        setMsTable(x.Subscriptions);
        setdataLength(x.Total);
        setLoading(false);
      }
    });

    return () => {
      apiTest = false;
    };
  }, [
    pageSize,
    pageNumber,
    status,
    props.modify,
    modify,
    props.searchText,
    planType,
  ]);

  return (
    <Grid container>
      <Grid container item direction={"row"}>
        {products.map((product, index) => (
          <Grid
            key={index}
            item
            xs={product.Id === null ? 1.5 : 1.7}
            sx={{
              borderBottom:
                props.active === product.Id ? "solid 2px #e1e319" : 0,
            }}
            marginLeft={3}
            paddingLeft="1%"
            onClick={() => {
              props.setActive(product.Id);
              setPlanType(product.Id);
            }}
          >
            <Button
              sx={props.active === product.Id ? style.activePlan : style.plan}
            >
              {product.Name}
            </Button>
          </Grid>
        ))}
        <Grid
          item
          xs={4}
          container
          direction={"row-reverse"}
          gap={2}
          marginLeft={{ xs: 3, md: 0 }}
        >
          <Grid item paddingTop={1}>
            <button
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#0674b9";
                e.target.style.color = "#fff";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
                e.target.style.color = "#0674b9";
              }}
              onClick={() => {
                props.setOpenModal(true);
              }}
              className={classes["GoBack"]}
            >
              ADD
            </button>
          </Grid>
          <Grid item>
            <CustomSelect
              value={status}
              onChange={(option) => {
                setStatus(option);
              }}
            >
              <StyledOption value={0} disabled>
                Status
              </StyledOption>

              {statuses.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {status ? (
            <Grid
              className={classes["clearFilter"]}
              onClick={() => {
                setStatus(0);
              }}
            >
              Clear Filters
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
        {msTable.length > 0 ? (
          <DataGrid
            style={{ paddingTop: 30, paddingLeft: 10 }}
            initialState={{
              pagination: {
                pageSize: 25,
              },
            }}
            pagination
            paginationMode="server"
            rows={msTable}
            columns={columns}
            headerHeight={40}
            rowHeight={100}
            getRowSpacing={getRowSpacing}
            showColumnRightBorder
            sx={{
              [`& .${gridClasses.row}`]: {
                border: 0,

                boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
                color: "#0674b9",
                overflow: "auto",

                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? "#fff" : grey[500],
              },

              [`& .${gridClasses.columnHeader}`]: {
                bgcolor: "transparent",
                color: "rgb(17 24 84)",
                border: 0,
              },
              [`& .${gridClasses.columnHeaders}`]: {
                border: 0,
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontFamily: "Muli",
                fontWeight: "bold",
              },

              [`& .${gridClasses.footerContainer}`]: {
                bgcolor: "transparent",
                minHeight: "30px",
                border: 0,
              },
              fontFamily: "Muli",
              fontSize: "13px",
              border: 0,
            }}
            disableColumnFilter
            getRowId={(row) => row.Id}
            rowCount={dataLength}
            onPageChange={(newPage) => {
              if (pageSize > dataLength) {
                setPageNumber(1);
              } else setPageNumber(newPage + 1);
            }}
            onPageSizeChange={(newPage) => {
              setPageSize(newPage);
            }}
            pageSize={pageSize}
            loading={loading}
            components={{
              NoRowsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  Please Subscribe to access Main Contractor
                </Stack>
              ),
            }}
          />
        ) : (
          <Stack className={classes["EmptyMessage"]}>
            No Managed Subscriptions To Display
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default Table;

import React, { useState, useEffect } from "react";
import { Grid, Modal, Box, TextField } from "@mui/material";
import classes from "./Notifications.module.css";
import MenuItem from "@mui/material/MenuItem";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as notificationServices from "../../Services/notification-services.proxy";
import moment from "moment";
const getNotificationsInputData =
  notificationServices.getNotificationsInputData;
const createNotifications = notificationServices.createNotifications;
const getNotificationsById = notificationServices.getNotificationsById;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  width: "55%",
  overflowY: "scroll",
};

const AddModal = (props) => {
  const [open, setOpen] = React.useState(false);

  const [title, setTitle] = useState(null);
  const [titleError, setTitleError] = useState(false);
  const [titleErrorMsg, setTitleErrorMsg] = useState(false);

  const [contentCategory, setContentCategory] = useState("");
  const [contentCategoryError, setContentCategoryError] = useState(false);
  const [contentCategories, setContentCategories] = useState([]);

  const [content, setContent] = useState(null);
  const [contentError, setContentError] = useState(false);
  const [contentErrorMsg, setContentErrorMsg] = useState(null);

  const [recipient, setRecipient] = useState("");
  const [recipientError, setRecipientError] = useState(false);
  const [recipients, setRecipients] = useState([]);

  const [country, setCountry] = useState([]);
  const [countries, setcountries] = useState([]);

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [dateTime, setDateTime] = useState(new Date());
  const [loadingButton, setLoadingButton] = useState(false);
  const [showContentCategoryOther, setShowContentCategoryOther] =
    useState(false);
  const [contentCategoryOther, setContentCategoryOther] = useState("");
  const [contentCategoryOtherError, setContentCategoryOtherError] =
    useState(false);

  const [showSpecificGroup, setShowSpecificGroup] = useState(false);
  const [showDateTime, setShowDateTime] = useState(false);
  const [triggerImmediatly, setTriggerImmediatly] = useState(false);

  const [specificGroupValue, setSpecificGroupValue] = useState([]);
  const [specificGroupValueError, setSpecificGroupValueError] = useState(false);

  const [specificGroups, setSpecificGroups] = useState([]);
  const [creationTime, setCreationTime] = useState(null);
  const [TrigeredAt, setTrigeredAt] = useState(null);
  const [allDisabled, setAllDisabled] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const handleDateChange = (newValue) => {
    setDate(newValue);
    setDateTime(newValue);
    dateTime.setHours(time.getHours());
    dateTime.setMinutes(time.getMinutes());
  };

  const handleTimeChange = (newValue) => {
    setTime(newValue);
    setDateTime(date);
    dateTime.setHours(newValue.getHours());
    dateTime.setMinutes(newValue.getMinutes());
  };
  const [isDisabled, setIsDisabled] = useState(true);

  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(false);
  };

  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const handleClose = () => {
    setTitle("");
    setTitleError(false);
    setContentCategory("");
    setContentCategoryError(false);
    setContent("");
    setContentError(false);
    setRecipient("");
    setRecipientError(false);
    setCountry([]);
    setOpen(false);
    setDate(new Date());
    setTime(new Date());
    setShowSpecificGroup(false);
    setSpecificGroupValue([]);
    setSpecificGroupValueError(false);
    setShowContentCategoryOther(false);
    setTriggerImmediatly(false);
    setShowDateTime(false);
    setTrigeredAt(null);
    setCreationTime(null);
    setAllDisabled(false);
    props.setRowData(null);
    setDateError(false);
    setTimeError(false);
  };

  useEffect(() => {
    if (
      !title ||
      !contentCategory ||
      !content ||
      !recipient ||
      titleError ||
      contentCategoryError ||
      contentError ||
      recipientError ||
      contentCategoryOtherError ||
      timeError ||
      dateError ||
      (!triggerImmediatly && !showDateTime)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  const data = {
    Title: title,
    Content: content,
    CategoryId: contentCategory,
    CategoryOthers: contentCategoryOther ? contentCategoryOther : null,
    RecpientId: recipient,
    RecpientGroupIds: specificGroupValue,
    CountriesIds: country,
    TriggerImmediately: triggerImmediatly,
    TriggeredAt: triggerImmediatly ? null : dateTime,
  };
  const Send = () => {
    setLoadingButton(true);
    createNotifications(data).then((x) => {
      props.setModify(!props.modify);
      if (triggerImmediatly) {
        props.setValue("2");
      } else {
        props.setValue("1");
      }
      setLoadingButton(false);
      handleClose();
    });
  };
  useEffect(() => {
    getNotificationsInputData().then((x) => {
      setContentCategories(x.ContentCategories);
      setcountries(x.Countries);
      setRecipients(x.Recipients);
      setSpecificGroups(
        x.Recipients.find((option) => option.Name === "Specific group").Groups
      );
    });
  }, []);
  useEffect(() => {
    if (props.rowData) {
      setAllDisabled(true);
      getNotificationsById(props.rowData.row?.Id).then((x) => {
        setTitle(x.Title);
        setContent(x.Content);
        setContentCategory(x.CategoryId);
        setRecipient(x.RecpientId);
        if (x.RecpientId === 2) {
          setShowSpecificGroup(true);
        }
        setSpecificGroupValue(x.RecpientGroupIds);
        setContentCategoryOther(x.CategoryOthers);
        if (x.CategoryOthers) {
          setShowContentCategoryOther(true);
        }
        setCountry(x.CountriesIds);
        setTrigeredAt(x.TriggeredAt);
        setCreationTime(x.CreationTIme);
      });
    }
  }, [props.rowData]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Grid item>
            <Grid sx={style} height={{ md: "90%", sm: "65%" }}>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                paddingTop={5}
                gap={1}
              >
                <Grid item xs={11}>
                  <span className={classes["field-label"]}>*Title</span>
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    fullWidth
                    value={title}
                    disabled={allDisabled}
                    error={titleError}
                    helperText={titleError ? titleErrorMsg : ""}
                    id="outlined-name"
                    size="small"
                    InputProps={{ style: { height: "49px" } }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setTitleError(true);
                        setTitleErrorMsg("Enter a title");
                      }
                    }}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setTitleError(true);
                        setTitleErrorMsg("Enter a title");
                      } else if (e.target.value.length > 35) {
                        setTitleError(true);
                        setTitleErrorMsg(
                          "Title must be less than 35 characters"
                        );
                      } else {
                        setTitleError(false);
                      }

                      setTitle(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <span className={classes["field-label"]}>
                    *Content Category
                  </span>
                  <Grid item xs={12}>
                    <TextField
                      disabled={allDisabled}
                      select
                      fullWidth
                      error={contentCategoryError}
                      MenuProps={{ disableScrollLock: true }}
                      helperText={contentError ? "Choose a category" : ""}
                      value={contentCategory}
                      onBlur={(e) => {
                        if (!e.target.value) setContentCategoryError(true);
                      }}
                      onChange={(e) => {
                        setContentCategoryError(false);
                        setContentCategory(e.target.value);
                        if (
                          contentCategories.find(
                            (option) => option.Name === "Others"
                          ).Id === e.target.value
                        ) {
                          setShowContentCategoryOther(true);
                        } else {
                          setShowContentCategoryOther(false);
                        }
                      }}
                    >
                      {contentCategories.map((option) => (
                        <MenuItem
                          value={option.Id}
                          key={option.Id}
                          style={{ display: "block" }}
                        >
                          {option.Name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                {showContentCategoryOther ? (
                  <Grid item xs={11}>
                    <span className={classes["field-label"]}>Others</span>
                    <Grid item xs={12}>
                      <TextField
                        disabled={allDisabled}
                        fullWidth
                        value={contentCategoryOther}
                        error={contentCategoryOtherError}
                        helperText={
                          contentCategoryOtherError ? "Enter a category." : ""
                        }
                        id="outlined-name"
                        size="small"
                        InputProps={{ style: { height: "49px" } }}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            setContentCategoryOtherError(true);
                          }
                        }}
                        onChange={(e) => {
                          if (!e.target.value) {
                            setContentCategoryOtherError(true);
                          } else if (e.target.value.length > 35) {
                            setContentCategoryOtherError(true);
                          } else {
                            setContentCategoryOtherError(false);
                          }

                          setContentCategoryOther(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={11}>
                  <span className={classes["field-label"]}>*Content</span>
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    disabled={allDisabled}
                    fullWidth
                    multiline
                    rows={3}
                    value={content}
                    error={contentError}
                    helperText={contentError ? contentErrorMsg : ""}
                    id="outlined-name"
                    size="small"
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setContentError(true);
                        setContentErrorMsg("Enter a content");
                      }
                    }}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setContentError(true);
                        setContentErrorMsg("Enter a content");
                      } else if (e.target.value.length > 255) {
                        setContentError(true);
                        setContentErrorMsg(
                          "Content must be less than 255 characters"
                        );
                      } else {
                        setContentError(false);
                      }

                      setContent(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <span className={classes["field-label"]}>*Recipient</span>
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    disabled={allDisabled}
                    select
                    fullWidth
                    error={recipientError}
                    MenuProps={{ disableScrollLock: true }}
                    InputProps={{ style: { height: "49px" } }}
                    helperText={recipientError ? "Choose a recipient" : ""}
                    value={recipient}
                    onBlur={(e) => {
                      if (!e.target.value) setRecipientError(true);
                    }}
                    onChange={(e) => {
                      setRecipient(e.target.value);
                      if (!e.target.value) setRecipientError(true);
                      else if (
                        e.target.value ===
                        recipients.find((x) => x.Name === "Specific group").Id
                      ) {
                        setShowSpecificGroup(true);
                        setSpecificGroupValueError(true);
                      } else {
                        setRecipientError(false);
                        setShowSpecificGroup(false);
                      }
                    }}
                  >
                    {recipients.map((option) => (
                      <MenuItem
                        value={option.Id}
                        key={option.Id}
                        style={{ display: "block" }}
                      >
                        {option.Name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {showSpecificGroup ? (
                  <Grid
                    item
                    paddingLeft={5}
                    container
                    className={classes.SpecificGroup}
                    gap={2}
                    paddingTop={2}
                    paddingBottom={2}
                  >
                    {specificGroups.map((option) => (
                      <>
                        <Grid
                          item
                          className={
                            specificGroupValue.find((x) => x === option.Id)
                              ? classes["FullChecked"]
                              : classes["Not-checked"]
                          }
                          onMouseOver={(e) =>
                            (e.target.style.cursor = "pointer")
                          }
                          onClick={(e) => {
                            if (!allDisabled) {
                              if (
                                specificGroupValue.find((x) => x === option.Id)
                              ) {
                                var filtered = specificGroupValue.filter(
                                  function (value) {
                                    return value !== option.Id;
                                  }
                                );
                                setSpecificGroupValue(filtered);
                              } else {
                                let temp = option.Id;
                                let arr = specificGroupValue;
                                arr.push(temp);
                                setSpecificGroupValue(arr);
                              }
                              forceUpdate();
                            }
                          }}
                        ></Grid>
                        <Grid item>{option.Name}</Grid>
                      </>
                    ))}
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={11}>
                  <span className={classes["field-label"]}>Country</span>
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    disabled={allDisabled}
                    select
                    fullWidth
                    MenuProps={{ disableScrollLock: true }}
                    InputProps={{ style: { height: "49px" } }}
                    SelectProps={{ multiple: true }}
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  >
                    {countries.map((option) => (
                      <MenuItem
                        value={option.Id}
                        key={option.Id}
                        style={{ display: "block" }}
                      >
                        {option.Name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {props.rowData ? (
                  <Grid container direction={"row"} paddingLeft={4}>
                    <Grid
                      item
                      xs={6}
                      fontFamily={"Muli"}
                      fontSize={15}
                      color={"#707070"}
                      container
                      direction={"row"}
                    >
                      Creation Time:
                      <Grid item color="#101650">
                        {new Date(creationTime).toString()?.slice(0, 24)}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      fontFamily={"Muli"}
                      fontSize={15}
                      color={"#707070"}
                      container
                      direction={"row"}
                    >
                      Triggered At:
                      <Grid item color="#101650">
                        {new Date(TrigeredAt).toString()?.slice(0, 24)}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    direction={"row"}
                    paddingLeft={4.2}
                    paddingTop={2}
                  >
                    <Grid item container direction={"row"} xs={4} gap={2}>
                      <Grid
                        item
                        className={
                          triggerImmediatly
                            ? classes["FullChecked"]
                            : classes["Not-checked"]
                        }
                        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                        onClick={(e) => {
                          setTriggerImmediatly(!triggerImmediatly);
                          setShowDateTime(false);
                          forceUpdate();
                        }}
                      ></Grid>
                      <Grid item className={classes["field-label"]}>
                        Send Now
                      </Grid>
                    </Grid>
                    <Grid item container direction={"row"} xs={4} gap={2}>
                      <Grid
                        item
                        className={
                          showDateTime
                            ? classes["FullChecked"]
                            : classes["Not-checked"]
                        }
                        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                        onClick={(e) => {
                          setShowDateTime(!showDateTime);
                          setTriggerImmediatly(false);
                          forceUpdate();
                        }}
                      ></Grid>
                      <Grid item className={classes["field-label"]}>
                        Schedule
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {showDateTime ? (
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    marginTop={2}
                    rowSpacing={2}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid item md={5.5} xs={11}>
                        <DesktopDatePicker
                          label="Date"
                          inputFormat="MM/dd/yyyy"
                          value={date}
                          onChange={handleDateChange}
                          disablePast
                          onError={() => {
                            setDateError(true);
                          }}
                          onAccept={() => {
                            setDateError(false);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} disabled error={dateError} />
                          )}
                        />
                      </Grid>
                      <Grid item md={5.5} xs={11}>
                        <TimePicker
                          label="Time"
                          value={time}
                          onChange={handleTimeChange}
                          onError={() => {
                            setTimeError(true);
                          }}
                          onAccept={() => {
                            setTimeError(false);
                            setDateError(false);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} disabled />
                          )}
                        />
                      </Grid>
                    </LocalizationProvider>
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid
                  item
                  container
                  justifyContent={"flex-end"}
                  direction="row"
                  alignItems={"flex-end"}
                  paddingRight={5}
                  paddingTop={2}
                  marginBottom={5}
                >
                  <Grid item paddingRight={2}>
                    <button
                      className={classes.exitModal}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#f5f5f5";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#fff";
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </Grid>
                  <Grid item>
                    <button
                      disabled={isDisabled}
                      className={classes.SaveModal}
                      style={{
                        backgroundColor: isDisabled ? "#bcbcbc" : "#0b5cab",
                        fontFamily: "Muli",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        Send();
                      }}
                    >
                      {loadingButton ? "Sending..." : "Send"}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default AddModal;

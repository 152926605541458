import * as constFile from "../cmsConst";
import axios from "axios";

const NOTIFICATION_INPUT_DATA = constFile.NOTIFICATION_INPUT_DATA;
export const getNotificationsInputData = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + NOTIFICATION_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const CREATE_NOTIFICATION = constFile.CREATE_NOTIFICATION;
export const createNotifications = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_NOTIFICATION}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const GET_NOTIFICATION_LIST = constFile.GET_NOTIFICATION_LIST;
export const getNotificationList = (data) => {
  const token = localStorage.getItem("accessToken");
  // data.Search = null;
  return axios
    .post(`${process.env.REACT_APP_URL + GET_NOTIFICATION_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
const DELETE_NOTIFICATION = constFile.DELETE_NOTIFICATION;
export const deleteNoti = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_NOTIFICATION}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { Id: Id },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => { });
};
const GET_NOTIFICATION_BY_ID = constFile.GET_NOTIFICATION_BY_ID;
export const getNotificationsById = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_NOTIFICATION_BY_ID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id: Id,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};

import React, { useState, useEffect } from "react";
import classes from "../../Assets/Styles/SaasUser.module.css";

import { Grid, Box, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
// import Table from "./Table";
import CreateReport from "./CreateReport";
import ReportsView from "./ReportsView";
import Table from "./Table";
const Reports = (props) => {
  useEffect(() => {
    props.setHideDrawer(false);
  });
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [searchText, setSearchText] = useState(null);
  const [viewDetails, setViewDetails] = useState(false);
  const [viewAddReport, setViewAddReport] = useState(false);

  const [rowData, setRowData] = useState(false);
  const [AvailableData, setAvailableData] = useState(true);
  const [showTableOnly, setShowTableOnly] = useState(false);

  const [reportType, setReportType] = useState();
  const [reportId, setReportId] = useState();
  const [reportIdTable, setReportIdTable] = useState();

  return (
    <Grid container paddingTop={9} md={12}>
      <Grid item container direction={"row"} marginLeft={4}>
        <Grid
          item
          className={classes["page-title"]}
          md={viewAddReport ? 1 : 3}
          xs={12}
        >
          Reports
        </Grid>
        {viewAddReport ? (
          <Grid
            item
            className={classes["IconBack"]}
            md={2}
            xs={12}
            paddingTop={0.5}
            onClick={() => {
              setViewAddReport(false);
              setReportIdTable(false);
              setAvailableData(true);
              setViewAddReport(false);
              setShowTableOnly(false)
            }}
          >
            <ArrowCircleLeftIcon fontSize={"small"} htmlColor={"#e1e319"} />
          </Grid>
        ) : (
          <></>
        )}

        {AvailableData ? (
          <Grid
            md={9}
            paddingLeft={{ md: 7, xs: 0 }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <TextField
              size="small"
              fullWidth
              onChange={inputHandler}
              placeholder="Search…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ marginLeft: 15 }}
                  />
                ),
                style: { fontFamily: "Muli" },
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,

                width: {
                  xs: 1,
                  sm: 483,
                },
              }}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>

      <Grid item container paddingTop={5} md={12}>
        {AvailableData ? (
          <Table
            setViewDetails={setViewDetails}
            setRowData={setRowData}
            searchText={searchText}
            setAvailableData={setAvailableData}
            setReportIdTable={setReportIdTable}
            setViewAddReport={setViewAddReport}
            setShowTableOnly={setShowTableOnly}
          />
        ) : viewAddReport ? (
          <CreateReport
            reportType={reportType}
            reportId={reportId}
            reportIdTable={reportIdTable}
            setReportIdTable={setReportIdTable}
            setReportId={setReportId}
            showTableOnly={showTableOnly}
            setViewAddReport={setViewAddReport}
            setShowTableOnly={setShowTableOnly}
            setAvailableData={setAvailableData}
          />
        ) : (
          <ReportsView
            setReportType={setReportType}
            setViewAddReport={setViewAddReport}
            setReportId={setReportId}
            AvailableData={AvailableData}
          />
        )}
      </Grid>
      {/* <Grid item container paddingTop={5} md={12}>
        {viewDetails ? (
          <MainContractorDetails
            setViewDetails={setViewDetails}
            rowData={rowData}
          />
        ) : (
          <Table
            setViewDetails={setViewDetails}
            setRowData={setRowData}
            searchText={searchText}
          />
        )}
      </Grid> */}
    </Grid>
  );
};

export default Reports;

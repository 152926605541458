import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Excalidraw,
  exportToCanvas,
  exportToSvg,
  exportToBlob,
} from "@excalidraw/excalidraw";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/Shapes.module.css";
import { useNavigate } from "react-router-dom";
import "../../../App.css";

const resolvablePromise = () => {
  let resolve;
  let reject;
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  promise.resolve = resolve;
  promise.reject = reject;
  return promise;
};

export default function WhiteBoard(props) {
  const navigate = useNavigate();
  const sceneDataNew = {
    elements: props.dataScene.elements,
    appState: {
      viewBackgroundColor: "#ffffff",
      // isLibraryMenuDocked: true,
      // isLibraryOpen: true,
      activeTool: {
        customType: null,
        lastActiveToolBeforeEraser: null,
        locked: false,
        type: "selection",
        currentChartType: "bar",
        currentItemBackgroundColor: "transparent",
        currentItemEndArrowhead: "arrow",
        currentItemFillStyle: "hachure",
        currentItemFontFamily: 2,
        currentItemFontSize: 20,
        currentItemLinearStrokeSharpness: "round",
        currentItemOpacity: 100,
        currentItemRoughness: 1,
        currentItemStartArrowhead: null,
        currentItemStrokeColor: "#000000",
        currentItemStrokeSharpness: "sharp",
        currentItemStrokeStyle: "solid",
        currentItemStrokeWidth: 1,
      },
      currentItemFontFamily: 2,
    },
    files: props.dataScene.files,
    scrollToContent: true,

    libraryItems: [],
  };
  const excalidrawRef = useRef(null);

  const [viewModeEnabled, setViewModeEnabled] = useState(false);
  const [zenModeEnabled, setZenModeEnabled] = useState(false);
  const [gridModeEnabled, setGridModeEnabled] = useState(true);
  const [theme, setTheme] = useState("light");

  const initialStatePromiseRef = useRef({ promise: null });
  if (!initialStatePromiseRef.current.promise) {
    initialStatePromiseRef.current.promise = resolvablePromise();
  }
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch("/rocket.jpeg");
      const imageData = await res.blob();
      const reader = new FileReader();
      reader.readAsDataURL(imageData);

      reader.onload = function () {
        initialStatePromiseRef.current.promise.resolve(sceneDataNew);
      };
    };
    fetchData();

    const onHashChange = () => {
      const hash = new URLSearchParams(window.location.hash.slice(1));

      const libraryUrl = hash.get("addLibrary");
      if (libraryUrl) {
        excalidrawRef.current.importLibrary(libraryUrl, hash.get("token"));
      }
    };
    window.addEventListener("hashchange", onHashChange, false);
    return () => {
      window.removeEventListener("hashchange", onHashChange);
    };
  }, []);

  const onLinkOpen = useCallback((element, event) => {
    const link = element.link;
    const { nativeEvent } = event.detail;
    const isNewTab = nativeEvent.ctrlKey || nativeEvent.metaKey;
    const isNewWindow = nativeEvent.shiftKey;
    const isInternalLink =
      link.startsWith("/") || link.includes(window.location.origin);
    if (isInternalLink && !isNewTab && !isNewWindow) {
      // signal that we're handling the redirect ourselves
      event.preventDefault();
      // do a custom redirect, such as passing to react-router
      // ...
    }
  }, []);

  useEffect(() => {
    if (excalidrawRef.current) {
      excalidrawRef.current.updateScene(sceneDataNew);
    }
  }, [excalidrawRef]);
  return (
    <Grid container paddingTop={4}>
      <Grid item container direction={"row"}>
        <Grid
          item
          className={classes["TableName"]}
          width={60}
          marginLeft={4}
          paddingBottom={2}
        >
          Shapes
        </Grid>
        <Grid paddingLeft={{ sm: "25%", xs: 3 }} paddingTop={{ sm: 0, xs: 1 }}>
          <button
            className={classes["ResetScene"]}
            onClick={() => {
              excalidrawRef.current.resetScene();
            }}
          >
            Reset Scene
          </button>

          <label className={classes["View-mode"]}>
            <input
              type="checkbox"
              checked={viewModeEnabled}
              onChange={() => setViewModeEnabled(!viewModeEnabled)}
            />
            View mode
          </label>
          <label className={classes["View-mode"]}>
            <input
              style={{ paddingTop: 10 }}
              type="checkbox"
              checked={zenModeEnabled}
              onChange={() => setZenModeEnabled(!zenModeEnabled)}
            />
            Zen mode
          </label>
          <label className={classes["View-mode"]}>
            <input
              type="checkbox"
              checked={gridModeEnabled}
              onChange={() => setGridModeEnabled(!gridModeEnabled)}
            />
            Grid mode
          </label>
        </Grid>

        <Grid
          item
          paddingBottom={1}
          paddingLeft={{ lg: "35%", md: "20%", sm: "10%", xs: 3 }}
          paddingTop={{ sm: 0, xs: 1 }}
        >
          <button
            onClick={() => {
              navigate("/Manu/rebarTable");
            }}
            className={classes["GoBack"]}
          >
            Go Back
          </button>
        </Grid>
      </Grid>
      <div
        className="excalidraw-wrapper"
        style={{ backgroundColor: "rgb(252 250 250)" }}
      >
        <Excalidraw
          ref={excalidrawRef}
          initialData={initialStatePromiseRef.current.promise}
          onChange={(elements, state, files) => {
            // console.log(excalidrawRef.current.getSceneElements());
            props.setDataElements(excalidrawRef.current.getSceneElements());
            props.setDataFiles(excalidrawRef.current.getFiles());
          }}
          onLibraryChange={(libraryItems) => {
            console.log(libraryItems);
          }}
          viewModeEnabled={viewModeEnabled}
          zenModeEnabled={zenModeEnabled}
          gridModeEnabled={gridModeEnabled}
          theme={theme}
          name="Shape"
          UIOptions={{
            canvasActions: { loadScene: false, export: false },
          }}
          onLinkOpen={onLinkOpen}
        />
      </div>

      {/* {<div className="export-wrapper button-wrapper">
        <label className="export-wrapper__checkbox">
          <input
            type="checkbox"
            checked={exportWithDarkMode}
            onChange={() => setExportWithDarkMode(!exportWithDarkMode)}
          />
          Export with dark mode
        </label>
        <button
          onClick={async () => {
            const svg = await exportToSvg({
              elements: excalidrawRef.current.getSceneElements(),
              appState: {
                ...initialData.appState,
                exportWithDarkMode,
                width: 300,
                height: 100,
              },
              embedScene: true,
              files: excalidrawRef.current.getFiles(),
            });

            console.log(svg)
            document.querySelector(".export-svg").innerHTML = svg.outerHTML;
          }}
        >
          Export to SVG
        </button>
        <div className="export export-svg"></div>

        <button
          onClick={async () => {
            const blob = await exportToBlob({
              elements: excalidrawRef.current.getSceneElements(),
              mimeType: "image/png",
              appState: {
                ...initialData.appState,
                exportWithDarkMode,
              },
              files: excalidrawRef.current.getFiles(),
            });
            setBlobUrl(window.URL.createObjectURL(blob));
          }}
        >
          Export to Blob
        </button>
        <div className="export export-blob">
          <img src={blobUrl} alt="" />
        </div>

        <button
          onClick={async () => {
            const canvas = await exportToCanvas({
              elements: excalidrawRef.current.getSceneElements(),
              appState: {
                ...initialData.appState,
                exportWithDarkMode,
              },
              files: excalidrawRef.current.getFiles(),
            });
            const ctx = canvas.getContext("2d");
            ctx.font = "30px Virgil";
            ctx.strokeText("My custom text", 50, 60);
            setCanvasUrl(canvas.toDataURL());
          }}
        >
          Export to Canvas
        </button>
        <div className="export export-canvas">
          <img src={canvasUrl} alt="" />
        </div>
      </div> } */}
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import classes from "../../../Assets/Styles/Shapes.module.css";
import { Grid, TextField } from "@mui/material";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";

const ariaLabel = { "aria-label": "description" };
const Form1 = (props) => {
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [isDisabled, setIsDisabled] = useState(true);
  const [orderError, setOrderError] = useState(false);

  useEffect(() => {
    let tobeDeleted = [];
    let toBeAdded = props.elements;
    if (props?.elements.length > 0) {
      let dataElementTexts = props.dataScene?.elements.map((x) => x.text);
      dataElementTexts = dataElementTexts.filter((x) => x !== undefined);
      tobeDeleted = props.elements.filter(
        (displayedEl) => !dataElementTexts.includes(displayedEl.Name)
      );
    }

    props.dataScene?.elements
      ?.filter((x) => x.type === "text")
      .map((dataEl) => {
        if (
          !props.elements
            .map((displayedEl) => displayedEl.ExcalidrawId)
            .includes(dataEl.id) &&
          dataEl.text !== null &&
          dataEl.text?.match(/^ *$/) === null //null or white spaces
        ) {
          const temp = {
            Id: null,
            ExcalidrawId: dataEl.id,
            Name: dataEl.text.toUpperCase(),
            ShapeElementId: null,
            Order: null,
            MeasureTypeId: null,
            MeasureValue: null,
            MeasureFunction: null,
            ParentId:
              props.elements.length > 0
                ? props.elements.filter((x) => dataEl.text === x.Name)[0]
                    ?.ExcalidrawId
                : null,
          };
          toBeAdded.push(temp);
        }

        if (props.elements?.length === 0) {
          const temp = {
            Id: null,
            ExcalidrawId: dataEl.id,
            Name:  dataEl.text.toUpperCase(),
            ShapeElementId: null,
            Order: null,
            MeasureTypeId: null,
            MeasureValue: null,
            MeasureFunction: null,
          };

          toBeAdded.push(temp);
        }
      });

    let ElementsGroup = props.dataScene?.elements.map((x) => x.text);
    ElementsGroup = ElementsGroup.filter((x) => x !== undefined);
    toBeAdded = toBeAdded.filter((displayedEl) =>
      ElementsGroup.includes(displayedEl.Name)
    );
    console.log(toBeAdded);
    props.setElements(toBeAdded);
  }, [props.dataScene.elements]);

  useEffect(() => {
    var valueArr = props.elements?.map(function (item) {
      return Math.abs(item.Order);
    });
    valueArr = valueArr.filter((x) => x !== null && x !== "");
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx;
    });
    if (isDuplicate) {
      setIsDisabled(true);
      setOrderError(true);
    } else {
      setOrderError(false);
    }
    let emptyFields = false;
    props.elements?.map((element) => {
      if (
        orderError ||
        !element.ShapeElementId ||
        !element.Order ||
        !element.MeasureTypeId ||
        (element.MeasureTypeId === 2 && !element.MeasureValue) ||
        (element.MeasureTypeId === 3 && !element.MeasureFunction)
      ) {
        emptyFields = true;
        setIsDisabled(true);
      } else {
        if (!emptyFields) setIsDisabled(false);
      }
    });
  });

  return (
    <Grid container paddingLeft={1} paddingTop={1}>
      <Grid container item xs={12} direction={"row"}>
        <Grid
          item
          xs={12}
          sm={3}
          lg={2}
          md={2.5}
          className={classes["SHAPE-CREATION"]}
        >
          SHAPE CREATION
        </Grid>
        <Grid item container md={7} xs={12} direction={"row"} paddingTop={0.7}>
          <Grid item className={classes["FieldsLabels"]}>
            *Dimension
          </Grid>
          <Grid
            item
            className={
              props.twoDIsselected
                ? classes["CheckedBox"]
                : classes["notCheckedBox"]
            }
            onClick={() => {
              props.setTwoDIsselected(true);
            }}
            marginLeft={3}
            marginTop={0.5}
          ></Grid>
          <Grid item className={classes["Dimension"]} paddingLeft={1}>
            2D
          </Grid>
          <Grid
            item
            className={
              props.twoDIsselected
                ? classes["notCheckedBox"]
                : classes["CheckedBox"]
            }
            onClick={() => {
              props.setTwoDIsselected(false);
            }}
            marginLeft={3}
            marginTop={0.5}
          ></Grid>
          <Grid item className={classes["Dimension"]} paddingLeft={1}>
            3D
          </Grid>
        </Grid>
        <Grid item md={2.7} container direction={"row-reverse"}>
          <button
            className={classes["syncButton"]}
            onClick={() => {
              props.SyncBoard();
            }}
          >
            Sync
          </button>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction={"row"}
        className={classes["FieldsLabels"]}
        paddingTop={5}
        paddingLeft={3}
      >
        <Grid item sm={2}>
          *Line Label
        </Grid>
        <Grid item sm={2.8}>
          *Type
        </Grid>
        <Grid item sm={1.8}>
          *MT
        </Grid>
        <Grid item sm={1.8}>
          *Value
        </Grid>
        <Grid item sm={2.1}>
          *Formula
        </Grid>
        <Grid item sm={1.5}>
          *Order
        </Grid>
      </Grid>
      <Grid item container direction={"row"} paddingLeft={3} gap={2}>
        {props.elements?.map((el, index) => (
          <Grid container item key={index}>
            <Grid item sm={2}>
              <Input disabled value={el.Name} inputProps={ariaLabel} />
            </Grid>
            <Grid item sm={2.8}>
              <TextField
                style={{ width: "95%" }}
                size="small"
                variant="standard"
                value={el.ShapeElementId}
                disabled={el.ParentId ? true : false}
                select
                onChange={(e) => {
                  el.ShapeElementId = e.target.value;
                  props.elements?.map((elToChange) => {
                    if (elToChange.ParentId === el.ExcalidrawId) {
                      elToChange.ShapeElementId = e.target.value;
                    }
                  });
                  forceUpdate();
                }}
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
              >
                {props.shapElementsTypes.map((option) => (
                  <MenuItem value={option.Id} key={option.Id}>
                    {option.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={1.8}>
              <TextField
                style={{ width: "95%" }}
                size="small"
                variant="standard"
                value={el.MeasureTypeId}
                select
                disabled={el.ParentId ? true : false}
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                onChange={(e) => {
                  el.MeasureTypeId = e.target.value;
                  el.MeasureFunction = null;
                  el.MeasureValue = null;
                  props.elements?.map((elToChange) => {
                    if (elToChange.ParentId === el.ExcalidrawId) {
                      elToChange.MeasureTypeId = e.target.value;
                    }
                  });
                  forceUpdate();
                }}
              >
                {props.measureTypes.map((option) => (
                  <MenuItem value={option.Id} key={option.Id}>
                    {option.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={1.8}>
              <TextField
                style={{ width: "95%" }}
                size="small"
                variant="standard"
                value={el.MeasureValue}
                type={"number"}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => {
                  el.MeasureValue = e.target.value;
                  props.elements?.map((elToChange) => {
                    if (elToChange.ParentId === el.ExcalidrawId) {
                      elToChange.MeasureValue = e.target.value;
                    }
                  });
                  forceUpdate();
                }}
                disabled={
                  el.MeasureTypeId === 1 ||
                  el.MeasureTypeId === 3 ||
                  el.ParentId
                    ? true
                    : false
                }
              />
            </Grid>
            <Grid item sm={2.1}>
              <TextField
                style={{ width: "95%" }}
                size="small"
                variant="standard"
                value={el.MeasureFunction}
                onChange={(e) => {
                  el.MeasureFunction = e.target.value;
                  props.elements?.map((elToChange) => {
                    if (elToChange.ParentId === el.ExcalidrawId) {
                      elToChange.MeasureFunction = e.target.value;
                    }
                  });
                  forceUpdate();
                }}
                disabled={
                  el.MeasureTypeId === 1 ||
                  el.MeasureTypeId === 2 ||
                  el.ParentId
                    ? true
                    : false
                }
              />
            </Grid>
            <Grid item sm={1.5}>
              <TextField
                style={{ width: "95%" }}
                size="small"
                variant="standard"
                type={"number"}
                value={el.Order}
                onChange={(e) => {
                  el.Order = +e.target.value;
                  forceUpdate();
                }}
              ></TextField>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {orderError ? (
        <Grid item container xs={12} direction={"row-reverse"}>
          <Grid item className={classes["ErrorMsg"]}>
            There is duplicate in the order.
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <Grid
        item
        container
        xs={12}
        gap={5}
        paddingTop={4}
        direction={"row-reverse"}
        paddingBottom={3}
      >
        <Grid item className={classes["steps"]} paddingRight={3}>
          step 1 of 2
        </Grid>
        <Grid item>
          <button
            className={classes["NextButton"]}
            disabled={isDisabled}
            onClick={() => {
              let numOfLines = null;
              let numOfAngles = null;
              props.elements?.map((displayedEl) => {
                if (displayedEl.ShapeElementId === 1) {
                  numOfLines++;
                } else if (displayedEl.ShapeElementId === 2) {
                  numOfAngles++;
                }
              });
              props.setNumOfAngles(numOfAngles);
              props.setNumOfLines(numOfLines);
              props.setStep(2);
            }}
          >
            Next
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Form1;

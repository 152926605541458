import * as constFile from "../cmsConst";
import axios from "axios";
//get tips list
const GET_TM_LIST = constFile.GET_TM_LIST;
export const getTMList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_TM_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};
const GET_TM_INPUT_DATA = constFile.GET_TM_INPUT_DATA;
export const getTMInputData = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_TM_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};
const GET_TM_BY_ID = constFile.GET_TM_BY_ID;
export const getTMById = (teamMemberId ) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_TM_BY_ID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },

      params: { teamMemberId : teamMemberId  },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};

import * as constFile from "../cmsConst";
import axios from "axios";

const GET_PROJECTS_LIST = constFile.GET_PROJECTS_LIST;
export const getProjectsList = (data) => {
  const token = localStorage.getItem("accessToken");
  // data.Search = null;
  return axios
    .post(`${process.env.REACT_APP_URL + GET_PROJECTS_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};

const GET_PROJECT_DETAILS = constFile.GET_PROJECT_DETAILS;
export const getProjectDetails = (projectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_PROJECT_DETAILS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },

      params: { projectId: projectId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};

const GET_PROJECT_FILTER_INPUTS = constFile.GET_PROJECT_FILTERS_INPUTS;
export const getProjectFilterInputs = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_PROJECT_FILTER_INPUTS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};

const GET_CLIENT_DETAILS = constFile.GET_CLIENT_DETAILS;
export const getClientDetails = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_CLIENT_DETAILS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { Id: Id },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};

const GET_MC_DETAILS = constFile.GET_MAIN_CONTRACTOR_DETAILS;
export const getMCDetails = (companyId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_MC_DETAILS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { companyId: companyId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};

const GET_SC_DETAILS = constFile.GET_SC_DETAILS;
export const getSCDetails = (companyId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_SC_DETAILS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { companyId: companyId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};

const TOGGLE_PROJECT_PUBLISHING = constFile.TOGGLE_PROJECT_PUBLISHING;
export const toggleProject = (ProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + TOGGLE_PROJECT_PUBLISHING}`,
      { ProjectId: ProjectId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import validator from "validator";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/TipsModal.module.css";
import { TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import * as cmsTMServices from "../../Services/cms-members-services.proxy";
const createCMSUser = cmsTMServices.createCMSUser;
const style = {
  position: "absolute",
  top: "53%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  width: "45%",
  height: "60%",
  overflowY: "auto",
  display: "block",
  border: "#fff",
  borderRadius: "5px",
};

const AddTM = (props) => {
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameInvalid, setNameInvalid] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [addressError, setAddressError] = useState(false);
  const [addressInvalid, setAddressInvalid] = useState(false);

  const [countryError, setCountryError] = useState(false);
  const [isoCodes, setisoCodes] = useState([]);
  const [fulled, setFulled] = useState(true);
  const [emailInvalid, setEmailInvalid] = useState("");
  const [personalPhoneNumber, setPersonalPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setName("");
    setNameError(false);
    setEmail("");
    setEmailError(false);
    setPassword("");
    setPasswordError(false);
    setConfirmPassword("");
    setConfirmPasswordError(false);
    setCountry("");
    setCountryError(false);
    setAddress("");
    setAddressError(false);
    setPersonalPhoneNumber("");
    setPhoneNumberError(false);
    setOpen(false);
    props.setOpenModal(false);
  };

  useEffect(() => {
    for (var i = 0; i < props.countries?.length; i++) {
      isoCodes[i] = props.countries[i].IsoCode;
    }
    if (isoCodes.length > 0) {
      setFulled(true);
    }
  });
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);
  useEffect(() => {
    if (
      !country ||
      !address ||
      !personalPhoneNumber ||
      phoneNumberError ||
      addressError ||
      addressInvalid ||
      countryError ||
      !name ||
      !email ||
      !password ||
      !confirmPassword ||
      nameError ||
      nameInvalid ||
      emailError ||
      emailInvalid ||
      passwordError ||
      passwordInvalid ||
      confirmPasswordError ||
      confirmPasswordInvalid
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  const TMData = {
    Id: null,
    Name: name,
    Address: address,
    Email: email,
    Password: password,
    PhoneNumber: personalPhoneNumber,
  };
  const Save = () => {
    setLoading(true);
    createCMSUser(TMData).then(() => {
      props.setModify(!props.modify);
      setLoading(false);
      handleClose();
    });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container paddingTop={3} paddingLeft={4} gap={2}>
            <Grid item xs={5.5} container gap={1}>
              <Grid item xs={12} className={classes["FieldLabel"]}>
                Name:
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label={name ? "" : "Name"}
                  size="small"
                  inputProps={{
                    maxLength: 150,
                  }}
                  value={name}
                  error={nameError}
                  helperText={
                    nameError
                      ? nameInvalid
                        ? "Name must be less than 150 characters"
                        : "Enter your Name"
                      : ""
                  }
                  onChange={(e) => {
                    setName(e.target.value);
                    if (e.target.value === "") {
                      setNameError(true);
                      setNameInvalid(false);
                    } else if (
                      (e.target.value !== "") &
                      (e.target.value > 150)
                    ) {
                      setNameError(true);
                      setNameInvalid(true);
                    } else {
                      setNameError(false);
                      setNameInvalid(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={5.5} container gap={1}>
              <Grid item xs={12} className={classes["FieldLabel"]}>
                Email:
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type={"email"}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label={email ? "" : "Email"}
                  size="small"
                  inputProps={{
                    maxLength: 150,
                  }}
                  value={email}
                  error={emailError}
                  helperText={
                    emailError
                      ? emailInvalid
                        ? "Enter a valid email"
                        : "Enter an email address"
                      : ""
                  }
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (e.target.value === "") {
                      setEmailError(true);
                      setEmailInvalid(false);
                    } else if (
                      (e.target.value !== "") &
                        !e.target.value.match(
                          /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                        ) ||
                      e.target.value.length > 64
                    ) {
                      setEmailError(true);
                      setEmailInvalid(true);
                    } else {
                      setEmailError(false);
                      setEmailInvalid(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={5.5}  container gap={1}>
              <Grid item xs={12} className={classes["FieldLabel"]}>
                Password:
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="new-password"
                  type={"password"}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label={password ? "" : "Password"}
                  size="small"
                  inputProps={{
                    maxLength: 150,
                  }}
                  value={password}
                  error={passwordError}
                  helperText={
                    passwordError
                      ? passwordInvalid
                        ? "Password must contain a number, a symbol, an uppercase & lowercase and be no less than 8  or more than 20 characters."
                        : "Enter a password"
                      : ""
                  }
                  onChange={(e) => {
                    setPassword(e.target.value);

                    if (e.target.value === "") {
                      setPasswordError(true);
                      setPasswordInvalid(false);
                    } else if (
                      (e.target.value !== "") &
                        !validator.isStrongPassword(e.target.value, {
                          minLength: 8,
                          minLowercase: 1,
                          minUppercase: 1,
                          minNumbers: 1,
                          minSymbols: 1,
                        }) ||
                      e.target.value.length > 20
                    ) {
                      if (confirmPassword !== "") {
                        setConfirmPasswordError(true);
                        setConfirmPasswordInvalid(true);
                      }
                      setPasswordError(true);
                      setPasswordInvalid(true);
                    } else {
                      if (confirmPassword !== e.target.value) {
                        setConfirmPasswordError(true);
                        setConfirmPasswordInvalid(true);
                      } else {
                        setConfirmPasswordError(false);
                        setConfirmPasswordInvalid(false);
                      }
                      setPasswordError(false);
                      setPasswordInvalid(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={5.5} container gap={1}>
              <Grid item xs={12} className={classes["FieldLabel"]}>
                Confirm Password:
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type={"password"}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label={confirmPassword ? "" : "Password"}
                  size="small"
                  inputProps={{
                    maxLength: 150,
                  }}
                  value={confirmPassword}
                  error={confirmPasswordError}
                  helperText={
                    confirmPasswordError
                      ? confirmPasswordInvalid
                        ? "Password didn't match, try again"
                        : "Confirm Password"
                      : ""
                  }
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);

                    if (e.target.value === "") {
                      setConfirmPasswordError(true);
                      setConfirmPasswordInvalid(false);
                    } else if (e.target.value !== password) {
                      setConfirmPasswordError(true);
                      setConfirmPasswordInvalid(true);
                    } else {
                      setConfirmPasswordError(false);
                      setConfirmPasswordInvalid(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={5.5} container gap={1}>
              <Grid item xs={12} className={classes["FieldLabel"]}>
                Country:
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label={country ? "" : "country"}
                  size="small"
                  inputProps={{
                    maxLength: 150,
                  }}
                  select
                  SelectProps={{ MenuProps: { disableScrollLock: true } }}
                  value={country}
                  error={countryError}
                  helperText={countryError ? "Select a country" : ""}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setCountryError(true);
                    } else {
                      setCountryError(false);
                      setCountry(e.target.value);
                    }
                  }}
                >
                  {props.countries.map((option) => (
                    <MenuItem value={option.Id} key={option.Id}>
                      {option.Name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid item xs={5.5} container gap={1}>
              <Grid item xs={12} className={classes["FieldLabel"]}>
                Address:
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      fontFamily: "Muli",
                      fontSize: "13px",
                    },
                  }}
                  label={address ? "" : "Address"}
                  size="small"
                  inputProps={{
                    maxLength: 150,
                  }}
                  value={address}
                  error={addressError}
                  helperText={
                    addressError
                      ? addressInvalid
                        ? "Address must be less than 150 characters"
                        : "Enter your address"
                      : ""
                  }
                  onChange={(e) => {
                    setAddress(e.target.value);
                    if (e.target.value === "") {
                      setAddressError(true);
                      setAddressInvalid(false);
                    } else if (
                      (e.target.value !== "") &
                      (e.target.value.length > 150)
                    ) {
                      setAddressError(true);
                      setAddressInvalid(true);
                    } else {
                      setAddressError(false);
                      setAddressInvalid(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={5.5} container gap={1}>
              <Grid item xs={12} className={classes["FieldLabel"]}>
                Phone Number:
              </Grid>
              <Grid item xs={12}>
                {fulled ? (
                  <PhoneInput
                    placeholder="Enter phone number"
                    onlyCountries={isoCodes}
                    enableAreaCodes={true}
                    value={personalPhoneNumber}
                    onChange={(phone) => {
                      setPersonalPhoneNumber(phone);
                      if (
                        personalPhoneNumber === "" ||
                        personalPhoneNumber.length < 4 ||
                        personalPhoneNumber.length > 25
                      ) {
                        setPhoneNumberError(true);
                      } else {
                        setPhoneNumberError(false);
                      }
                    }}
                    country={"us"}
                    inputStyle={{
                      borderColor: "#707070",
                      width: "100%",
                      height: 40,
                      fontFamily: "Muli",
                      fontsize: "16px",
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction={"row-reverse"}
              md={12}
              paddingTop={1}
              paddingRight={5}
              paddingBottom={2}
            >
              <button

              
                onClick={() => {
                  Save();
                }}
                disabled={isDisabled}
                className={classes["SaveButon"]}
              >
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                onClick={() => {
                  handleClose();
                }}
                className={classes["CancelButton"]}
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default AddTM;

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/reports.module.css";

import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import Bin from "../../Assets/Images/Reports/Bin.png";
import Eye from "../../Assets/Images/Reports/Eye.png";

import Pen from "../../Assets/Images/Reports/Pen.png";
import Loading from "../../Components/Loading";
import * as tipsServices from "../../Services/tips-services.proxy";
const getTipsList = tipsServices.getTipsList;
const deleteTip = tipsServices.deleteTip;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Table = (props) => {
  const navigate = useNavigate();


  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "ACTION",
      sortable: false,
      headerAlign: 'center',
      width: 250,
      
      
      renderCell: (params) => {
        return (
          <Grid
            container
            direction={"column"}
            gap={1}
           justifyContent="center"
           alignItems="center"
          >
            <Grid
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={(e) => {
                props.setTipId(params.row.Id);
                setTimeout(() => {
                  props.setOpenModal(true);
                }, 500);
                // props.setOpenModal(true);
              }}
            >
              <img src={Eye} alt={"pen"}></img>
            </Grid>

            <Grid item>
              <img
                src={Bin}
                alt={"pen"}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={(e) => {
                  deleteTip(params.row.Id).then((x) => {
                    props.setModify(!props.modify);
                  });
                }}
              ></img>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "Title",

      width: 500,
    },

    {
      field: "Content",
      width: 450,
    },
  ];
  const [tipsTable, setTipsTable] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setdataLength] = useState(0);

 


  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 20,
      bottom: params.isLastVisible ? 0 : 20,
    };
  }, []);
  useEffect(() => {
    let apiTest = true;

    getTipsList(listData).then((x) => {
      if (apiTest) {
        setTipsTable(x.Tips);
        setdataLength(x.Total);
        setLoading(false)
      }
    });

    return () => {
      apiTest = false;
    };
  }, [pageSize, pageNumber, props.modify, props.searchText]);

  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (dataLength > 0) {
  //     setLoading(false);
  //   }
  //   else if(dataLength === 0){
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }
  // }, [dataLength]);
  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: props.searchText,
  };

  return (
    <Grid container>
      <Grid
        item
        md={12}
        container
        direction={"row-reverse"}
        paddingRight={5}
        paddingBottom={2}
      >
        <Grid item>
          <button
            style={{ width: "fit-content", padding: 10 }}
            onClick={() => {
              props.setOpenModal(true);
            }}
            className={classes["RunButton"]}
          >
            New Tip
          </button>
        </Grid>
      </Grid>
      {loading ? (
        <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={12}
          >
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"} paddingLeft={1}>
        {tipsTable.length > 0 ? (
          <DataGrid
            style={{ paddingTop: 30, paddingLeft: 10 }}
            initialState={{
              pagination: {
                pageSize: 25,
              },
            }}
            pagination
            paginationMode="server"
            rows={tipsTable}
            columns={columns}
            headerHeight={40}
            rowHeight={100}
            getRowSpacing={getRowSpacing}
            showColumnRightBorder
            sx={{
              [`& .${gridClasses.row}`]: {
                border: 0,

                boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
                color: "#0674b9",

                overflow: "visible",

                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? "#fff" : grey[500],
              },
              [`& .${gridClasses.cell}`]: {
                overflow: "visible",
                whiteSpace: "pre-wrap",
              },
              [`& .${gridClasses.columnHeader}`]: {
                bgcolor: "transparent",
                color: "rgb(17 24 84)",
                border: 0,
              },
              [`& .${gridClasses.columnHeaders}`]: {
                border: 0,
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontFamily: "Muli",
                fontWeight: "bold",
              },

              [`& .${gridClasses.footerContainer}`]: {
                bgcolor: "transparent",
                minHeight: "30px",
                border: 0,
              },
              fontFamily: "Muli",
              fontSize: "13px",
              border: 0,
            }}
            disableColumnFilter
            getRowId={(row) => row.Id}
            rowCount={dataLength}
            onPageChange={(newPage) => {
              if (pageSize > dataLength) {
                setPageNumber(1);
              } else setPageNumber(newPage + 1);
            }}
            onPageSizeChange={(newPage) => {
              setPageSize(newPage);
            }}
            pageSize={pageSize}
            //   loading={props.loading}
            // components={{
            //   NoRowsOverlay: () => (
            //     <Stack className={classes["EmptyMessage"]}>No Tips To Display</Stack>
            //   ),
            // }}
          />
        ) :(
        <Stack className={classes["EmptyMessage"]}>No Tips To Display</Stack>
        )
        }
         
        </Grid>
      )}
    </Grid>
  );
};

export default Table;

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/reports.module.css";

import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import Bin from "../../Assets/Images/Reports/Bin.png";
import Eye from "../../Assets/Images/Reports/Eye.png";
import CircularProgress from "@mui/material/CircularProgress";

import Pen from "../../Assets/Images/Reports/Pen.png";
import Loading from "../../Components/Loading";
import * as reportsServices from "../../Services/reports-services.proxy";
const getReportsList = reportsServices.getReportsList;
const deleteReport = reportsServices.deleteReport;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Table = (props) => {
  const navigate = useNavigate();
  const [chosenId, setChosenId] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);

  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "ACTION",
      sortable: false,
      width: 200,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Grid
            container
            direction={"column"}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              item
              className={classes.IconTable}
              onClick={(e) => {
                props.setReportIdTable(params.row.Id);
                props.setViewAddReport(true);
                props.setShowTableOnly(true);
                props.setAvailableData(false);
              }}
            >
              <img src={Eye} alt={"pen"}></img>
            </Grid>
            {loadingButton && params.row.Id === chosenId ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item className={classes.IconTable}>
                <img
                  src={Bin}
                  alt={"pen"}
                  onClick={(e) => {
                    setChosenId(params.row.Id);
                    setLoadingButton(true);
                    deleteReport(params.row.Id).then((x) => {
                      setModify(!modify);
                      setLoadingButton(false);
                    });
                  }}
                ></img>
              </Grid>
            )}
          </Grid>
        );
      },
    },
    {
      field: "Name",

      width: 300,
    },

    {
      field: "Type",
      width: 300,
    },

    {
      field: "Description",
      width: 300,
    },
    {
      field: "Creation Date",
      width: 300,
      sortable: false,
      renderCell: (params) => params.row.CreationDate.slice(0, 10),
    },
  ];
  const [reprortsTable, setReportsTable] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setdataLength] = useState(0);

  const [modify, setModify] = useState(true);

  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (dataLength > 0) {
  //     setLoading(false);
  //   }
  //   else if(dataLength === 0){
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }
  // }, [dataLength]);
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 20,
      bottom: params.isLastVisible ? 0 : 20,
    };
  }, []);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: props.searchText,
  };

  useEffect(() => {
    let apiTest = true;

    getReportsList(listData).then((x) => {
      if (apiTest) {
        setReportsTable(x.Reports);
        setdataLength(x.Total);
        setLoading(false);
        if (x.Total < 1) {
          props.setAvailableData(false);
        }
      }
    });

    return () => {
      apiTest = false;
    };
  }, [pageSize, pageNumber, modify, props.searchText]);
  return (
    <Grid container>
      <Grid
        item
        md={12}
        container
        direction={"row-reverse"}
        paddingRight={5}
        paddingBottom={2}
      >
        <Grid item>
          <button
            style={{ width: "fit-content", padding: 10 }}
            onClick={() => {
              props.setAvailableData(false);
            }}
            className={classes["RunButton"]}
          >
            New Report
          </button>
        </Grid>
      </Grid>
      {loading ? (
        <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={12}
          >
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
          {reprortsTable ? (
            <DataGrid
              style={{ paddingTop: 30, paddingLeft: 10 }}
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              pagination
              paginationMode="server"
              rows={reprortsTable}
              columns={columns}
              headerHeight={40}
              rowHeight={100}
              getRowSpacing={getRowSpacing}
              showColumnRightBorder
              sx={{
                [`& .${gridClasses.row}`]: {
                  border: 0,

                  boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
                  color: "#0674b9",
                  overflow: "auto",

                  bgcolor: (theme) =>
                    theme.palette.mode === "light" ? "#fff" : grey[500],
                },

                [`& .${gridClasses.columnHeader}`]: {
                  bgcolor: "transparent",
                  color: "rgb(17 24 84)",
                  border: 0,
                },
                [`& .${gridClasses.columnHeaders}`]: {
                  border: 0,
                },
                [`& .${gridClasses.columnHeaderTitle}`]: {
                  fontFamily: "Muli",
                  fontWeight: "bold",
                },

                [`& .${gridClasses.footerContainer}`]: {
                  bgcolor: "transparent",
                  minHeight: "30px",
                  border: 0,
                },
                fontFamily: "Muli",
                fontSize: "13px",
                border: 0,
              }}
              disableColumnFilter
              getRowId={(row) => row.Id}
              rowCount={dataLength}
              onPageChange={(newPage) => {
                if (pageSize > dataLength) {
                  setPageNumber(1);
                } else setPageNumber(newPage + 1);
              }}
              onPageSizeChange={(newPage) => {
                setPageSize(newPage);
              }}
              pageSize={pageSize}
              //   loading={props.loading}
              components={{
                NoRowsOverlay: () => (
                  <Stack className={classes["EmptyMessage"]}>
                    No Reports to Display
                  </Stack>
                ),
              }}
            />
          ) : (
            <Stack className={classes["EmptyMessage"]}>
              No Reports to Display
            </Stack>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Table;

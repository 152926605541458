import React, { useState, useEffect } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import classes from "../../Assets/Styles/Shapes.module.css";
import { Grid, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import * as shapesServices from "../../Services/shape-services.proxy";

const deleteShape = shapesServices.deleteShape;

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 25%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  bgcolor: "#transparent",
  border: "0",
  width: "45%",
  height: "60%",
  outline: "none",
};

export default function DeleteModal(props) {
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
    setPassword(null);
    setPasswordError(false);
  };

  useEffect(() => {
    if (props.open) {
      handleOpen(true);
    }
  }, [props.open]);

  return (
    <div>
      <StyledModal
        disableScrollLock
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Grid container direction={"row-reverse"}>
            <IconButton
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon htmlColor="#fff" />
            </IconButton>
          </Grid>
          <Grid bgcolor={"#fff"} container direction={"column"} paddingTop={2}>
            <Grid item container justifyContent={"center"}>
              <div className={classes["Modal-title"]}>Delete Shape</div>
            </Grid>
            <Grid
              item
              width={"100%"}
              bgcolor={"#e9e7e7"}
              height={1}
              marginTop={1}
            >
              <div style={{ width: "100%", height: 1 }}></div>
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              className={classes.message}
              paddingTop="4%"
            >
              <Grid item md={12} fontSize={16}>
                Are you sure you want to delete this Shape?
              </Grid>
            </Grid>
            <Grid
              container
              gap={1}
              justifyContent={"center"}
              alignItems={"center"}
              direction={"column"}
              paddingTop={2}
            >
              <Grid item className={classes["FieldsLabels"]}>
                Please Enter your password:
              </Grid>
              <Grid item>
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  type={"password"}
                  variant="standard"
                  value={password}
                  error={passwordError}
                  helperText={passwordError ? "Wrong Password" : ""}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(false);
                  }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid
              item
              marginTop={3.1}
              xs={10}
              container
              direction={"row-reverse"}
              bgcolor={"#e9e7e7"}
              padding={2}
            >
              <Grid item marginLeft={2}>
                <button
                  className={classes.SaveModal}
                  disabled={password ? false : true}
                  onClick={() => {
                    setLoad(true);
                    deleteShape(props.rowData.Id, password).then((x) => {
                      setLoad(false);
                      if (x.status === 401) {
                        setPasswordError(true);
                      } else {
                        handleClose();
                        props.setModify(!props.modify);
                      }
                    });
                  }}
                >
                  {load ? "Deleting" : "Delete"}
                </button>
              </Grid>
              <Grid item>
                <button
                  className={classes.exitModal}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </StyledModal>
    </div>
  );
}

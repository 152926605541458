import React, { useState, useEffect } from "react";
import classes from "../../../src/Assets/Styles/projects.module.css";
import { Grid, Box, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Table from "./Table";
import ProjectsDetails from "./ProjectsDetails";
import ClientDetails from "./ClientDetails";
import ProjectCreatorDetails from "./ProjectCreatorDetails";
const ProjectsView = (props) => {
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [searchText, setSearchText] = useState(null);
  const [viewDetails, setViewDetails] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [clientId, setClientId] = useState();
  const [projectCreatorId, setProjectCreatorId] = useState();

  const [viewClientDetails, setViewClientDetails] = useState(false);
  const [viewProjectCreatorDetails, setViewProjectCreatorDetails] =
    useState(false);
  const [creatorAccountTypeId, setCreatorAccountTypeId] = useState();

  return (
    <Grid container paddingTop={9} >
      <Grid item container direction={"row"} marginLeft={4}>
        <Grid item className={classes["page-title"]} md={3} xs={12}>
          Projects
        </Grid>
     {!viewClientDetails && !viewProjectCreatorDetails && !viewDetails ?
            <Grid
          md={9}
          paddingLeft={{ md: 7, xs: 0 }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <TextField
            variant="standard"
            fullWidth
            onChange={inputHandler}
            placeholder="Search…"
            InputProps={{
              startAdornment: (
                <SearchIcon
                  fontSize="small"
                  htmlColor={"#707070"}
                  style={{ marginLeft: 15 }}
                />
              ),
              style: { fontFamily: "Muli" },
            }}
            sx={{
              fontFamily: "Muli",
              backgroundColor: "white",
              borderRadius: 1,

              border: "solid 1px #e9e7e7",
              width: {
                xs: 1,
                sm: 483,
              },
            }}
          />
        </Grid> 
        : 
        <></>
     }
      </Grid>
      <Grid item container paddingTop={5} md={12}>
        {viewDetails ? (
          viewClientDetails ? (
            <ClientDetails
              setViewClientDetails={setViewClientDetails}
              rowData={rowData}
              clientId={clientId}
              setViewProjectCreatorDetails={setViewProjectCreatorDetails}
              projectCreatorId={projectCreatorId}
            />
          ) : viewProjectCreatorDetails ? (
            <ProjectCreatorDetails
              setViewProjectCreatorDetails={setViewProjectCreatorDetails}
              projectCreatorId={projectCreatorId}
              creatorAccountTypeId={creatorAccountTypeId}
            />
          ) : (
            <ProjectsDetails
              setViewDetails={setViewDetails}
              rowData={rowData}
              setViewClientDetails={setViewClientDetails}
              setViewProjectCreatorDetails={setViewProjectCreatorDetails}
              setClientId={setClientId}
              setProjectCreatorId={setProjectCreatorId}
              setCreatorAccountTypeId={setCreatorAccountTypeId}
            />
          )
        ) : (
          <Table
            setViewDetails={setViewDetails}
            setRowData={setRowData}
            searchText={searchText}
            rowData={rowData}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ProjectsView;

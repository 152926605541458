import React, { useState, useEffect, useMemo } from "react";
import {  useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/SaasUser.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import * as tmServices from "../../../Services/team-member-services.proxy";
import Loading from "../../../Components/Loading";
import TMLogo from "../../../Assets/Images/IconsProfile/TeamMemberProfile.png";

const getTMInputData = tmServices.getTMInputData;
const getTMList = tmServices.getTMList;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 12px;
    box-sizing: border-box;
    min-height: 32px;
    width: 9rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    margin: 0.5em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${
        theme.palette.mode === "dark" ? blue[600] : blue[100]
      };
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    min-width: 11rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    height:280px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;
    
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${
        theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${
        theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

const Table = (props) => {
  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const MCID = query.get("id");
  useEffect(() => {
  }, []);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setdataLength] = useState(0);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState(0);

  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   if (dataLength > 0) {
  //     setLoading(false);
  //   }
  //   else if(dataLength === 0){
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }
  // }, [dataLength]);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },

    CompanyId: MCID,
    Search: props.searchText,
    GroupPermissionId: role === 0 ? null : role,
  };
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "ACTION",
      sortable: false,
      width: 150,

      renderCell: (params) => {
        return (
          <button
            onMouseOver={(e) => {
              e.target.style.backgroundColor = "#5fba47";
              e.target.style.color = "#fff";
              e.target.style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#fff";
              e.target.style.color = "#5fba47";
            }}
            onClick={() => {
              props.setRowData(params);
              props.setViewDetails(true);
            }}
            className={classes["ViewButton"]}
          >
            View
          </button>
        );
      },
    },
    {
      field: "Company",
      headerName: "Company Name",
      width: 180,
    },
    {
      field: "Team Member Profile",
      headerName: "Profile",
      width: 100,
      resizeable: true,
      renderCell: (params) => (
        <img
          src={
            params.row.UserDetails?.ProfilePicture
              ? params.row.UserDetails.ProfilePicture.URL
              : TMLogo
          }
          style={{ width: "27px" }}
          alt={"null"}
        />
      ),
    },
    {
      field: "Name",
      headerName: "Name",

      width: 150,
    },

    {
      field: "Email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "UserGroupPermissions",
      headerName: "Role",
      width: 150,
    },

    {
      field: "Country",
      headerName: "Country",
      width: 150,
    },

    {
      field: "Address",
      headerName: "Address",
      width: 150,
      sortable: false,
    },
    {
      field: "PhoneNumber",
      headerName: "Phone",
      width: 150,
      sortable: false,
    },
    {
      field: "CreationTime",
      headerName: "Created Date",
      width: 150,
      sortable: false,
      renderCell: (params) => params.row.CreationTime.slice(0, 10),
    },
  ];
  const [teamMemberTable, setTeamMemberTable] = useState([]);
  useEffect(() => {
    getTMList(listData).then((x) => {
      setTeamMemberTable(x.TeamMembers);

      setdataLength(x.Total);
      setLoading(false);
    });
  }, [pageSize, pageNumber, role, props.searchText]);
  useEffect(() => {
    getTMInputData().then((x) => {
      setRoles(x.GroupPermission);
    });
  }, []);
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 20,
      bottom: params.isLastVisible ? 0 : 20,
    };
  }, []);
  return (
    <Grid container>
      <Grid container item direction={"row"}>
        <Grid item className={classes["TableName"]} width={130} marginLeft={4}>
          Team Member
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={9} container direction={"row-reverse"} gap={2}>
          <Grid item paddingTop={1}>
            <button
              className={classes["GoBack"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go back
            </button>
          </Grid>
          <Grid item>
            <CustomSelect
              value={role}
              onChange={(option) => {
                setRole(option);
              }}
            >
              <StyledOption value={0} disabled>
                Role
              </StyledOption>

              {roles.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {role ? (
            <Grid
               className={classes["clearFilter"]}
              onClick={() => {
                setRole(0);
              }}
            >
              Clear Filters
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {loading ? (
        <Grid item width="98%" height={700} bgcolor={"rgb(252 249 249)"}>
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={12}
          >
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          width="98%"
          height={700}
          bgcolor={"rgb(252 249 249)"}
          paddingLeft={1.5}
        >
          {teamMemberTable.length > -1 ? (
            <DataGrid
              style={{ paddingTop: 30, paddingLeft: 10 }}
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              pagination
              paginationMode="server"
              rows={teamMemberTable}
              columns={columns}
              headerHeight={40}
              rowHeight={100}
              getRowSpacing={getRowSpacing}
              showColumnRightBorder
              sx={{
                [`& .${gridClasses.row}`]: {
                  border: 0,

                  boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
                  color: "#0674b9",
                  overflow: "auto",

                  bgcolor: (theme) =>
                    theme.palette.mode === "light" ? "#fff" : grey[500],
                },

                [`& .${gridClasses.columnHeader}`]: {
                  bgcolor: "transparent",
                  color: "rgb(17 24 84)",
                  border: 0,
                },
                [`& .${gridClasses.columnHeaders}`]: {
                  border: 0,
                },
                [`& .${gridClasses.columnHeaderTitle}`]: {
                  fontFamily: "Muli",
                  fontWeight: "bold",
                },

                [`& .${gridClasses.footerContainer}`]: {
                  bgcolor: "transparent",
                  minHeight: "30px",
                  border: 0,
                },
                fontFamily: "Muli",
                fontSize: "13px",
                border: 0,
              }}
              disableColumnFilter
              getRowId={(row) => row.Id}
              rowCount={dataLength}
              onPageChange={(newPage) => {
                if (pageSize > dataLength) {
                  setPageNumber(1);
                } else setPageNumber(newPage + 1);
              }}
              onPageSizeChange={(newPage) => {
                setPageSize(newPage);
              }}
              pageSize={pageSize}
              //   loading={props.loading}
              components={{
                NoRowsOverlay: () => (
                  <Stack className={classes["EmptyMessage"]}>
                    No Team Member to display
                  </Stack>
                ),
              }}
            />
          ) : (
            <Stack className={classes["EmptyMessage"]}>
              No team members to display
            </Stack>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Table;
